import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHelperText, Input, Option, Select, Textarea } from "@mui/joy";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
} from "reactstrap";
import { validateEightDigitNumber } from "../../../components/Common";
import SharedBeneficiaryEdit from "../../../components/SharedBeneficiaryEdit";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import "../../../css/formPOPup.css";
import "../../../css/myestate_edit.css";
import {
  getSingleOtherAsset,
  updateOtherAsset,
} from "../../../services/OtherAssetService";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../../services/user-service";
function EditOtherAssets() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState({
    otherAsset: {
      owner: "",
      otherAssetListName: "",
      assetCaption: "",
      assetValue: "",
      assetName: "",
      notes: "",
      trust: "",
      beneficiaryName: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [errors, setErrors] = useState({
    assetValueValidate: true,
  });

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        otherAsset: {
          ...prevData.otherAsset,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        otherAsset: {
          ...prevData.otherAsset,
          trust: "",
        },
      }));
      setData((prevData) => ({
        ...prevData,
        otherAsset: {
          ...prevData.otherAsset,
          beneficiaryName: "",
        },
      }));
    } else {
      if (field === "assetValue") {
        errorStates.assetValueValidate =
          newValue !== "" ? validateEightDigitNumber(newValue) : true;
      }
      setData((prevData) => ({
        ...prevData,
        otherAsset: {
          ...prevData.otherAsset,
          [field]: newValue,
        },
      }));
    }
    setErrors(errorStates);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // Set the form
  const OtherAssestForm = (event) => {
    event.preventDefault();

    const hasErrors = Object.values(errors).includes(false);
    if (!hasErrors) {
      let token = "Bearer " + getToken();

      //create form data to send a file and remaining class data
      const formData = new FormData();
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
      formData.append("data", JSON.stringify(data));

      updateOtherAsset(formData, token)
        .then((resp) => {
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
          navigate("/user/my-estate/other-assests");
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // const [showAfterCloseBene, setShowAfterCloseBene] = useState(true);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let token = "Bearer " + getToken();
  //       const res = await getSingleRealEstate(token, id);
  //       console.log("this is realEstate responce ", res);
  //       setData({
  //         ...data,
  //         sharedDetails: res.sharedDetails,
  //       });
  //       const copiedSharedDetails = [...res.sharedDetails];
  //       console.log("copiedSharedDetails response : ", copiedSharedDetails);
  //       setEstimatedTotalAmount(res.realEstate.estPropertyVal);

  //       console.log("check ", sharedDetails[0].distributedType);

  //       console.log("sharedDetails response : ", res.sharedDetails);
  //       console.log("sharedDetails response : ", sharedDetails);
  //       if (copiedSharedDetails.length > 0) {
  //         console.log(res.sharedDetails.length);
  //         setSharedDetails(res.sharedDetails);
  //         console.log("check ", sharedDetails[0].distributedType);
  //         ben(copiedSharedDetails[0].distributedType);
  //         for (var i = 0; i < copiedSharedDetails.length; i++) {
  //           handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
  //           handleFieldChange1(
  //             copiedSharedDetails[i].beneficiaryId,
  //             copiedSharedDetails[i].distributedType,
  //             copiedSharedDetails[i].distributedValue
  //           );
  //           // console.log("sajid " + sharedDetails[0])
  //         }

  //         console.log(
  //           "sharedDetails beneficiaryDetails : ",
  //           beneficiaryDetails
  //         );
  //         console.log(
  //           "sharedDetails selectedBeneficiaries : ",
  //           selectedBeneficiaries
  //         );
  //         console.log("sharedDetails distributedType : ", distributedType);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [showAfterCloseBene]);
  const [responseData, setResponseData] = useState(null);
  const [responseAssetData, setResponseAssetData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleOtherAsset(token, id);
        console.log("this is otherAsset responce ", res);
        setData({
          ...data,
          otherAsset: res.otherAsset,
          documents: res.documents,
          sharedDetails: res.sharedDetails,
        });
        setResponseData(res);
        setResponseAssetData(res?.otherAsset);
        const ownerNameArray = res.otherAsset.owner.split(", ");
        setOwnerName(ownerNameArray);
        if (res.otherAsset.trust !== "" && res.otherAsset.trust != null) {
          setSharedType("Trust");
        } else if (
          res.otherAsset.beneficiaryName !== "" &&
          res.otherAsset.beneficiaryName != null
        ) {
          setSharedType("Beneficiary");
        } else {
          setSharedType(null);
        }
        setEstimatedTotalAmount(res.otherAsset.assetValue);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.otherAsset.assetValue);
  };

  const handleEditCloseBeneficiary = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  const handleTrustSave = () => {
    setbeneVisible(false);
  };
  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div>
            <Form onSubmit={OtherAssestForm} className="addform_body">
              <container>
                <div className="homepage jewelry_container">
                  <Card>
                    <CardHeader>
                      <div
                        style={{ width: "100%" }}
                        className="addform_heading"
                      >
                        <h3 className="addform_heading_h1">
                          {data.otherAsset.otherAssetListName}
                        </h3>
                        <div className="addform_close">
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => {
                              navigate("/user/my-estate/other-assests");
                            }}
                          />
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="jewelry_card_body">
                      <div>
                        <table className="jewelry_table">
                          <thead>
                            <tr>
                              <th
                                style={{ width: "190px" }}
                                className="otherAsset_th"
                              >
                                Select Owner*
                              </th>
                              <th className="otherAsset_th">Asset Caption</th>
                              <th className="otherAsset_th">Asset Name*</th>
                              <th className="otherAsset_th">Asset Value*</th>
                              <th className="otherAsset_150px">Document</th>
                              <th style={{ width: "190px" }}>
                                Select Your Beneficiary*
                              </th>
                              <th className="otherAsset_th">Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Select
                                  style={{ width: "190px" }}
                                  className="otherAsset_select"
                                  placeholder="Select Owner"
                                  value={ownerName}
                                  multiple
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "owner")
                                  }
                                  sx={{ minWidth: "13rem" }}
                                  slotProps={{
                                    listbox: {
                                      sx: { width: "100%" },
                                    },
                                  }}
                                  required
                                >
                                  {ownerNames.map((name) => (
                                    <Option key={name} value={name}>
                                      {name}
                                    </Option>
                                  ))}
                                </Select>
                              </td>

                              <td>
                                <Input
                                  className="otherAsset_input"
                                  placeholder="Asset Caption"
                                  onChange={(e) =>
                                    handleChanges(e, "assetCaption")
                                  }
                                  value={data.otherAsset.assetCaption}
                                />
                              </td>

                              <td>
                                <Input
                                  className="otherAsset_input"
                                  placeholder="Asset Name"
                                  onChange={(e) =>
                                    handleChanges(e, "assetName")
                                  }
                                  value={data.otherAsset.assetName}
                                  required
                                />
                              </td>

                              <td>
                                <Input
                                  className="otherAsset_input"
                                  placeholder="Asset Value"
                                  onChange={(e) =>
                                    handleChanges(e, "assetValue")
                                  }
                                  value={data.otherAsset.assetValue}
                                  startDecorator={<div>$</div>}
                                  required
                                />
                                {(!errors.assetValueValidate ||
                                  !errors.assetValueZeroValidate) && (
                                  <FormHelperText error>
                                    <Typography variant="caption" color="error">
                                      {!errors.assetValueValidate
                                        ? "Must be less than: $99,999,999."
                                        : !errors.assetValueZeroValidate
                                        ? "Value can't be zero"
                                        : ""}
                                    </Typography>
                                  </FormHelperText>
                                )}
                              </td>

                              <td>
                                <div className="jewelry_choose_files otherAsset_150px">
                                  <div>
                                    <label
                                      className="otherAsset_choose_file_label"
                                      htmlFor={`files`}
                                    >
                                      Choose Files
                                    </label>
                                  </div>
                                  {selectedImage?.length > 0 && (
                                    <div>
                                      <label className="jewelry_choose_images_file">
                                        {selectedImage?.length === 1
                                          ? `${
                                              selectedImage[0].name.length > 15
                                                ? selectedImage[0].name.substring(
                                                    0,
                                                    4
                                                  ) +
                                                  "..." +
                                                  selectedImage[0].name.slice(
                                                    -6
                                                  )
                                                : selectedImage[0].name
                                            }`
                                          : `${selectedImage?.length} files`}
                                      </label>
                                    </div>
                                  )}

                                  <input
                                    style={{
                                      display: "none",
                                    }}
                                    id={`files`}
                                    type="file"
                                    name="myfile"
                                    onChange={(e) => handleImageChange(e, 5)}
                                    accept=".pdf"
                                    multiple
                                  />
                                </div>
                              </td>

                              <td style={{ width: "190px" }}>
                                <div
                                  style={{ width: "190px" }}
                                  className="bene-select beneficiary_btn"
                                  onClick={handleShowBeneficiary}
                                >
                                  Select Your Beneficiary
                                </div>
                              </td>
                              <td>
                                <Textarea
                                  placeholder="Notes"
                                  id="notes"
                                  onChange={(e) => handleChanges(e, "notes")}
                                  value={data.otherAsset.notes}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="text-center">
                        <Button outline className="my-estate-add-btn">
                          Update
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </container>
            </Form>
          </div>
        </div>

        {benevisible && (
          <SharedBeneficiaryEdit
            assetName={"otherAsset"}
            responseData={responseData}
            responseAssetData={responseAssetData}
            handleTrustSave={handleTrustSave}
            assetData={data?.otherAsset}
            trusts={trusts}
            handleChanges={handleChanges}
            sharedType={sharedType}
            handleCloseBeneficiaryPopup={handleEditCloseBeneficiary}
            estimatedTotalAmount={estimatedTotalAmount}
            typeBeneficiary={"normal"}
            sharedDetails={data?.sharedDetails}
          />
        )}
      </SideBar>
    </UserBase>
  );
}
export default EditOtherAssets;
