import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Form,
} from "reactstrap";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser
} from "../../../services/user-service";
//import { useState } from 'react'
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Option, Select, Textarea } from "@mui/joy";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import SharedBeneficiaryEdit from "../../../components/SharedBeneficiaryEdit";
import "../../../css/formPOPup.css";
import "../../../css/myestate_edit.css";
import {
  getSinglejewelry,
  updateJewelries,
} from "../../../services/JewelryService";

function EditJewelry() {
  const { id } = useParams();
  const navigate = useNavigate();
  // }
  const [data, setData] = useState({
    jewelry: {
      owner: "",
      jewelryCaption: "",
      jewelryName: "",
      otherJewelryName: "",
      quantity: "",
      perQuantityValue: "",
      estimatedValue: "",
      caratValue: "",
      weight: "",
      trust: "",
      beneficiaryName: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "caratValue") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          caratValue: newValue,
        },
      }));
    }

    if (stringValue === "jewelryName") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          jewelryName: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();

  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState([]);

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = "";
      }
    }
  };

  const handleImageChange1 = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`imageFiles${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage1(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid image formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // const handleChanges = (event, property) => {
  //   setData({ ...data, [property]: event.target.value });
  // };

  useEffect(() => {
    if (data.jewelry.jewelryName !== "Other") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          otherJewelryName: "",
        },
      }));
    }
  }, [data.jewelry.jewelryName]);

  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          trust: "",
        },
      }));
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          beneficiaryName: "",
        },
      }));
    } else {
      if (field === "quantity" || field === "perQuantityValue") {
        if (field === "quantity") {
          setData((prevData) => ({
            ...prevData,
            jewelry: {
              ...prevData.jewelry,
              [field]: newValue,
              estimatedValue: newValue * data.jewelry.perQuantityValue,
            },
          }));
        } else {
          setData((prevData) => ({
            ...prevData,
            jewelry: {
              ...prevData.jewelry,
              [field]: newValue,
              estimatedValue: newValue * data.jewelry.quantity,
            },
          }));
        }
      } else {
        setData((prevData) => ({
          ...prevData,
          jewelry: {
            ...prevData.jewelry,
            [field]: newValue,
          },
        }));
      }
      setEstimatedTotalAmount(data.jewelry.estimatedValue);
    }
  };

  // post the form
  const jewelryForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();
    const formData = new FormData();

    if (null != selectedImage) {
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
        console.log("this is file indexs", selectedImage[i]);
      }
    }

    for (let i = 0; i < selectedImage1.length; i++) {
      formData.append(`imageFiles`, selectedImage1[i]);
      console.log("this is imageFiles indexs", selectedImage1[i]);
    }

    formData.append("data", JSON.stringify(data));

    updateJewelries(formData, token)
      .then((resp) => {
        toast.success("Updated Successfully!!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        fetchNetWorth(primaryUserDetails.commonId);
        navigate("/user/my-estate/jewelry");
      })
      .catch((error) => {
        console.log(error);
        toast.error("An error occurred. Please try again.", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      });
  };

  const [responseData, setResponseData] = useState(null);
  const [responseAssetData, setResponseAssetData] = useState(null);
  const getData = () => {
    let token = "Bearer " + getToken();
    getSinglejewelry(token, id).then((res) => {
      setData({
        ...data,
        jewelry: res.jewelry,
        documents: res.documents,
        sharedDetails: res.sharedDetails,
        supportingImages: res.supportingImages,
      });
      setResponseData(res);
      setResponseAssetData(res?.jewelry);
      const ownerNameArray = res.jewelry.owner.split(", ");

      setOwnerName(ownerNameArray);
      if (res.jewelry.trust !== "" && res.jewelry.trust != null) {
        setSharedType("Trust");
      } else if (
        res.jewelry.beneficiaryName !== "" &&
        res.jewelry.beneficiaryName != null
      ) {
        setSharedType("Beneficiary");
      } else {
        setSharedType(null);
      }
      setEstimatedTotalAmount(res.jewelry.estimatedValue);
    });
  };
  // Get data based on the jewelery id
  // const getData = () => {
  //   let token = "Bearer " + getToken();
  //   getjewelery(token, jewelryId)
  //     .then((res) => {
  //       setData({
  //         ...data,
  //         details: res.details,
  //         estimatedValue: res.estimatedValue,
  //         keratValue: res.keratValue,
  //         weight: res.weight,
  //         notes: res.notes,
  //         benificiary: res.benificiary,
  //         jewelryCaption: res.jewelryCaption
  //       });
  //       console.log(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       console.log("Data not loaded");

  //     });
  // };
  // const [metalPrice, setMetalPrice] = useState(0);

  // useEffect(() => {
  //   // Fetch metal price from the API and store it in the state

  //   const fetchMetalPrice = () => {
  //     if (data.jewelry.jewelryName === "") {
  //       setMetalPrice(0);
  //       if (data.jewelry.caratValue === "") {
  //         setMetalPrice(0);
  //         return;
  //       }
  //       return;
  //     }
  //     // if (data.keratValue === "") {
  //     //   setMetalPrice(0);
  //     //   return;
  //     // }

  //     const headers = {
  //       "x-access-token": "goldapi-sw18arlkmh58d1-io",
  //       "Content-Type": "application/json",
  //     };
  //     const metalAPIEndpoint = `https://www.goldapi.io/api/${data.jewelry.jewelryName}/USD`;
  //     axios
  //       .get(metalAPIEndpoint, { headers: headers })
  //       .then((res) => {
  //         setMetalPrice(res.data[data.jewelry.caratValue]); // Assuming the API response contains the price for 24K gold per gram
  //       })
  //       .catch((error) => {
  //         // console.log("Error: ", "Failed to fetch the metal price");
  //       });
  //   };

  //   fetchMetalPrice();
  //   getData(); // Moved the getData call inside the same useEffect
  // }, [data.jewelry.jewelryName, data.jewelry.caratValue]);

  // ... (remaining existing functions)

  // useEffect(() => {
  //   const calculateEstimatedValue = () => {
  //     if (data.jewelry.jewelryName && data.jewelry.weight && metalPrice) {
  //       // Assuming the selected metal unit is 24K and using the metal price for 24K gold to calculate the estimated value
  //       const estimatedValue = data.jewelry.weight * metalPrice;

  //       setData((prevData) => ({
  //         ...prevData,
  //         jewelry: {
  //           ...prevData.jewelry,
  //           estimatedValue: estimatedValue.toFixed(3),
  //         },
  //       }));

  //       // setData((prevData) => ({
  //       //   ...prevData,
  //       //   estimatedValue: estimatedValue.toFixed(3),
  //       // }));
  //     } else {
  //       setData((prevData) => ({
  //         ...prevData,
  //         jewelry: {
  //           ...prevData.jewelry,
  //           estimatedValue: "",
  //         },
  //       }));
  //     }
  //   };

  //   calculateEstimatedValue();
  // }, [data.jewelry.jewelryName, data.jewelry.weight, metalPrice]);

  // const getDisplayName = (metadataValue) => {
  //   switch (metadataValue) {
  //     case "XAU":
  //       return "Gold";
  //     case "XAG":
  //       return "Silver";
  //     case "XPT":
  //       return "Platinum";
  //     case "XPD":
  //       return "Palladium";
  //     default:
  //       return metadataValue;
  //   }
  // };
  // const getDisplayKeratValue = (keratValue) => {
  //   switch (keratValue) {
  //     case "":
  //       return "24k";
  //     case "price_gram_22k":
  //       return "22k";
  //     case "price_gram_21k":
  //       return "21k";
  //     case "price_gram_20k":
  //       return "20k";
  //     case "price_gram_18k":
  //       return "18k";
  //     case "price_gram_16k":
  //       return "16k";
  //     case "price_gram_14k":
  //       return "14k";
  //     case "price_gram_10k":
  //       return "10k";
  //     default:
  //       return keratValue;
  //   }
  // };
  useEffect(() => {
    getData();
    getBenificiarydata();
    getTrustsData();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.jewelry.estimatedValue);
  };

  const handleEditCloseBeneficiary = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div>
            <Form onSubmit={jewelryForm} className="addform_body">
              <container>
                <div className="homepage jewelry_container">
                  <Card>
                    <CardHeader>
                      <div
                        style={{ width: "100%" }}
                        className="addform_heading"
                      >
                        <h3 className="addform_heading_h1">
                          {data.jewelry.jewelryListName}
                        </h3>
                        <div className="addform_close">
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => {
                              navigate("/user/my-estate/jewelry");
                            }}
                          />
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="jewelry_card_body">
                      <div>
                        <table className="jewelry_table">
                          <thead>
                            <tr>
                              <th className="jewelry_130px">Select Owner*</th>
                              <th className="jewelry_110px">Jewelry Type*</th>
                              <th className="jewelry_130px">Jewelry Name*</th>
                              <th className="jewelry_100px">Weight(gm)*</th>
                              <th className="jewelry_90px">Quantity*</th>
                              <th className="jewelry_90px">Qty/Value*</th>
                              <th className="jewelry_130px">Appraised Val*</th>
                              <th>Images</th>
                              <th>Documents</th>
                              <th>Beneficiary*</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Select
                                  className="jewelry_select jewelry_130px"
                                  placeholder="Select Owner"
                                  value={ownerName}
                                  multiple
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "owner")
                                  }
                                  sx={{ minWidth: "13rem" }}
                                  slotProps={{
                                    listbox: {
                                      sx: { width: "100%" },
                                    },
                                  }}
                                  required
                                >
                                  {ownerNames.map((name) => (
                                    <Option key={name} value={name}>
                                      {name}
                                    </Option>
                                  ))}
                                </Select>
                              </td>

                              <td>
                                <div className="jewelry_select_and_input">
                                  <div>
                                    <Select
                                      className="jewelry_select jewelry_110px"
                                      value={data.jewelry.jewelryName}
                                      onChange={(event, newValue) =>
                                        handleChange(
                                          event,
                                          newValue,
                                          "jewelryName"
                                        )
                                      }
                                      required
                                    >
                                      <Option value="">Jewelry Type</Option>
                                      <Option value="Gold">Gold</Option>
                                      <Option value="Silver">Silver</Option>
                                      <Option value="Platinum">Platinum</Option>
                                      <Option value="Palladium">
                                        Palladium
                                      </Option>
                                      <Option value="Stone">Stone</Option>
                                      <Option value="Other">Other</Option>
                                    </Select>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <Input
                                  className="jewelry_input jewelry_130px"
                                  placeholder="Jewelry Name"
                                  onChange={(e) =>
                                    handleChanges(e, "jewelryCaption")
                                  }
                                  value={data.jewelry.jewelryCaption}
                                />
                              </td>
                              {/* <td>
                                <Select
                                  className="jewelry_select jewelry_130px"
                                  value={data.jewelry.caratValue}
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "caratValue")
                                  }
                                  required
                                >
                                  <Option value="">Carat Value</Option>
                                  <Option value="24k">24k</Option>
                                  <Option value="22k">22k</Option>
                                  <Option value="21k">21k</Option>
                                  <Option value="20k">20k</Option>
                                  <Option value="18k">18k</Option>
                                  <Option value="16k">16k</Option>
                                  <Option value="14k">14k</Option>
                                  <Option value="10k">10k</Option>
                                </Select>
                              </td> */}
                              <td>
                                <Input
                                  className="jewelry_input jewelry_100px"
                                  type="number"
                                  placeholder="Weight"
                                  onChange={(e) => handleChanges(e, "weight")}
                                  value={data.jewelry.weight}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  className="jewelry_input jewelry_90px"
                                  placeholder="Quantity"
                                  onChange={(e) => handleChanges(e, "quantity")}
                                  value={data.jewelry.quantity}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  type="number"
                                  className="jewelry_input jewelry_90px"
                                  placeholder="Value"
                                  onChange={(e) =>
                                    handleChanges(e, "perQuantityValue")
                                  }
                                  value={data.jewelry.perQuantityValue}
                                  startDecorator={<div>$</div>}
                                  required
                                />
                              </td>
                              <td>
                                <Input
                                  className="jewelry_input jewelry_130px"
                                  id={`estimatedValue`}
                                  placeholder="Appraised Value"
                                  type="number"
                                  onChange={(e) => {
                                    handleChanges(e, "estimatedValue");
                                  }}
                                  value={data.jewelry.estimatedValue}
                                  startDecorator={<div>$</div>}
                                  required
                                  disabled
                                />
                              </td>
                              <td>
                                <div className="jewelry_choose_images">
                                  <div>
                                    <label
                                      className="jewelry_choose_images_label"
                                      htmlFor={`images`}
                                    >
                                      Images
                                    </label>
                                  </div>
                                  {selectedImage1?.length > 0 && (
                                    <div>
                                      <label className="jewelry_choose_images_file">
                                        {selectedImage1?.length === 1
                                          ? `${
                                              selectedImage1[0].name.length > 15
                                                ? selectedImage1[0].name.substring(
                                                    0,
                                                    4
                                                  ) +
                                                  "..." +
                                                  selectedImage1[0].name.slice(
                                                    -6
                                                  )
                                                : selectedImage1[0].name
                                            }`
                                          : `${selectedImage1?.length} images`}
                                      </label>
                                    </div>
                                  )}

                                  <input
                                    style={{
                                      display: "none",
                                    }}
                                    id={`images`}
                                    type="file"
                                    name="images"
                                    onChange={(e) => handleImageChange1(e)}
                                    multiple
                                    accept="image/*"
                                  />
                                </div>
                              </td>

                              <td>
                                <div className="jewelry_choose_files">
                                  <div>
                                    <label
                                      className="jewelry_choose_file_label"
                                      htmlFor={`files`}
                                    >
                                      Documents
                                    </label>
                                  </div>
                                  {selectedImage?.length > 0 && (
                                    <div>
                                      <label className="jewelry_choose_images_file">
                                        {selectedImage?.length === 1
                                          ? `${
                                              selectedImage[0].name.length > 15
                                                ? selectedImage[0].name.substring(
                                                    0,
                                                    4
                                                  ) +
                                                  "..." +
                                                  selectedImage[0].name.slice(
                                                    -6
                                                  )
                                                : selectedImage[0].name
                                            }`
                                          : `${selectedImage?.length} files`}
                                      </label>
                                    </div>
                                  )}

                                  <input
                                    style={{
                                      display: "none",
                                    }}
                                    id={`files`}
                                    type="file"
                                    name="myfile"
                                    onChange={(e) => handleImageChange(e, 5)}
                                    accept=".pdf"
                                    multiple
                                  />
                                </div>
                              </td>

                              <td>
                                <div
                                  className="bene-select"
                                  onClick={handleShowBeneficiary}
                                  style={{
                                    cursor: "pointer",
                                  }}
                                >
                                  Beneficiary
                                </div>
                              </td>
                              <td>
                                <Textarea
                                  placeholder="Notes"
                                  id="notes"
                                  onChange={(e) => handleChanges(e, "notes")}
                                  value={data.jewelry.notes}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="text-center">
                        <Button outline className="my-estate-add-btn">
                          Update
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </container>
            </Form>
          </div>
        </div>

        {benevisible && (
          <SharedBeneficiaryEdit
            assetName={"jewelry"}
            responseData={responseData}
            responseAssetData={responseAssetData}
            handleTrustSave={handleTrustSave}
            assetData={data?.jewelry}
            trusts={trusts}
            handleChanges={handleChanges}
            sharedType={sharedType}
            handleCloseBeneficiaryPopup={handleEditCloseBeneficiary}
            estimatedTotalAmount={estimatedTotalAmount}
            typeBeneficiary={"normal"}
            sharedDetails={data?.sharedDetails}
          />
        )}
      </SideBar>
    </UserBase>
  );
}

export default EditJewelry;
