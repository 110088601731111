import {
  faCarSide,
  faDownload,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
} from "@mui/joy";
import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import {
  formatAmount,
  validateEightDigitNumber,
} from "../../components/Common";
import SharedBeneficiary from "../../components/SharedBeneficiary";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import "../../css/formPOPup.css";
import "../../css/myestare.css";
import {
  deleteVehicle,
  getVehicle,
  vehicle,
} from "../../services/VehicleService";
import {
  deleteSingleProperty,
  downloadDocument1,
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../services/user-service";
import Deletebutton from "./Deletebutton";
import UpdateButton from "./UpdateButton";

function Vehicles() {
  const [data, setData] = useState({
    vehicle: {
      owner: "",
      vehicleCaption: "",
      vehicleType: "",
      otherVehicleType: "",
      year: "",
      vinNumber: "",
      make: "",
      otherMake: "",
      estValue: "",
      isPartOfNetWorth: "true",
      model: "",
      otherModel: "",
      miles: "",
      trust: "",
      notes: "",
    },
    mortgage: {
      mortgage: "",
      mortgageInstitution: "",
      accountNumber: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [errors, setErrors] = useState({
    estValueValidate: true,
    mortgageAmountValidate: true,
    estValueZeroValidate: true,
    mortgageAmountZeroValidate: true,
  });

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "vehicleType") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          vehicleType: newValue,
        },
      }));
    }

    if (stringValue === "type") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          type: newValue,
        },
      }));
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          make: "",
          model: "",
        },
      }));
    }

    if (stringValue === "make") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          make: newValue,
        },
      }));
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          model: "",
        },
      }));
    }

    if (stringValue === "model") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          model: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string
      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  const [sharedType, setSharedType] = useState(null);
  const currentYear = new Date().getFullYear();
  const handleChanges = (e, field, section) => {
    let newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          trust: "",
        },
      }));
    } else {
      if (section === "vehicle" || field === "trust") {
        if (field === "year" && newValue > currentYear) {
          if (newValue > currentYear) {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: "",
              },
            }));
            toast.error("You cannot select a future year.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else if (newValue.length === 4 && newValue < 1900) {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: "",
              },
            }));
            toast.error("You cannot select year before 1900.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: newValue,
              },
            }));
          }
        } else {
          if (field === "isPartOfNetWorth") {
            newValue =
              data.vehicle.isPartOfNetWorth === "false" ? "true" : "false";
          }
          setData((prevData) => ({
            ...prevData,
            vehicle: {
              ...prevData.vehicle,
              [field]: newValue,
            },
          }));

          if (field === "estValue") {
            errorStates.estValueValidate =
              newValue !== "" ? validateEightDigitNumber(newValue) : true;
            errorStates.estValueZeroValidate =
              newValue !== "" ? Number(newValue) > 0 : true;
            setEstimatedTotalAmount(data.vehicle.estValue);
          }
        }
      } else {
        if (field === "mortgage") {
          errorStates.mortgageAmountValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.mortgageAmountZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
        }

        setData((prevData) => ({
          ...prevData,
          mortgage: {
            ...prevData.mortgage,
            [field]: newValue,
          },
        }));
      }
    }

    setErrors(errorStates);
  };
  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };
  const resetForm = () => {
    setData({
      vehicle: {
        vehicleCaption: "",
        vehicleType: "",
        year: "",
        vinNumber: "",
        make: "",
        estValue: "",
        isPartOfNetWorth: "true",
        model: "",
        miles: "",
        trust: "",
        notes: "",
      },
      mortgage: {
        mortgage: "",
        mortgageInstitution: "",
        accountNumber: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
    setOwnerName([]);
    handleReset();
    setIsChecked(false);
    setErrors({
      estValueValidate: true,
      mortgageAmountValidate: true,
      estValueZeroValidate: true,
      mortgageAmountZeroValidate: true,
    });
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  // Set the form
  const vehiclesForm = (event) => {
    event.preventDefault();

    const hasErrors = Object.values(errors).includes(false);
    if (!hasErrors) {
      let token = "Bearer " + getToken();

      //create form data to send a file and remaining class data
      const formData = new FormData();

      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
      formData.append("data", JSON.stringify(data));

      vehicle(formData, token)
        .then((resp) => {
          AddCard();
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });

          fetchNetWorth(primaryUserDetails.commonId);
          toggle();
        })
        .catch((error) => {});
    }
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removeVehicle = (id, idType) => {
    setShowConfirmation(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  // Code by Purnendu
  const handleRemove = (params) => {
    setShowConfirmation(false);
    const { Id, idType } = params;
    if (idType === "vehicleId") {
      deleteVehicle(Id)
        .then((res) => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
          AddCard();
          setShow1(false);
        })
        .catch((error) => {});
    } else {
      deleteSingleProperty(Id)
        .then((res) => {
          setBeneficiaryVisible(!beneficiaryVisible);
          setShow1(false);
          AddCard();
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
        })
        .catch((error) => {});
    }
  };

  const handleDownload = (id, fileName) => {
    let myarry = fileName.split(".");
    downloadDocument1(id)
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${myarry[0]}.${myarry[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {});
  };

  // show notes popup
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };

  const [popupVisibleDownlaod, setPopupVisibleDownlaod] = useState(false);
  const [selectedDownlaod, setSelectDownload] = useState("");

  const handleShowDownlaod = (showDetail) => {
    setPopupVisibleDownlaod(true);
    setSelectDownload(showDetail);
  };

  // page opening  animation
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  //  form show button
  let [form1, setForm1] = useState(false);

  const toggle = () => {
    resetForm();
    setForm1(!form1);
  };

  // cards
  let [card, setCard] = useState([]); // card = [ {} , {} , {}] - include the form data going to use it for card
  let [showDetail, setShowDetail] = useState([]); // this is to display the card details
  let [show1, setShow1] = useState(false);

  // card creating
  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken(); // Added 'Bearer'
    getVehicle(token, userId)
      .then((res) => {
        setCard(res);
      })
      .catch((error) => {
        setCard([]);
      });
  };

  // showing the details of cards like popup
  const Showdetails = (obj) => {
    setShowDetail(obj);
    setShow1(true);
  };

  useEffect(() => {
    AddCard();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  // for add field pop
  let [showAdditionField, SetshowAdditionField] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [showAdditionField1, setshowAdditionField1] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.vehicle.estValue);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleOpenBeneficiary = (showDetail) => {
    setBeneficiaryVisible(true);
  };

  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const handleReset = () => {
    setbeneVisible(false);
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  const maskCardNumber = (accountNumber) => {
    return "•".repeat(accountNumber.length);
  };

  const maskNumberRemaining4digits = (accountNumber) => {
    if (accountNumber.length <= 4) {
      return maskCardNumber(accountNumber);
    } else {
      const visibleDigits = accountNumber.slice(-4);
      const maskedDigits = "•".repeat(accountNumber.length - 4);
      return maskedDigits + visibleDigits;
    }
  };

  const famousCompanies = [
    "ACURA",
    "ALFA ROMEO",
    "ASTON MARTIN",
    "AUDI",
    "BENTLEY",
    "BMW",
    "BUGATTI",
    "BUICK",
    "BYD",
    "CADILLAC",
    "CHEVROLET",
    "CHRYSLER",
    "CITROEN",
    "DAIHATSU",
    "DODGE",
    "DONGFENG",
    "FERRARI",
    "FIAT",
    "FISKER",
    "FORD",
    "GEELY",
    "GENESIS",
    "GMC",
    "HOLDEN",
    "HONDA",
    "HUMMER",
    "HYUNDAI",
    "INFINITI",
    "ISUZU",
    "JAGUAR",
    "JEEP",
    "KARMA",
    "KIA",
    "KOENIGSEGG",
    "LAMBORGHINI",
    "LANCIA",
    "LAND ROVER",
    "LEXUS",
    "LINCOLN",
    "LOTUS",
    "LUCID",
    "MAHINDRA",
    "MASERATI",
    "MAZDA",
    "MCLAREN",
    "MERCEDES-BENZ",
    "MINI",
    "MITSUBISHI",
    "MORGAN",
    "MOSLER",
    "NISSAN",
    "OPEL",
    "PAGANI",
    "PEUGEOT",
    "POLESTAR",
    "PORSCHE",
    "RAM",
    "RIVIAN",
    "ROLLS-ROYCE",
    "SAAB",
    "SALEEN",
    "SHELBY",
    "SMART",
    "SPYKER",
    "SUBARU",
    "SUZUKI",
    "TESLA",
    "TOYOTA",
    "VECTOR",
    "VINFAST",
    "VOLKSWAGEN",
    "VOLVO",
    "Other",
  ];

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button onClick={() => toggle()}>Add New Vehicle</button>
              </div>
            </div>
          )}

          <div className="propCard">
            <div className="propCard-card">
              {card.map((entity) => (
                <div className="propCard-card-body" key={entity.vehicle.id}>
                  <div className="propCard_card_status">
                    <p
                      className={`propCard_card_status_text ${
                        entity.sharedDetails.length > 0 ||
                        (entity.vehicle.trust &&
                          entity.vehicle.trust !== "" &&
                          entity.vehicle.trust !== null)
                          ? "completed"
                          : "incomplete"
                      }`}
                    >
                      {entity.sharedDetails.length > 0 ||
                      (entity.vehicle.trust &&
                        entity.vehicle.trust !== "" &&
                        entity.vehicle.trust !== null)
                        ? "completed"
                        : "incomplete"}
                    </p>
                  </div>
                  <h5 className="propCard-card-title">
                    {entity.vehicle.vehicleCaption}
                  </h5>
                  <p className="propCard-card-text"> {entity.vehicle.make}</p>
                  <div className="propCard-btn">
                    <p className=" viewDetails">
                      <button onClick={() => Showdetails(entity)}>
                        View Details
                      </button>
                    </p>
                  </div>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              ))}
            </div>
          </div>

          {form1 && (
            <div
              className="overlay1"
              style={{ transition: "500ms", height: "" }}
            >
              <div className="addform_ichest">
                <div className="addform_main">
                  <div className="addform_heading">
                    <h3 className="addform_heading_h1">Add Vehicle</h3>
                    <div className="addform_close" onClick={toggle}>
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </div>
                  <div className="addform_body">
                    <Form onSubmit={vehiclesForm} className="addform_body">
                      <div className="addform_select_body">
                        <div className="addform_body_left">
                          <div>
                            <Tooltip title="Select Owner" className="mt-2">
                              <div>
                                <FormLabel required>Select Owner</FormLabel>
                                <Autocomplete
                                  multiple
                                  id="tags-default"
                                  placeholder="Select Owner"
                                  options={ownerNames}
                                  value={ownerName}
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "owner")
                                  }
                                  required={ownerName.length > 0 ? false : true}
                                />
                              </div>
                            </Tooltip>
                          </div>

                          <div>
                            <Tooltip title="Enter your vehicle heading">
                              <div className="mt-2">
                                <FormLabel>Vehicle Heading</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Enter vehicle heading"
                                  onChange={(e) =>
                                    handleChanges(
                                      e,
                                      "vehicleCaption",
                                      "vehicle"
                                    )
                                  }
                                  value={data.vehicle.vehicleCaption}
                                />
                              </div>
                            </Tooltip>
                          </div>

                          <div className="address_input">
                            <div className="mt-2 addform_input_half">
                              <div>
                                <Tooltip title="Enter the type of your vehicle">
                                  <div className="mt-2">
                                    <FormLabel required>Vehicle Type</FormLabel>
                                    <Select
                                      value={data.vehicle.vehicleType}
                                      onChange={(event, newValue) =>
                                        handleChange(
                                          event,
                                          newValue,
                                          "vehicleType"
                                        )
                                      }
                                      required
                                    >
                                      <Option value="">
                                        Select type of vehicle
                                      </Option>
                                      <Option value="Auto">Auto</Option>
                                      <Option value="Two Wheeler">
                                        Two Wheeler
                                      </Option>
                                      <Option value="Boat">Boat</Option>
                                      <Option value="Yacht">Yacht</Option>
                                      <Option value="Other">Other</Option>
                                    </Select>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>

                            <div className="mt-2 addform_input_half">
                              <div>
                                <Tooltip title="Enter the year of Manufacture of your vehicle">
                                  <div className="mt-2">
                                    <FormLabel required>
                                      Manufactured Year
                                    </FormLabel>
                                    <Input
                                      type="number"
                                      onChange={(e) =>
                                        handleChanges(e, "year", "vehicle")
                                      }
                                      value={data.vehicle.year}
                                      inputProps={{
                                        max: currentYear,
                                      }}
                                      placeholder="Manufactured Year"
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>

                          {data.vehicle.vehicleType === "Other" && (
                            <div>
                              <Tooltip title="Enter your other vehicle type">
                                <div className="mt-2">
                                  <FormLabel>Other Vehicle Type</FormLabel>
                                  <Input
                                    className="input_mui_joy"
                                    placeholder="Enter Other Vehicle"
                                    onChange={(e) =>
                                      handleChanges(
                                        e,
                                        "otherVehicleType",
                                        "vehicle"
                                      )
                                    }
                                    value={data.vehicle.otherVehicleType}
                                  />
                                </div>
                              </Tooltip>
                            </div>
                          )}

                          <div className="address_input">
                            <div className="mt-2 addform_input_half">
                              <div>
                                <Tooltip title="Enter the vin number">
                                  <div>
                                    <FormLabel>Vin Number</FormLabel>
                                    <Input
                                      placeholder="Enter vin number"
                                      value={data.vehicle.vinNumber}
                                      onChange={(e) =>
                                        handleChanges(e, "vinNumber", "vehicle")
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>

                            <div className="mt-2 addform_input_half">
                              <div>
                                <Tooltip title="Enter the company of your vehicle">
                                  <div>
                                    <FormLabel required>Make</FormLabel>

                                    <Input
                                      placeholder="Enter make"
                                      value={data.vehicle.make}
                                      onChange={(e) =>
                                        handleChanges(e, "make", "vehicle")
                                      }
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>

                          <div className="address_input">
                            <div className="mt-2 addform_input_half">
                              <div>
                                <Tooltip title="Enter the model of your vehicle">
                                  <div>
                                    <FormLabel>Model</FormLabel>
                                    <Input
                                      placeholder="Enter model"
                                      value={data.vehicle.model}
                                      onChange={(e) =>
                                        handleChanges(e, "model", "vehicle")
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>

                            <div className="mt-2 addform_input_half">
                              <div>
                                <Tooltip title="Enter your vehicle mileage">
                                  <div>
                                    <FormLabel>Miles</FormLabel>
                                    <Input
                                      placeholder="Enter miles"
                                      type="number"
                                      onChange={(e) =>
                                        handleChanges(e, "miles", "vehicle")
                                      }
                                      value={data.vehicle.miles}
                                    />
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>

                          <div>
                            <Tooltip title="Enter estimated value">
                              <div className="mt-2">
                                <FormLabel required>Estimated Value</FormLabel>
                                <Input
                                  type="number"
                                  className="input_mui_joy"
                                  placeholder="Enter Estimated Value"
                                  onChange={(e) =>
                                    handleChanges(e, "estValue", "vehicle")
                                  }
                                  value={data.vehicle.estValue}
                                  startDecorator={<div>$</div>}
                                  required
                                />
                                {(!errors.estValueValidate ||
                                  !errors.estValueZeroValidate) && (
                                  <FormHelperText error>
                                    <Typography variant="caption" color="error">
                                      {!errors.estValueValidate
                                        ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                        : !errors.estValueZeroValidate
                                        ? "Value can't be zero"
                                        : ""}
                                    </Typography>
                                  </FormHelperText>
                                )}
                              </div>
                            </Tooltip>
                          </div>

                          <div className="mt-2 switch_changes_main">
                            <div className="switch_changes_base">
                              <div>
                                Do you want to include the asset value as part
                                of your net worth?
                              </div>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={
                                        data.vehicle.isPartOfNetWorth === "true"
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        handleChanges(
                                          e,
                                          "isPartOfNetWorth",
                                          "vehicle"
                                        )
                                      }
                                      color="primary"
                                      size="normal"
                                    />
                                  }
                                  label={
                                    data?.vehicle?.isPartOfNetWorth === "true"
                                      ? "Yes"
                                      : "No"
                                  }
                                  labelPlacement="end"
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="addform_body_right_crypto">
                          <div className="mt-2 switch_changes_main">
                            <div className="switch_changes_base">
                              <div>Do you have loan against vehicle?</div>
                              <div>
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={isChecked}
                                      onChange={handleSwitchChange}
                                      color="primary"
                                      size="normal"
                                    />
                                  }
                                  label={isChecked ? "Yes" : "No"}
                                  labelPlacement="end"
                                />
                              </div>
                            </div>
                          </div>
                          {isChecked && (
                            <div>
                              <div>
                                <div>
                                  <div>
                                    <FormLabel required>
                                      {`Institution/Bank`}
                                    </FormLabel>

                                    <Input
                                      className="input_mui_joy"
                                      placeholder={`Institution/Bank`}
                                      onChange={(e) =>
                                        handleChanges(
                                          e,
                                          "mortgageInstitution",
                                          "mortgage"
                                        )
                                      }
                                      value={
                                        data?.mortgage?.mortgageInstitution
                                      }
                                      required
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="address_input">
                                <div className="mt-2 addform_input_half">
                                  <div style={{ width: "100%" }}>
                                    <FormLabel required>
                                      {`Account Number`}
                                    </FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      type="number"
                                      placeholder={`Account Number`}
                                      onChange={(e) =>
                                        handleChanges(
                                          e,
                                          "accountNumber",
                                          "mortgage"
                                        )
                                      }
                                      value={data?.mortgage?.accountNumber}
                                      required
                                    />
                                  </div>
                                </div>
                                <div className="mt-2 addform_input_half">
                                  <div>
                                    <FormLabel required>{`Amount`}</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      type="number"
                                      placeholder="Amount"
                                      onChange={(e) =>
                                        handleChanges(e, "mortgage", "mortgage")
                                      }
                                      value={data?.mortgage?.mortgage}
                                      startDecorator={<div>$</div>}
                                      required
                                    />
                                    {(!errors.mortgageAmountValidate ||
                                      !errors.mortgageAmountZeroValidate) && (
                                      <FormHelperText error>
                                        <Typography
                                          variant="caption"
                                          color="error"
                                        >
                                          {!errors.mortgageAmountValidate
                                            ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                            : !errors.mortgageAmountZeroValidate
                                            ? "Value can't be zero"
                                            : ""}
                                        </Typography>
                                      </FormHelperText>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div>
                            <Tooltip title="Add your banks related file">
                              <div className="mt-2">
                                <FormLabel>Supporting Document</FormLabel>
                                <input
                                  style={{
                                    border: "solid 1px lightgray",
                                    borderLeft: "none",
                                    width: "100%",
                                    borderRadius: "5px",
                                  }}
                                  type="file"
                                  name="myfile"
                                  id="exampleFile"
                                  onChange={(e) => handleImageChange(e, 5)}
                                  accept=".pdf"
                                  multiple
                                />
                              </div>
                            </Tooltip>
                          </div>
                          <div
                            className="bene-select mt-3"
                            onClick={handleShowBeneficiary}
                            style={{ cursor: "pointer" }}
                          >
                            Select Your Beneficiary
                          </div>

                          <div className="address_input">
                            <div style={{ width: "100%" }} className="mt-2">
                              <div>
                                <FormLabel>Notes</FormLabel>
                                <Textarea
                                  sx={{ height: "182px", minHeight: "52px" }}
                                  placeholder="Notes"
                                  id="notes"
                                  onChange={(e) =>
                                    handleChanges(e, "notes", "vehicle")
                                  }
                                  value={data.vehicle.notes}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <Button
                          className="my-estate-clear-btn"
                          onClick={resetForm}
                          type="reset"
                          outline
                        >
                          Clear
                        </Button>
                        <Button outline type="" className="my-estate-add-btn">
                          Add
                        </Button>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}

          {show1 && Object.keys(showDetail).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faCarSide}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetail.vehicle.vehicleType}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetail.documents &&
                            showDetail.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(showDetail);
                                    setShow(false);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        {primaryUserDetails.accountType === "secondary" &&
                        secondaryUserDetails.accountType === "primary" &&
                        secondaryUserDetails.isSecondaryUserEditable ===
                          "false" ? (
                          ""
                        ) : (
                          <div>
                            <Tooltip title="Click Here To Edit">
                              <div>
                                <UpdateButton
                                  URL={"../my-estate/vehicles/"}
                                  id={showDetail.vehicle.id}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}

                        {primaryUserDetails.accountType === "secondary" &&
                        secondaryUserDetails.accountType === "primary" &&
                        secondaryUserDetails.isSecondaryUserEditable ===
                          "false" ? (
                          ""
                        ) : (
                          <div>
                            <FontAwesomeIcon
                              onClick={() =>
                                removeVehicle(
                                  showDetail.vehicle.id,
                                  "vehicleId"
                                )
                              }
                              className="delete-button-comp"
                              style={{ cursor: "pointer" }}
                              icon={faTrash}
                            />
                          </div>
                        )}

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShow1(!show1);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetail.vehicle.owner && (
                          <p>
                            Owner : <code>{showDetail.vehicle.owner}</code>
                          </p>
                        )}
                        <p>
                          Vehicle Type:{" "}
                          {showDetail.vehicle.vehicleType !== "Other" ? (
                            <code>{showDetail.vehicle.vehicleType}</code>
                          ) : (
                            <code>{showDetail.vehicle.otherVehicleType}</code>
                          )}
                        </p>
                        <p>
                          Year Manufactured:{" "}
                          <code>{showDetail.vehicle.year}</code>
                        </p>

                        <p>
                          Make:{" "}
                          {showDetail.vehicle.make === "Other" ? (
                            <code>{showDetail.vehicle.otherMake}</code>
                          ) : (
                            <code>{showDetail.vehicle.make}</code>
                          )}
                        </p>
                        <p>
                          Miles: <code>{showDetail.vehicle.miles}</code>
                        </p>
                        {showDetail.vehicle.vinNumber && (
                          <p>
                            Vin Number :{" "}
                            <code>{showDetail.vehicle.vinNumber}</code>
                          </p>
                        )}
                      </div>
                      <div className="col2">
                        <p>
                          Model:{" "}
                          {showDetail.vehicle.model === "Other" ? (
                            <code>{showDetail.vehicle.otherModel}</code>
                          ) : (
                            <code>{showDetail.vehicle.model}</code>
                          )}
                        </p>
                        <p>
                          Estimated Value :{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(showDetail.vehicle.estValue)}
                          </code>
                        </p>

                        {showDetail.vehicle.equity && (
                          <p>
                            Estimated Equity :{" "}
                            <code
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {formatAmount(showDetail.vehicle.equity)}
                            </code>
                          </p>
                        )}

                        {showDetail.benificiary && (
                          <p>
                            Beneficiary Name:{" "}
                            <code>{showDetail.benificiary}</code>
                          </p>
                        )}

                        {showDetail.vehicle.vehicleCaption && (
                          <p>
                            Vehicle Caption:{" "}
                            <code>{showDetail.vehicle.vehicleCaption}</code>
                          </p>
                        )}

                        {showDetail.addfield1 && (
                          <Tooltip title={`Click To See Details`}>
                            <p
                              onClick={() => {
                                SetshowAdditionField(showDetail);
                                setShow1(!show1);
                              }}
                            >
                              Additional Fields:&nbsp;
                              <code>
                                {showDetail && showDetail.addfield1
                                  ? showDetail.addfield1.slice(0, 5)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}

                        {showDetail.sharedDetails[0] && (
                          <p
                            onClick={() => {
                              handleOpenBeneficiary(showDetail);
                              setShow1(false);
                            }}
                          >
                            Beneficiary Details{" "}
                            <code>
                              <span className="readmore">Click Here</span>
                            </code>
                          </p>
                        )}

                        {showDetail.vehicle.trust && (
                          <p>
                            Trust Name : <code>{showDetail.vehicle.trust}</code>
                          </p>
                        )}

                        {showDetail?.mortgage && (
                          <Tooltip title={`Click To See Details`}>
                            <p
                              onClick={() => {
                                setshowAdditionField1(showDetail);
                              }}
                            >
                              Loan Details:&nbsp;
                              <code>
                                <span className="readmore">Click to see</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}

                        {showDetail.vehicle.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(showDetail.vehicle.notes);
                                setShow1(!show1);
                              }}
                            >
                              Note:{" "}
                              <code>
                                {" "}
                                {showDetail && showDetail.vehicle.notes
                                  ? showDetail.vehicle.notes.slice(0, 5)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {showConfirmation && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationClose}
            />
          )}

          {popupVisible && (
            // Popup div
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Notes</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow1(!show1);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            // Popup div

            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    <div style={{ marginBottom: "20px" }}>
                      <Tooltip title={selectedDownlaod.name}>
                        <div
                          style={{
                            cursor: "pointer",
                            display: "flex",
                            gap: "20px",
                          }}
                          onClick={() => {
                            handleDownload(selectedDownlaod.name, 0);
                          }}
                        >
                          Downlaod - 1
                          <div className="myestate_download_button dwnbtn">
                            <FontAwesomeIcon
                              className="myestate_download_icon"
                              icon={faDownload}
                            />
                            <span>{selectedDownlaod.name}</span>
                          </div>
                        </div>
                      </Tooltip>
                    </div>

                    {selectedDownlaod.name1 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name1}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name1, 1);
                            }}
                          >
                            Downlaod - 2
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name1}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name2 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name2}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name2, 2);
                            }}
                          >
                            Download - 3
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name2}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name3 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name3}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name3, 3);
                            }}
                          >
                            Download - 4
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name3}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}

                    {selectedDownlaod.name4 && (
                      <div style={{ marginBottom: "20px" }}>
                        <Tooltip title={selectedDownlaod.name4}>
                          <div
                            //  value = {showDetail.user.id}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              gap: "20px",
                            }}
                            onClick={() => {
                              handleDownload(selectedDownlaod.name4, 4);
                            }}
                          >
                            Downlaod - 5
                            <div className="myestate_download_button dwnbtn">
                              <FontAwesomeIcon
                                className="myestate_download_icon"
                                icon={faDownload}
                              />
                              <span>{selectedDownlaod.name4}</span>
                            </div>
                          </div>
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    {selectedDownlaod.documents &&
                      selectedDownlaod.documents.length > 0 &&
                      selectedDownlaod.documents.map((file, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Tooltip title={file.fileName}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                gap: "20px",
                              }}
                              onClick={() => {
                                handleDownload(file.id, file.fileName);
                              }}
                            >
                              Download - {index + 1}
                              <div className="myestate_download_button dwnbtn">
                                <FontAwesomeIcon
                                  className="myestate_download_icon"
                                  icon={faDownload}
                                />
                                <span>{file.fileName}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {beneficiaryVisible && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>share property</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setBeneficiaryVisible(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    {showDetail.sharedDetails &&
                      showDetail.sharedDetails.map((details, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            margin: "5px",
                            padding: "10px",
                            border: "solid 1px lightgray",
                            borderRadius: "5px",
                            minWidth: "230px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "17px",
                                color: "black",
                                fontWeight: "500",
                              }}
                            >
                              Share - {index + 1}
                            </p>

                            <FontAwesomeIcon
                              onClick={() =>
                                removeVehicle(details.id, "sharedDetailId")
                              }
                              className="delete-button-comp"
                              style={{ cursor: "pointer" }}
                              icon={faTrash}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginLeft: "20px",
                            }}
                          >
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Beneficiary Name:
                              <span style={{ marginLeft: "10px" }}>
                                {getBenificiaryName(details.beneficiaryId)}
                              </span>
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Distributed Type:
                              <span style={{ marginLeft: "10px" }}>
                                {details.distributedType}
                              </span>
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Distributed Value:{" "}
                              <span style={{ marginLeft: "10px" }}>
                                {details &&
                                  details.distributedType === "dollar" &&
                                  "$"}
                                {details.distributedValue}
                                {details.distributedType === "percentage" &&
                                  "%"}
                              </span>
                            </p>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "black",
                                fontWeight: "400",
                                marginLeft: "20px",
                              }}
                            >
                              Distributed Amount:
                              <span style={{ marginLeft: "10px" }}>
                                ${details.distributedAmount}
                              </span>
                            </p>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {showAdditionField && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup_heading">
                  <div>
                    <h2>All Additional Field</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        SetshowAdditionField(false);
                        setShow1(!show1);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>
                <div className="popup-body">
                  {showAdditionField.addfield1 !== 0 &&
                  showAdditionField.addfield1 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 1 : {showAdditionField.addfield1}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield2 !== 0 &&
                  showAdditionField.addfield2 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 2 : {showAdditionField.addfield2}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield3 !== 0 &&
                  showAdditionField.addfield3 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 3 : {showAdditionField.addfield3}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield4 !== 0 &&
                  showAdditionField.addfield4 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 4 : {showAdditionField.addfield4}
                    </p>
                  ) : (
                    <p></p>
                  )}
                  {showAdditionField.addfield5 !== 0 &&
                  showAdditionField.addfield5 !== "" ? (
                    <p
                      style={{
                        fontWeight: 450,
                        fontSize: "12px",
                        color: "black",
                      }}
                    >
                      New Field 5 : {showAdditionField.addfield5}
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
              </div>
            </div>
          )}

          {showAdditionField1 && (
            // Display the popup when a row is selected
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup_heading">
                  <div style={{ width: "100%" }}>
                    <h2 style={{ textAlign: "center" }}>Loan Details</h2>
                  </div>
                  <div>
                    <button
                      className="note_popup_heading_close_btn"
                      onClick={() => {
                        setshowAdditionField1(false);
                      }}
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </button>
                  </div>
                </div>

                {showDetail.mortgage && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "5px",
                      padding: "10px",
                      border: "solid 1px lightgray",
                      borderRadius: "5px",
                      minWidth: "230px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {/* <FontAwesomeIcon
                      onClick={() =>
                        removeRealEstate(mortgageList.id, "mortgageId")
                      }
                      className="delete-button-comp"
                      style={{ cursor: "pointer" }}
                      icon={faTrash}
                    /> */}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "20px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "15px",
                          color: "black",
                          fontWeight: "400",
                          marginLeft: "20px",
                        }}
                      >
                        Amount:
                        <span style={{ marginLeft: "10px", fontWeight: "500" }}>
                          {formatAmount(showDetail.mortgage.mortgage)}
                        </span>
                      </p>

                      <p
                        style={{
                          fontSize: "15px",
                          color: "black",
                          fontWeight: "400",
                          marginLeft: "20px",
                        }}
                      >
                        Institution/Bank:
                        <span style={{ marginLeft: "10px", fontWeight: "500" }}>
                          {showDetail.mortgage.mortgageInstitution}
                        </span>
                      </p>

                      <p
                        style={{
                          fontSize: "15px",
                          color: "black",
                          fontWeight: "400",
                          marginLeft: "20px",
                        }}
                      >
                        Account Number:
                        <span style={{ marginLeft: "10px", fontWeight: "500" }}>
                          {maskNumberRemaining4digits(
                            showDetail.mortgage.accountNumber
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {benevisible && (
            <SharedBeneficiary
              handleTrustSave={handleTrustSave}
              assetData={data?.vehicle}
              trusts={trusts}
              handleChanges={handleChanges}
              sharedType={sharedType}
              handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
              estimatedTotalAmount={estimatedTotalAmount}
              typeBeneficiary={"normal"}
              sharedDetails={data?.sharedDetails}
            />
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}

export default Vehicles;
