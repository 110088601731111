import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import {
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../services/user-service";

import {
  Autocomplete,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
} from "@mui/joy";
import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import "../../css/myestare.css";

import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import dayjs from "dayjs";
import { CardFooter } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { countries, validateEightDigitNumber } from "../../components/Common";
import SharedBeneficiary from "../../components/SharedBeneficiary";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import { getInternationalBank } from "../../services/bank-service";
import { addInternationalAsset } from "../../services/InternationalAssetService";
import "./../../css/formPOPup.css";

export function InternationalAssetRealEstate() {
  const navigate = useNavigate();

  // new update
  let [form1, setForm1] = useState(true);
  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    realEstate: {
      owner: "",
      propertyCaption: "",
      propertyType: "",
      otherPropertyType: "",
      aptNumber: "",
      streetAddress: "",
      estPropertyVal: "",
      zipCode: "",
      country: "",
      propertyTax: "",
      city: "",
      state: "",
      trust: "",
      notes: "",
    },
    mortgages: [
      {
        mortgage: "",
        mortgageInstitution: "",
        accountNumber: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "realEstate",
    countryName: "",
  });

  const [errors, setErrors] = useState({
    estPropertyValValidate: true,
    propertyTaxValidate: true,
    estPropertyValZeroValidate: true,
    propertyTaxZeroValidate: true,
    mortgages: [],
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "propertyType") {
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          propertyType: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState(null);

  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          trust: "",
        },
      }));
    } else {
      if (field === "estPropertyVal" || field === "propertyTax") {
        if (field === "estPropertyVal") {
          errorStates.estPropertyValValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.estPropertyValZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
        } else {
          errorStates.propertyTaxValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.propertyTaxZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
        }
      }
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          [field]: newValue,
        },
      }));
    }
    const totalBalance = data.realEstate.estPropertyVal;
    setEstimatedTotalAmount(totalBalance);
    setErrors(errorStates);
  };

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setVisibleColumnIndex(0);

    setData({
      realEstate: {
        owner: "",
        propertyCaption: "",
        propertyType: "",
        otherPropertyType: "",
        aptNumber: "",
        streetAddress: "",
        estPropertyVal: "",
        zipCode: "",
        country: "",
        propertyTax: "",
        city: "",
        state: "",
        trust: "",
        notes: "",
      },
      mortgages: [
        {
          mortgage: "",
          mortgageInstitution: "",
          accountNumber: "",
        },
      ],
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });

    setData1({
      assetCaption: "",
      assetType: "realEstate",
      countryName: "",
    });
    handleReset();
    setOwnerName([]);
    setIsChecked(false);
    setSharedType(null);
    setErrors({
      estPropertyValValidate: true,
      propertyTaxValidate: true,
      estPropertyValZeroValidate: true,
      propertyTaxZeroValidate: true,
      mortgages: [],
    });
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  // Set the form
  const AddForm = (event) => {
    event.preventDefault();

    // Check if there are any errors
    const hasErrors =
      Object.values(errors).includes(false) ||
      errors.mortgages.some((mortgage) => !mortgage.mortgageAmountValidate);

    if (!hasErrors) {
      let token = "Bearer " + getToken();

      //create form data to send a file and remaining class data
      const formData = new FormData();
      if (null != selectedImage) {
        for (let i = 0; i < selectedImage.length; i++) {
          formData.append(`files`, selectedImage[i]);
        }
      }

      formData.append("asset", JSON.stringify(data));
      formData.append("data", JSON.stringify(data1));

      addInternationalAsset(formData, token)
        .then((resp) => {
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          toggle();
        })
        .catch((error) => {
          toast.error(
            "File is too large please choose file size under 400 KB",
            error
          );
        });
    }
  };

  // add multiple mordgages
  const [mordgage, setMordgage] = useState([]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const mordgages = [0, 1, 2, 3, 4];
  const handleAddColumn = () => {
    if (visibleColumnIndex < 4) {
      setMordgage([...mordgage, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveColumn = (indexToRemove) => {
    setMordgage(mordgage.filter((_, index) => index !== indexToRemove));
    setVisibleColumnIndex(visibleColumnIndex - 1);

    setData((prevData) => {
      const updatedMortgages = prevData.mortgages.map((mortgage, index) => {
        if (index === indexToRemove) {
          return { mortgage: "" }; // Reset the mortgage value to an empty string
        }
        return mortgage; // Return the original mortgage object for other indices
      });

      // Filter out mortgages with empty strings
      const filteredMortgages = updatedMortgages.filter(
        (mortgage) => mortgage.mortgage !== ""
      );

      // Return the new state with empty mortgages removed
      return {
        ...prevData,
        mortgages: filteredMortgages,
      };
    });
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };

  // other property
  let [otherPropertyTypes, setOtherPropertyTypes] = useState(false);

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.realEstate.estPropertyVal);
  };

  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const handleReset = () => {
    setbeneVisible(false);
  };

  const handleChanges1 = (e, field, { index }) => {
    const { value } = e.target;
    const errorStates = { ...errors };

    if (!errorStates.mortgages[index]) {
      errorStates.mortgages[index] = {
        mortgageAmountValidate: true,
      };
    }

    setData((prevData) => {
      const updatedMortgages = [...prevData.mortgages];
      if (!updatedMortgages[index]) {
        updatedMortgages[index] = {};
      }
      updatedMortgages[index][field] = value;

      return {
        ...prevData,
        mortgages: updatedMortgages,
      };
    });

    if (field === "mortgage") {
      errorStates.mortgages[index].mortgageAmountValidate =
        value !== "" ? validateEightDigitNumber(value) : true;
    }

    setErrors(errorStates);
  };

  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">
                            Add Real Estate Properties
                          </h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form onSubmit={AddForm} className="addform_body">
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Autocomplete
                                            placeholder="Choose a country"
                                            options={countries}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={
                                              countries.find(
                                                (country) =>
                                                  country.label ===
                                                  data1.countryName
                                              ) || null
                                            }
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>

                                      <Select
                                        placeholder="Select Owner"
                                        value={ownerName}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        slotProps={{
                                          listbox: {
                                            sx: {
                                              width: "100%",
                                            },
                                          },
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter Heading For Property ">
                                    <div className="mt-2">
                                      <FormLabel>Property Heading</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter property heading"
                                        value={data.realEstate.propertyCaption}
                                        onChange={(e) =>
                                          handleChanges(e, "propertyCaption")
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Select The Type Of Property">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Type Of Property
                                      </FormLabel>

                                      <Select
                                        value={data.realEstate.propertyType}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "propertyType"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of Property
                                        </Option>
                                        <Option value="Residential">
                                          Residential
                                        </Option>
                                        <Option value="Commercial">
                                          Commercial
                                        </Option>
                                        <Option value="Industrial">
                                          Industrial
                                        </Option>
                                        <Option value="Land">Land</Option>
                                        <Option
                                          value="Other"
                                          onClick={() => {
                                            setOtherPropertyTypes(
                                              !otherPropertyTypes
                                            );
                                          }}
                                        >
                                          Other
                                        </Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                {otherPropertyTypes && (
                                  <div className="mt-2">
                                    <Tooltip title="Enter Your Other Property Type ">
                                      <div>
                                        <FormLabel>Other Property</FormLabel>
                                        <Input
                                          className="input_mui_joy"
                                          placeholder="Enter Other Property"
                                          id="otherprop"
                                          onChange={(e) =>
                                            handleChanges(
                                              e,
                                              "otherPropertyType"
                                            )
                                          }
                                          value={
                                            data.realEstate.otherPropertyType
                                          }
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter a valid Zip-code ">
                                        <div>
                                          <FormLabel required>
                                            Zip Code
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            type="number"
                                            placeholder="Enter your Zip Code"
                                            id="zipCode"
                                            onChange={(e) =>
                                              handleChanges(e, "zipCode")
                                            }
                                            value={data.realEstate.zipCode}
                                            inputprops={{
                                              minLength: 5,
                                              maxLength: 5,
                                            }}
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Automatically populate based on the zip code">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel required>
                                            City/Town
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="City/Town "
                                            value={data.realEstate.city}
                                            onChange={(e) =>
                                              handleChanges(e, "city")
                                            }
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Automatically populate based on the zip code">
                                        <div>
                                          <FormLabel required>State</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="State"
                                            value={data.realEstate.state}
                                            onChange={(e) =>
                                              handleChanges(e, "state")
                                            }
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Automatically populate based on the zip code">
                                        <div>
                                          <FormLabel required>
                                            Country
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Country"
                                            value={data.realEstate.country}
                                            onChange={(e) =>
                                              handleChanges(e, "country")
                                            }
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Your Street Address ">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel>Street Address</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Street Address"
                                            onChange={(e) =>
                                              handleChanges(e, "streetAddress")
                                            }
                                            value={
                                              data.realEstate.streetAddress
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Your Apartment Number ">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel>Apartment</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Apartment"
                                            onChange={(e) =>
                                              handleChanges(e, "aptNumber")
                                            }
                                            value={data.realEstate.aptNumber}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your estimated Property Value">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel required>
                                            Property Value
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Estimated Property Value"
                                            onChange={(e) =>
                                              handleChanges(e, "estPropertyVal")
                                            }
                                            value={
                                              data.realEstate.estPropertyVal
                                            }
                                            startDecorator={<div>$</div>}
                                            required
                                          />
                                          {(!errors.estPropertyValValidate ||
                                            !errors.estPropertyValZeroValidate) && (
                                            <FormHelperText error>
                                              <Typography
                                                variant="caption"
                                                color="error"
                                              >
                                                {!errors.estPropertyValValidate
                                                  ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                                  : !errors.estPropertyValZeroValidate
                                                  ? "Value can't be zero"
                                                  : ""}
                                              </Typography>
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your Estimated Annual Property Tax">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel>
                                            Annual Property Tax
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Estimated Annual Property Tax"
                                            onChange={(e) =>
                                              handleChanges(e, "propertyTax")
                                            }
                                            value={data.realEstate.propertyTax}
                                            startDecorator={<div>$</div>}
                                          />
                                          {(!errors.propertyTaxValidate ||
                                            !errors.propertyTaxZeroValidate) && (
                                            <FormHelperText error>
                                              <Typography
                                                variant="caption"
                                                color="error"
                                              >
                                                {!errors.propertyTaxValidate
                                                  ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                                  : !errors.propertyTaxZeroValidate
                                                  ? "Value can't be zero"
                                                  : ""}
                                              </Typography>
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="addform_body_right">
                                <div className="mt-2 switch_changes_main">
                                  <div className="switch_changes_base">
                                    <div>Do you have mortgages?</div>
                                    <div>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            color="primary"
                                            size="normal"
                                          />
                                        }
                                        label={isChecked ? "Yes" : "No"}
                                        labelPlacement="end"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {isChecked && (
                                  <div>
                                    {mordgages.map((index) => (
                                      <div
                                        key={index}
                                        style={{
                                          marginBottom: "10px",
                                          display:
                                            index <= visibleColumnIndex
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        <div>
                                          <div>
                                            <Tooltip
                                              title={
                                                index === 0
                                                  ? `Add your primary mortgage`
                                                  : "Add your secondary mortgage"
                                              }
                                            >
                                              <div>
                                                {index === 0 ? (
                                                  <FormLabel required>
                                                    {`Primary Mortgage/Bank`}
                                                  </FormLabel>
                                                ) : (
                                                  <FormLabel required>
                                                    {`Secondary Number/Bank`}
                                                  </FormLabel>
                                                )}

                                                <Input
                                                  className="input_mui_joy"
                                                  placeholder={
                                                    index === 0
                                                      ? `Primary Mortgage`
                                                      : "Secondary Number"
                                                  }
                                                  id={`mortgageInstitution${
                                                    index + 1
                                                  }`}
                                                  required={
                                                    visibleColumnIndex >= index
                                                  }
                                                  onChange={(e) =>
                                                    handleChanges1(
                                                      e,
                                                      "mortgageInstitution",
                                                      {
                                                        index,
                                                      }
                                                    )
                                                  }
                                                  value={
                                                    data.mortgages[index]
                                                      ? data.mortgages[index]
                                                          .mortgageInstitution
                                                      : ""
                                                  }
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </div>
                                        <div className="address_input">
                                          <div className="mt-2 addform_input_half">
                                            <Tooltip
                                              title={`Add your account number ${
                                                index + 1
                                              }`}
                                            >
                                              <div style={{ width: "100%" }}>
                                                <FormLabel required>
                                                  {`Account Number ${
                                                    index + 1
                                                  }`}
                                                </FormLabel>
                                                <Input
                                                  className="input_mui_joy"
                                                  type="number"
                                                  id={`accountNumber${
                                                    index + 1
                                                  }`}
                                                  placeholder={`Account Number ${
                                                    index + 1
                                                  }`}
                                                  onChange={(e) =>
                                                    handleChanges1(
                                                      e,
                                                      "accountNumber",
                                                      {
                                                        index,
                                                      }
                                                    )
                                                  }
                                                  value={
                                                    data.mortgages[index]
                                                      ? data.mortgages[index]
                                                          .accountNumber
                                                      : ""
                                                  }
                                                  required={
                                                    visibleColumnIndex >= index
                                                  }
                                                />
                                              </div>
                                            </Tooltip>
                                          </div>
                                          <div className="mt-2 addform_input_half">
                                            <Tooltip title={`Add your amount`}>
                                              <div>
                                                <FormLabel
                                                  required
                                                >{`Amount`}</FormLabel>
                                                <Input
                                                  className="input_mui_joy"
                                                  type="number"
                                                  placeholder="Amount"
                                                  id={`mortgage${index + 1}`}
                                                  required={
                                                    visibleColumnIndex >= index
                                                  }
                                                  onChange={(e) =>
                                                    handleChanges1(
                                                      e,
                                                      "mortgage",
                                                      {
                                                        index,
                                                      }
                                                    )
                                                  }
                                                  value={
                                                    data.mortgages[index]
                                                      ? data.mortgages[index]
                                                          .mortgage
                                                      : ""
                                                  }
                                                  startDecorator={<div>$</div>}
                                                />
                                                {errors.mortgages.length >
                                                  index &&
                                                  !errors?.mortgages[index]
                                                    ?.mortgageAmountValidate && (
                                                    <FormHelperText error>
                                                      <Typography
                                                        variant="caption"
                                                        color="error"
                                                      >
                                                        Please enter a value up
                                                        to 8 digits (maximum:
                                                        $99,999,999).
                                                      </Typography>
                                                    </FormHelperText>
                                                  )}
                                              </div>
                                            </Tooltip>
                                          </div>
                                        </div>
                                        {index !== 0 && (
                                          <div style={{ marginTop: "4px" }}>
                                            <Button
                                              style={{
                                                height: "30px",
                                                width: "30px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                borderRadius: "50%",
                                                backgroundColor:
                                                  "rgb(255, 74, 74)",
                                                border: "none",
                                              }}
                                              onClick={() =>
                                                handleRemoveColumn(index)
                                              }
                                            >
                                              <FontAwesomeIcon icon={faMinus} />
                                            </Button>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                    {visibleColumnIndex < 4 && (
                                      <div style={{ marginTop: "2px" }}>
                                        <Button
                                          style={{
                                            height: "30px",
                                            width: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                            backgroundColor: "#4aafff",
                                            border: "none",
                                          }}
                                          onClick={handleAddColumn}
                                        >
                                          <FontAwesomeIcon icon={faPlus} />
                                        </Button>
                                      </div>
                                    )}
                                  </div>
                                )}
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div
                                      className="bene-select mt-3"
                                      onClick={handleShowBeneficiary}
                                      style={{ cursor: "pointer" }}
                                    >
                                      Select Beneficiary
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div style={{ width: "100%" }}>
                                      <Tooltip title="Upload your property related file it should be under 400 kb">
                                        <div>
                                          <FormLabel>
                                            Supporting Document
                                          </FormLabel>
                                          <input
                                            style={{
                                              border: "solid 1px lightgray",
                                              borderLeft: "none",
                                              width: "100%",
                                              borderRadius: "2px",
                                            }}
                                            type="file"
                                            name="myfile"
                                            id="exampleFile"
                                            onChange={handleImageChange}
                                            accept=".pdf"
                                            multiple
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="mt-2 form1-double"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    justifyContent: "space-between",
                                    marginTop: "-12px",
                                  }}
                                ></div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel>Notes</FormLabel>
                                      <Textarea
                                        sx={{
                                          height: "182px",
                                          minHeight: "52px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChanges(e, "notes")
                                        }
                                        value={data.realEstate.notes}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                onClick={resetForm}
                                type="reset"
                                outline
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                type=""
                                className="my-estate-add-btn"
                              >
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        <SharedBeneficiary
          handleTrustSave={handleTrustSave}
          assetData={data?.realEstate}
          trusts={trusts}
          handleChanges={handleChanges}
          sharedType={sharedType}
          handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
          estimatedTotalAmount={estimatedTotalAmount}
          typeBeneficiary={"normal"}
          sharedDetails={data?.sharedDetails}
        />
      )}
    </>
  );
}

export function InternationalAssetBank() {
  // const [bankName, setBankName] = React.useState("");
  const [selectedImage, setSelectedImage] = useState([]);

  const [category, setCategory] = useState([]);

  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [beneficiary, setBenificiary] = useState([]);
  const [bankFieldClicked, setBankFieldClicked] = useState(false);
  const [account, setAccount] = useState([]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);

  let [otherPropertyTypes, setOtherPropertyTypes] = useState(false);

  const navigate = useNavigate();

  let [form1, setForm1] = useState(true);
  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    bank: {
      owner: "",
      bankName: "",
      safetyBox: "",
      safetyBoxNumber: "",
      notes: "",
      bankType: "",
      totalAmount: "",
      trust: "",
    },
    accounts: [
      {
        accountType: "",
        accountNumber: "",
        balance: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "bank",
    countryName: "",
  });

  const [errors, setErrors] = useState({
    banks: [],
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  const resetForm = () => {
    getBankName("");
    setBankFieldClicked(false);
    setVisibleColumnIndex(0);
    setSelectedBankTypes("");
    setData({
      bank: {
        owner: "",
        bankName: "",
        safetyBox: "",
        safetyBoxNumber: "",
        notes: "",
        bankType: "",
        totalAmount: "",
        trust: "",
      },
      accounts: [
        {
          accountType: "",
          accountNumber: "",
          balance: "",
        },
      ],
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });

    setData1({
      assetCaption: "",
      assetType: "bank",
      countryName: "",
    });
    setOwnerName([]);
    handleReset();
    setIsChecked(false);
    setErrors({
      banks: [],
    });
  };

  const handleImageChange = (event, maxFilesAllowed) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      if (selectedFilesArray.length > maxFilesAllowed) {
        // Notify the user if they exceed the file limit
        toast.error(`You can only select up to ${maxFilesAllowed} files.`, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        event.target.value = ""; // Clear the file input
      } else {
        const invalidFiles = selectedFilesArray.filter((file) => {
          const fileNameParts = file.name.split(".");
          const fileExtension =
            fileNameParts[fileNameParts.length - 1].toLowerCase();
          return !allowedExtensions.includes(fileExtension);
        });

        if (invalidFiles.length === 0) {
          const formData = new FormData();
          selectedFilesArray.forEach((file, index) => {
            formData.append(`file${index + 1}`, file);
          });

          // Update your state or perform actions with the selected files
          setSelectedImage(selectedFilesArray);
        } else {
          const invalidExtensions = invalidFiles
            .map((file) => file.name.split(".").pop())
            .join(", ");
          // Notify the user about invalid file extensions
          toast.error(
            `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
            {
              position: toast.POSITION.BOTTOM_CENTER,
            }
          );

          // Clear the file input field
          event.target.value = "";
        }
      }
    }
  };
  const bankHandleChange = (event, newValue) => {
    const selectedBank = newValue;
    if (selectedBank === "other") {
      setOtherPropertyTypes(true);
      setData({
        ...data,
        bank: {
          ...data.bank,
          bankType: "other",
          bankName: "",
        },
      });
    } else {
      setOtherPropertyTypes(false);
      setData({
        ...data,
        bank: {
          ...data.bank,
          bankType: "",
          bankName: selectedBank,
        },
      });
    }
  };

  const BankForm = (event) => {
    event.preventDefault();

    const hasErrors = errors.banks.some(
      (bank) => !bank.bankAmountValidate || !bank.bankAmountZeroValidate
    );

    if (!hasErrors) {
      let token = "Bearer " + getToken();

      const formData = new FormData();
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
      formData.append("asset", JSON.stringify(data));
      formData.append("data", JSON.stringify(data1));

      addInternationalAsset(formData, token)
        .then(() => {
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          toggle();
        })
        .catch(() => {});
    }
  };

  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch(() => {});
  };
  const getBankName = (bankName) => {
    return category.some((item) => item.bank.bankName === bankName);
  };

  const getData = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken();
    getInternationalBank(token, userId)
      .then((res) => {
        setCategory(res);
      })
      .catch((error) => {});
  };

  const bankLogo = [
    { "JPMorgan Chase & Co": "/logos/J.P._Morgan_Chase_logo_PNG3.png" },
    { "Bank of America": "/logos/Bank_of_America_logo_PNG4.png" },
    { "Wells Fargo & Co": "/logos/Wells_fargo_logo_PNG2.png" },
    { "Citigroup Inc": "/logos/Citigroup_logo_PNG1.png" },
    { "U.S. Bancorp": "/logos/US-Bank-Logo-PNG3.png" },
    { "PNC bank": "/logos/PNC_Bank_logo_PNG1.png" },
    { "TD Bank": "/logos/TD_Bank_logo_PNG1.png" },
    { "Capital One": "/logos/Capital_One_logo_PNG1.png" },
    { "Fifth Third Bank": "/logos/Harris-Teeter-Logo-PNG_003-1.png" },
    { "Ally Financial Inc": "/logos/Ally_Financial_logo_PNG4.png" },
    { "Huntington Bancshares Incorporated": "/logos/huntington.webp" },
    { KeyCorp: "/logos/KeyBank_logo_PNG7.png" },
  ];

  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
    data.bank.safetyBox = !isChecked;
    if (isChecked) {
      data.bank.safetyBoxNumber = null;
    }
  };

  const [sharedType, setSharedType] = useState(null);
  const handleChangesBank = (e, field) => {
    const newValue = e.target.value;

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          trust: "",
        },
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        bank: {
          ...prevData.bank,
          [field]: newValue,
        },
      }));
    }
  };

  const handleChanges1 = (e, newValue, field, { index }) => {
    const errorStates = { ...errors };

    if (!errorStates.banks[index]) {
      errorStates.banks[index] = {
        bankAmountValidate: true,
        bankAmountZeroValidate: true,
      };
    }

    if (field === "accountType") {
      if (newValue != null) {
        // Update selected bank types based on the index
        setSelectedBankTypes((prevSelectedBankTypes) => {
          const newSelectedBankTypes = [...prevSelectedBankTypes];
          newSelectedBankTypes[index] = newValue;
          return newSelectedBankTypes;
        });

        // Update the account type in the data state
        setData((prevData) => {
          const updatedAccounts = [...prevData.accounts];
          updatedAccounts[index] = {
            ...updatedAccounts[index],
            accountType: newValue,
          };

          return {
            ...prevData,
            accounts: updatedAccounts,
          };
        });
      }
    } else {
      // Handle changes for other fields
      const { value } = e.target;
      setData((prevData) => {
        const updatedAccounts = [...prevData.accounts];
        if (!updatedAccounts[index]) {
          updatedAccounts[index] = {};
        }
        updatedAccounts[index][field] = value;

        // Calculate total balance and update data.bank.totalAmount
        const balances = updatedAccounts.map((account) =>
          account.balance ? parseFloat(account.balance) : 0
        );
        const newTotalBalance = balances.reduce(
          (acc, curr) => acc + parseFloat(curr),
          0
        );

        return {
          ...prevData,
          accounts: updatedAccounts,
          bank: {
            ...prevData.bank,
            totalAmount: newTotalBalance,
          },
        };
      });

      if (field === "balance") {
        errorStates.banks[index].bankAmountValidate =
          value !== "" ? validateEightDigitNumber(value) : true;
        errorStates.banks[index].bankAmountZeroValidate =
          value !== "" ? Number(value) > 0 : true;
      }
    }

    setErrors(errorStates);
  };

  // multiple account
  const multipleAccount = [0, 1, 2, 3];
  const handleAddBank = () => {
    if (visibleColumnIndex < 3) {
      setAccount([...account, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveBank = (indexToRemove) => {
    if (visibleColumnIndex > 0) {
      // Filter out the account with the index to remove
      const updatedAccounts = account.filter(
        (_, index) => index !== indexToRemove - 1
      );
      setAccount(updatedAccounts);

      // Adjust visibleColumnIndex if the removed index is within the current visible range
      if (indexToRemove - 1 < visibleColumnIndex) {
        setVisibleColumnIndex(visibleColumnIndex - 1);
      }
    }

    setData((prevData) => {
      const updatedAccounts = prevData.accounts.map((account, index) => {
        if (index === indexToRemove) {
          // Create a new object with empty values for the removed index
          return {
            accountType: "",
            accountNumber: "",
            balance: "",
          };
        }
        return account; // Return the original bank object for other indices
      });

      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const filteredAccounts = updatedAccounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the new array of bank accounts
      const newData = {
        ...prevData,
        accounts: filteredAccounts,
      };

      // Perform further operations directly after updating the state
      // Filter out accounts with empty strings for accountType, accountNumber, and balance
      const furtherFilteredAccounts = newData.accounts.filter(
        (account) =>
          account.accountType !== "" ||
          account.accountNumber !== "" ||
          account.balance !== ""
      );

      // Update the state with the further filtered accounts
      setData({
        ...newData,
        accounts: furtherFilteredAccounts,
      });

      return newData;
    });

    setSelectedBankTypes((prevSelectedBankTypes) => {
      const newSelectedBankTypes = [...prevSelectedBankTypes];
      newSelectedBankTypes.splice(indexToRemove, 1);
      return newSelectedBankTypes;
    });
  };

  const [selectedBankTypes, setSelectedBankTypes] = useState(
    Array(multipleAccount.length).fill("")
  );

  const handleReset = () => {
    setbeneVisible(false);
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.bank.totalAmount);
  };
  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  useEffect(() => {
    getBenificiarydata();
    getData();
    getTrustsData();
  }, []);

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main addform_main_bank">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">
                            Add Bank Properties
                          </h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form onSubmit={BankForm} className="addform_body">
                            <div className="addform_select_body">
                              <div className="addform_body_left addform_body_left_bank">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Autocomplete
                                            placeholder="Choose a country"
                                            options={countries}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={
                                              countries.find(
                                                (country) =>
                                                  country.label ===
                                                  data1.countryName
                                              ) || null
                                            }
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div required>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>
                                      <Select
                                        value={ownerName}
                                        multiple
                                        placeholder="Select Name"
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div className="mt-2">
                                  <Tooltip title="Enter name of the bank">
                                    <div>
                                      <FormLabel required>Bank Name</FormLabel>
                                      <Select
                                        id="banks"
                                        placeholder="Select Bank Name"
                                        // onChange={bankHandleChange}
                                        onChange={(event, newValue) =>
                                          bankHandleChange(event, newValue)
                                        }
                                        required
                                        // value={
                                        //   data.bank.bankType === "other"
                                        //     ? data.bank.bankType
                                        //     : data.bank.bankName
                                        // }
                                      >
                                        <Option
                                          value={"JPMorgan Chase & Co"}
                                          disabled={getBankName(
                                            "JPMorgan Chase & Co"
                                          )}
                                        >
                                          <img
                                            src="/logos/J.P._Morgan_Chase_logo_PNG3.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;JPMorgan Chase & Co
                                        </Option>
                                        <Option
                                          value={"Bank of America"}
                                          disabled={getBankName(
                                            "Bank of America"
                                          )}
                                        >
                                          <img
                                            src="/logos/Bank_of_America_logo_PNG4.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;Bank of America
                                        </Option>
                                        <Option
                                          value={"Wells Fargo & Co"}
                                          disabled={getBankName(
                                            "Wells Fargo & Co"
                                          )}
                                        >
                                          <img
                                            src="/logos/Wells_fargo_logo_PNG2.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;Wells Fargo & Co
                                        </Option>
                                        <Option
                                          value={"Citigroup Inc"}
                                          disabled={getBankName(
                                            "Citigroup Inc"
                                          )}
                                        >
                                          <img
                                            src="/logos/Citigroup_logo_PNG1.png"
                                            alt="logo"
                                            style={{
                                              width: "40px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;Citigroup Inc
                                        </Option>
                                        <Option
                                          value={"U.S. Bancorp"}
                                          disabled={getBankName("U.S. Bancorp")}
                                        >
                                          <img
                                            src="/logos/US-Bank-Logo-PNG3.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;U.S. Bancorp
                                        </Option>
                                        <Option
                                          value={"PNC bank"}
                                          disabled={getBankName("PNC bank")}
                                        >
                                          <img
                                            src="/logos/PNC_Bank_logo_PNG1.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;PNC bank
                                        </Option>
                                        <Option
                                          value={"TD Bank"}
                                          disabled={getBankName("TD Bank")}
                                        >
                                          <img
                                            src="/logos/TD_Bank_logo_PNG1.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;TD Bank
                                        </Option>
                                        <Option
                                          value={"Capital One"}
                                          disabled={getBankName("Capital One")}
                                        >
                                          <img
                                            src="/logos/Capital_One_logo_PNG1.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;Capital One
                                        </Option>
                                        <Option
                                          value={"Fifth Third Bank"}
                                          disabled={getBankName(
                                            "Fifth Third Bank"
                                          )}
                                        >
                                          <img
                                            src="/logos/Harris-Teeter-Logo-PNG_003-1.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;Fifth Third Bank
                                        </Option>
                                        <Option
                                          value={"Ally Financial Inc"}
                                          disabled={getBankName(
                                            "Ally Financial Inc"
                                          )}
                                        >
                                          <img
                                            src="/logos/Ally_Financial_logo_PNG4.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;Ally Financial Inc
                                        </Option>
                                        <Option
                                          value={
                                            "Huntington Bancshares Incorporated"
                                          }
                                          disabled={getBankName(
                                            "Huntington Bancshares Incorporated"
                                          )}
                                        >
                                          <img
                                            src="/logos/huntington.webp"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;Huntington Bancshares
                                          Incorporated
                                        </Option>
                                        <Option
                                          value={"KeyCorp"}
                                          disabled={getBankName("KeyCorp")}
                                        >
                                          <img
                                            src="/logos/KeyBank_logo_PNG7.png"
                                            alt="logo"
                                            style={{
                                              width: "45px",
                                              aspectRatio: "16/9",
                                              objectFit: "contain",
                                            }}
                                          />
                                          &nbsp;&nbsp;KeyCorp
                                        </Option>
                                        <Option value={"other"}>Other</Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>
                                {otherPropertyTypes && (
                                  <div className="mt-2">
                                    <Tooltip title="Enter Your Other Bank Name ">
                                      <div>
                                        <FormLabel>Other Bank</FormLabel>
                                        <Input
                                          className="input_mui_joy"
                                          placeholder="Enter Other Bank"
                                          id="otherprop"
                                          onChange={(e) =>
                                            handleChangesBank(e, "bankName")
                                          }
                                          value={data.bank.bankName}
                                          aria-readonly
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}
                                <div
                                  className="mt-3 form1-double"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    gap: "5px",
                                  }}
                                >
                                  <div
                                    style={{
                                      width: "49.5%",
                                      display: "flex",
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <div
                                      className="txt"
                                      style={{
                                        alignSelf: "center",
                                        marginRight: "10px",
                                        flex: "1 1 100%",
                                      }}
                                    >
                                      Safety Box
                                    </div>
                                    <div
                                      className="switch"
                                      style={{
                                        flex: "1 1 100%",
                                      }}
                                    >
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            color="primary"
                                            size="normal"
                                          />
                                        }
                                        label={isChecked ? "Yes" : "No"}
                                        labelPlacement="end"
                                      />
                                    </div>
                                  </div>

                                  {isChecked && (
                                    <div className="addform_input_half">
                                      <Tooltip title="Enter Your SafetyBox ID">
                                        <div>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Safety Box ID"
                                            id="safetyBoxNumber"
                                            onChange={(e) =>
                                              handleChangesBank(
                                                e,
                                                "safetyBoxNumber"
                                              )
                                            }
                                            value={data.bank.safetyBoxNumber}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  )}
                                </div>
                                <div>
                                  {multipleAccount.map((index) => (
                                    <div
                                      className="mt-2"
                                      key={index}
                                      style={{
                                        flexDirection: "column",
                                        display:
                                          index <= visibleColumnIndex
                                            ? "flex"
                                            : "none",
                                      }}
                                    >
                                      <div>
                                        <Tooltip
                                          title={`Add your bank details for bank ${
                                            index + 1
                                          }`}
                                        >
                                          <div>
                                            <FormLabel
                                              id={`accountType${index + 1}`}
                                            >
                                              Bank Type{" "}
                                              {index >= 0 && <span>*</span>}
                                            </FormLabel>
                                            <Select
                                              id={`accountType${index + 1}`}
                                              placeholder="Select Your Account Type"
                                              // value={
                                              //   data.accounts[index]?.accountType || ""
                                              // }
                                              onChange={(e, newValue) =>
                                                handleChanges1(
                                                  e,
                                                  newValue,
                                                  "accountType",
                                                  {
                                                    index,
                                                  }
                                                )
                                              }
                                              onClick={() =>
                                                setBankFieldClicked(true)
                                              }
                                              required={
                                                visibleColumnIndex >= index
                                              }
                                            >
                                              <Option
                                                value="Checking Account"
                                                disabled={selectedBankTypes.includes(
                                                  "Checking Account"
                                                )}
                                              >
                                                Checking Account
                                              </Option>
                                              <Option
                                                value="Savings Account"
                                                disabled={selectedBankTypes.includes(
                                                  "Savings Account"
                                                )}
                                              >
                                                Savings Account
                                              </Option>
                                              <Option
                                                value="Investment Account"
                                                disabled={selectedBankTypes.includes(
                                                  "Investment Account"
                                                )}
                                              >
                                                Investment Account
                                              </Option>
                                              <Option
                                                value="C.D Account"
                                                disabled={selectedBankTypes.includes(
                                                  "C.D Account"
                                                )}
                                              >
                                                C.D Account
                                              </Option>
                                            </Select>
                                          </div>
                                        </Tooltip>
                                      </div>

                                      <div className="address_input">
                                        <div className="mt-2 addform_input_half">
                                          <Tooltip
                                            title={`Add your bank details for bank ${
                                              index + 1
                                            }`}
                                          >
                                            <div>
                                              <FormLabel
                                                required
                                              >{`Bank Account ${
                                                index + 1
                                              }`}</FormLabel>
                                              <Input
                                                type="number"
                                                placeholder={`Bank Account ${
                                                  index + 1
                                                }`}
                                                id={`BankAccount${index + 1}`}
                                                required={
                                                  visibleColumnIndex >= index
                                                }
                                                onChange={(e) =>
                                                  handleChanges1(
                                                    e,
                                                    null,
                                                    "accountNumber",
                                                    {
                                                      index,
                                                    }
                                                  )
                                                }
                                                value={
                                                  data.accounts[index]
                                                    ?.accountNumber || ""
                                                }
                                              />
                                            </div>
                                          </Tooltip>
                                        </div>
                                        <div className="mt-2 addform_input_half">
                                          <Tooltip
                                            title={`Add your bank details for bank ${
                                              index + 1
                                            }`}
                                          >
                                            <div>
                                              <FormLabel
                                                required
                                              >{`Account Balance ${
                                                index + 1
                                              }`}</FormLabel>
                                              <Input
                                                type="number"
                                                placeholder={`Account Balance ${
                                                  index + 1
                                                }`}
                                                id={`Account Balance ${
                                                  index + 1
                                                }`}
                                                required={
                                                  visibleColumnIndex >= index
                                                }
                                                onChange={(e) =>
                                                  handleChanges1(
                                                    e,
                                                    null,
                                                    "balance",
                                                    {
                                                      index,
                                                    }
                                                  )
                                                }
                                                value={
                                                  data.accounts[index]
                                                    ?.balance || ""
                                                }
                                                onClick={() =>
                                                  setBankFieldClicked(true)
                                                }
                                                startDecorator={
                                                  bankFieldClicked ? (
                                                    <div>$</div>
                                                  ) : null
                                                }
                                              />
                                              {errors.banks.length > index &&
                                                (!errors?.banks[index]
                                                  ?.bankAmountValidate ||
                                                  !errors?.banks[index]
                                                    ?.bankAmountZeroValidate) && (
                                                  <FormHelperText error>
                                                    <Typography
                                                      variant="caption"
                                                      color="error"
                                                    >
                                                      {!errors.banks[index]
                                                        .bankAmountValidate
                                                        ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                                        : !errors.banks[index]
                                                            .bankAmountZeroValidate
                                                        ? "Value can't be zero"
                                                        : ""}
                                                    </Typography>
                                                  </FormHelperText>
                                                )}
                                            </div>
                                          </Tooltip>
                                        </div>
                                      </div>
                                      {index !== 0 && (
                                        <Button
                                          style={{
                                            marginTop: "7px",
                                            height: "30px",
                                            width: "30px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            borderRadius: "50%",
                                            backgroundColor: "#ff4a4a",
                                            border: "none",
                                          }}
                                          onClick={() =>
                                            handleRemoveBank(index)
                                          }
                                        >
                                          <FontAwesomeIcon icon={faMinus} />
                                        </Button>
                                      )}
                                    </div>
                                  ))}
                                  {visibleColumnIndex < 3 && (
                                    <div
                                      style={{
                                        marginTop: "7px",
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Button
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: "50%",
                                          backgroundColor: "#4aafff",
                                          border: "none",
                                        }}
                                        onClick={handleAddBank}
                                      >
                                        <FontAwesomeIcon icon={faPlus} />
                                      </Button>
                                    </div>
                                  )}
                                </div>
                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-3">
                                      <FormLabel>Supporting Document</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="myfile"
                                        id="exampleFile"
                                        onChange={(e) =>
                                          handleImageChange(e, 5)
                                        }
                                        accept=".pdf"
                                        multiple
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div
                                  className="bene-select mt-3"
                                  onClick={handleShowBeneficiary}
                                  style={{ cursor: "pointer" }}
                                >
                                  Select Your Beneficiary
                                </div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel>Notes</FormLabel>
                                      <Textarea
                                        sx={{
                                          height: "182px",
                                          minHeight: "52px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChangesBank(e, "notes")
                                        }
                                        value={data.bank.notes}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                type="reset"
                                onClick={resetForm}
                                outline
                              >
                                Clear
                              </Button>
                              <Button outline className="my-estate-add-btn">
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        <SharedBeneficiary
          handleTrustSave={handleTrustSave}
          assetData={data?.bank}
          trusts={trusts}
          handleChanges={handleChangesBank}
          sharedType={sharedType}
          handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
          estimatedTotalAmount={estimatedTotalAmount}
          typeBeneficiary={"normal"}
          sharedDetails={data?.sharedDetails}
        />
      )}
    </>
  );
}

export function InternationalAssetInvestment() {
  const navigate = useNavigate();
  let userId = getUser().id;
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    investment: {
      owner: "",
      investment: "",
      nameOfTheInvestment: "",
      investmentCaption: "",
      notes: "",
      totalAmount: "",
      trust: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "investment",
    countryName: "",
  });

  const [errors, setErrors] = useState({
    totalAmountValidate: true,
    totalAmountZeroValidate: true,
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "investment") {
      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          investment: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          trust: "",
        },
      }));
    } else {
      if (field === "totalAmount") {
        errorStates.totalAmountValidate =
          newValue !== "" ? validateEightDigitNumber(newValue) : true;
        errorStates.totalAmountZeroValidate =
          newValue !== "" ? Number(newValue) > 0 : true;
      }
      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          [field]: newValue,
        },
      }));
    }
    setEstimatedTotalAmount(data.investment.totalAmount);
    setErrors(errorStates);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };
  // auto matic clear the data
  const resetForm = () => {
    setData({
      investment: {
        investment: "",
        nameOfTheInvestment: "",
        investmentCaption: "",
        notes: "",
        totalAmount: "",
        trust: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });

    setData1({
      assetCaption: "",
      assetType: "investment",
      countryName: "",
    });

    setOwnerName([]);
    handleReset();
    setErrors({
      totalAmountValidate: true,
      totalAmountZeroValidate: true,
    });
  };

  // Set the form
  const investmentForm = (event) => {
    event.preventDefault();
    const hasErrors = Object.values(errors).includes(false);

    if (!hasErrors) {
      let token = "Bearer " + getToken();

      //create form data to send a file and remaining class data
      const formData = new FormData();

      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
      formData.append("asset", JSON.stringify(data));
      formData.append("data", JSON.stringify(data1));

      addInternationalAsset(formData, token)
        .then((resp) => {
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          toggle();
        })
        .catch((error) => {});
    }
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.investment.totalAmount);
  };

  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const handleReset = () => {
    setbeneVisible(false);
  };

  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">Add Investment</h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form
                            onSubmit={investmentForm}
                            className="addform_body"
                          >
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Autocomplete
                                            placeholder="Choose a country"
                                            options={countries}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={
                                              countries.find(
                                                (country) =>
                                                  country.label ===
                                                  data1.countryName
                                              ) || null
                                            }
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>

                                      <Select
                                        placeholder="Select Owner"
                                        value={ownerName}
                                        multiple
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        sx={{
                                          minWidth: "13rem",
                                        }}
                                        slotProps={{
                                          listbox: {
                                            sx: {
                                              width: "100%",
                                            },
                                          },
                                        }}
                                        required
                                      >
                                        {ownerNames.map((name) => (
                                          <Option key={name} value={name}>
                                            {name}
                                          </Option>
                                        ))}
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter Heading for Investment">
                                    <div className="mt-2">
                                      <FormLabel>Investment Heading</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter investment heading"
                                        value={
                                          data.investment.investmentCaption
                                        }
                                        onChange={(e) =>
                                          handleChanges(e, "investmentCaption")
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Select Investment Type">
                                    <div className="mt-2">
                                      <FormLabel required>Investment</FormLabel>
                                      <Select
                                        value={data.investment.investment}
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "investment"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select Your Type Of investment
                                        </Option>
                                        <Option value="Stocks">Stocks</Option>
                                        <Option value="Mutual Funds">
                                          Mutual Funds
                                        </Option>
                                        <Option value="Bonds">Bonds</Option>
                                        <Option value="401K">401K</Option>
                                        <Option value="Exchange-Traded Funds (ETFs)">
                                          Exchange-Traded Funds (ETFs)
                                        </Option>
                                        <Option value="Certificates of Deposit (CDs)">
                                          Certificates of Deposit (CDs)
                                        </Option>
                                        <Option value="Money Market Funds">
                                          Money Market Funds
                                        </Option>
                                        <Option value="Alternative Investments">
                                          Alternative Investments
                                        </Option>
                                      </Select>
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter the name of Exchange">
                                    <div className="mt-2">
                                      <FormLabel>Name Of Exchange</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter name of Exchange"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "nameOfTheInvestment"
                                          )
                                        }
                                        value={
                                          data.investment.nameOfTheInvestment
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter Your Estimated Amount ">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Estimated Amount
                                      </FormLabel>
                                      <Input
                                        placeholder="Estimated Investment Amount"
                                        required
                                        type="number"
                                        onChange={(e) => {
                                          handleChanges(e, "totalAmount");
                                        }}
                                        value={data.investment.totalAmount}
                                        onClick={() => {
                                          setIsTextFieldClicked(true);
                                        }}
                                        startDecorator={<div>$</div>}
                                      />
                                      {(!errors.totalAmountValidate ||
                                        !errors.totalAmountZeroValidate) && (
                                        <FormHelperText error>
                                          <Typography
                                            variant="caption"
                                            color="error"
                                          >
                                            {!errors.totalAmountValidate
                                              ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                              : !errors.totalAmountZeroValidate
                                              ? "Value can't be zero"
                                              : ""}
                                          </Typography>
                                        </FormHelperText>
                                      )}
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="addform_body_right_investment">
                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-2">
                                      <FormLabel>Supporting Document</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="myfile"
                                        id="exampleFile"
                                        onChange={(e) =>
                                          handleImageChange(e, 5)
                                        }
                                        accept=".pdf"
                                        multiple
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className="bene-select mt-3"
                                  onClick={handleShowBeneficiary}
                                  style={{ cursor: "pointer" }}
                                >
                                  Select Your Beneficiary
                                </div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel>Notes</FormLabel>
                                      <Textarea
                                        sx={{
                                          height: "182px",
                                          minHeight: "52px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChanges(e, "notes")
                                        }
                                        value={data.investment.notes}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                onClick={resetForm}
                                type="reset"
                                outline
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                type=""
                                className="my-estate-add-btn"
                              >
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        <SharedBeneficiary
          handleTrustSave={handleTrustSave}
          assetData={data?.investment}
          trusts={trusts}
          handleChanges={handleChanges}
          sharedType={sharedType}
          handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
          estimatedTotalAmount={estimatedTotalAmount}
          typeBeneficiary={"normal"}
          sharedDetails={data?.sharedDetails}
        />
      )}
    </>
  );
}

export function InternationalAssetCrypto() {
  const navigate = useNavigate();
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    cryptoAssest: {
      cryptoListName: "",
      owner: "",
      heading: "",
      coin: "Bitcoin",
      storageType: "",
      exchangeOrWallet: "",
      otherExchangeOrWallet: "",
      quantity: "",
      notes: "",
      estValue: "",
      trust: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "crypto",
    countryName: "",
  });

  const [errors, setErrors] = useState({
    quantityZeroValidate: true,
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "coin") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          coin: newValue,
        },
      }));
    }

    if (stringValue === "storageType") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          storageType: newValue,
          exchangeOrWallet: "",
        },
      }));
    }

    if (stringValue === "exchangeOrWallet") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          exchangeOrWallet: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          trust: "",
        },
      }));
    } else {
      if (field === "quantity") {
        errorStates.quantityZeroValidate =
          newValue !== "" ? Number(newValue) > 0 : true;
        setData((prevData) => ({
          ...prevData,
          cryptoAssest: {
            ...prevData.cryptoAssest,
            [field]: newValue,
            estValue: newValue * bitcoinPrice,
          },
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          cryptoAssest: {
            ...prevData.cryptoAssest,
            [field]: newValue,
          },
        }));
      }
    }
    setEstimatedTotalAmount(data.cryptoAssest.estValue);
    setErrors(errorStates);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setData({
      cryptoAssest: {
        heading: "",
        coin: "Bitcoin",
        storageType: "",
        exchangeOrWallet: "",
        otherExchangeOrWallet: "",
        quantity: "",
        notes: "",
        estValue: "",
        trust: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
    setData1({ assetCaption: "", assetType: "crypto", countryName: "" });
    setOwnerName([]);
    handleReset();
    setSharedType(null);
    setErrors({
      quantityZeroValidate: true,
    });
  };

  // Set the form
  const cryptoForm = (event) => {
    event.preventDefault();

    const hasErrors = Object.values(errors).includes(false);

    if (!hasErrors) {
      let token = "Bearer " + getToken();

      //create form data to send a file and remaining class data
      const formData = new FormData();

      const { estimatedValue, ...formDataValues } = data;

      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
      formData.append("asset", JSON.stringify(data));
      formData.append("data", JSON.stringify(data1));

      addInternationalAsset(formData, token)
        .then((resp) => {
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          toggle();
        })
        .catch((error) => {});
    }
  };

  const [bitcoinPrice, setBitcoinPrice] = useState(null);

  useEffect(() => {
    const getBitcoinPrice = () => {
      axios
        .get("https://api.coindesk.com/v1/bpi/currentprice/BTC.json")
        .then((res) => {
          const price = res.data.bpi.USD.rate_float;
          setBitcoinPrice(price);
        })
        .catch((error) => {
          console.error("Error fetching Bitcoin price:", error);
        });
    };
    getBitcoinPrice();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.cryptoAssest.estValue);
  };
  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };
  const handleReset = () => {
    setbeneVisible(false);
  };

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5"></div>
              <SideBar>
                <div
                  className="overlay1"
                  style={{ transition: "500ms", height: "" }}
                >
                  <div>
                    <Form onSubmit={cryptoForm} className="addform_body">
                      <container>
                        <div className="homepage jewelry_container">
                          <Card>
                            <CardHeader>
                              <div
                                style={{ width: "100%" }}
                                className="addform_heading"
                              >
                                <h3 className="addform_heading_h1">
                                  {"Add Bitcoin BTC"}
                                </h3>
                                <div className="addform_close" onClick={toggle}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div>
                              </div>
                            </CardHeader>
                            <CardBody className="jewelry_card_body">
                              <div>
                                <p className="crypto_note">
                                  NOTE: DO NOT STORE YOUR SEED PHRASES ON ANY
                                  ELECTRONIC PLATFORM, INCLUDING I
                                  <span style={{ color: "green" }}>-</span>
                                  CHEST.COM, GOOGLE DOC ETC.
                                </p>
                                <table className="jewelry_table">
                                  <thead>
                                    <tr>
                                      <th className="crypto_100px">Caption</th>
                                      <th className="crypto_115px">Country*</th>
                                      <th className="crypto_115px">
                                        Select Owner*
                                      </th>
                                      <th className="crypto_100px">Heading</th>
                                      <th className="crypto_100px">
                                        Select Coin*
                                      </th>
                                      <th className="crypto_110px">
                                        Select Exchange/Wallet*
                                      </th>
                                      <th className="crypto_100px">
                                        Quantity*
                                      </th>
                                      <th className="crypto_100px">Value*</th>

                                      <th className="crypto_100px">
                                        Documents
                                      </th>
                                      <th>Beneficiary*</th>
                                      <th>Notes</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <Input
                                          className="crypto_select crypto_100px"
                                          placeholder="Enter caption"
                                          onChange={(e) =>
                                            handleChangesData(e, "assetCaption")
                                          }
                                          value={data1.assetCaption}
                                        />
                                      </td>
                                      <td>
                                        <Autocomplete
                                          className="crypto_select crypto_115px"
                                          placeholder="Country"
                                          options={countries}
                                          autoHighlight
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          onChange={(e, newValue) =>
                                            handleChangesCountry(e, newValue)
                                          }
                                          value={
                                            countries.find(
                                              (country) =>
                                                country.label ===
                                                data1.countryName
                                            ) || null
                                          }
                                          required
                                        />
                                      </td>
                                      <td>
                                        <Select
                                          className="crypto_select crypto_115px"
                                          placeholder="Owner"
                                          value={ownerName}
                                          multiple
                                          onChange={(event, newValue) =>
                                            handleChange(
                                              event,
                                              newValue,
                                              "owner"
                                            )
                                          }
                                          required
                                        >
                                          {ownerNames.map((name) => (
                                            <Option key={name} value={name}>
                                              {name}
                                            </Option>
                                          ))}
                                        </Select>
                                      </td>

                                      <td>
                                        <Input
                                          className="crypto_input crypto_100px"
                                          placeholder="Heading"
                                          value={data.cryptoAssest.heading}
                                          onChange={(e) =>
                                            handleChanges(e, "heading")
                                          }
                                        />
                                      </td>

                                      <td>
                                        <Select
                                          className="crypto_select crypto_100px"
                                          value={data.cryptoAssest.coin}
                                          onChange={(event, newValue) =>
                                            handleChange(
                                              event,
                                              newValue,
                                              "coin"
                                            )
                                          }
                                          required
                                        >
                                          <Option value="">Select coin</Option>
                                          <Option value="Bitcoin">
                                            Bitcoin
                                          </Option>
                                        </Select>
                                      </td>

                                      <td>
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            gap: "2px",
                                          }}
                                        >
                                          <Select
                                            className="crypto_select crypto_100px"
                                            value={
                                              data.cryptoAssest.storageType
                                            }
                                            onChange={(event, newValue) =>
                                              handleChange(
                                                event,
                                                newValue,
                                                "storageType"
                                              )
                                            }
                                            required
                                          >
                                            <Option value="">Select one</Option>
                                            <Option value="Exchange">
                                              Exchange
                                            </Option>
                                            <Option value="Wallet">
                                              Wallet
                                            </Option>
                                          </Select>

                                          {data.cryptoAssest.storageType ===
                                            "Exchange" ||
                                          !data.cryptoAssest.storageType !=
                                            "" ? (
                                            <>
                                              <Select
                                                className="crypto_select crypto_100px"
                                                value={
                                                  data.cryptoAssest
                                                    .exchangeOrWallet
                                                }
                                                onChange={(event, newValue) =>
                                                  handleChange(
                                                    event,
                                                    newValue,
                                                    "exchangeOrWallet"
                                                  )
                                                }
                                                required
                                              >
                                                <Option value="">
                                                  Exchange
                                                </Option>
                                                <Option value="Coinbase">
                                                  Coinbase
                                                </Option>
                                                <Option value="Binance.US">
                                                  Binance.US
                                                </Option>
                                                <Option value="Kraken">
                                                  Kraken
                                                </Option>
                                                <Option value="Gemini">
                                                  Gemini
                                                </Option>
                                                <Option value="Bitfinex">
                                                  Bitfinex
                                                </Option>
                                                <Option value="Bitstamp">
                                                  Bitstamp
                                                </Option>
                                                <Option value="Huobi US">
                                                  Huobi US
                                                </Option>
                                                <Option value="Crypto.com">
                                                  Crypto.com
                                                </Option>
                                                <Option value="BitFlyer">
                                                  BitFlyer
                                                </Option>
                                                <Option value="OKCoin">
                                                  OKCoin
                                                </Option>
                                                <Option value="Other">
                                                  Other
                                                </Option>
                                              </Select>
                                              {data.cryptoAssest
                                                .exchangeOrWallet ===
                                                "Other" && (
                                                <Input
                                                  className="crypto_input crypto_100px"
                                                  placeholder="Other Exchange"
                                                  value={
                                                    data.cryptoAssest
                                                      .otherExchangeOrWallet
                                                  }
                                                  onChange={(e) =>
                                                    handleChanges(
                                                      e,
                                                      "otherExchangeOrWallet"
                                                    )
                                                  }
                                                  required
                                                />
                                              )}
                                            </>
                                          ) : (
                                            <>
                                              <Select
                                                className="crypto_select crypto_100px"
                                                value={
                                                  data.cryptoAssest
                                                    .exchangeOrWallet
                                                }
                                                onChange={(event, newValue) =>
                                                  handleChange(
                                                    event,
                                                    newValue,
                                                    "exchangeOrWallet"
                                                  )
                                                }
                                                required
                                              >
                                                <Option value="">Wallet</Option>
                                                <Option value="Ledger Nano S">
                                                  Ledger Nano S
                                                </Option>
                                                <Option value="Trezor Model T">
                                                  Trezor Model T
                                                </Option>
                                                <Option value="Exodus">
                                                  Exodus
                                                </Option>
                                                <Option value="Atomic Wallet">
                                                  Atomic Wallet
                                                </Option>
                                                <Option value="Trust Wallet">
                                                  Trust Wallet
                                                </Option>
                                                <Option value="MyEtherWallet">
                                                  MyEtherWallet
                                                </Option>
                                                <Option value="Coinomi">
                                                  Coinomi
                                                </Option>
                                                <Option value="MetaMask">
                                                  MetaMask
                                                </Option>
                                                <Option value="Freewallet">
                                                  Freewallet
                                                </Option>
                                                <Option value="KeepKey">
                                                  KeepKey
                                                </Option>
                                                <Option value="Other">
                                                  Other
                                                </Option>
                                              </Select>
                                              {data.cryptoAssest
                                                .exchangeOrWallet ===
                                                "Other" && (
                                                <Input
                                                  className="crypto_input crypto_100px"
                                                  placeholder="Other Wallet"
                                                  value={
                                                    data.cryptoAssest
                                                      .otherExchangeOrWallet
                                                  }
                                                  onChange={(e) =>
                                                    handleChanges(
                                                      e,
                                                      "otherExchangeOrWallet"
                                                    )
                                                  }
                                                  required
                                                />
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </td>

                                      <td>
                                        <Input
                                          className="crypto_input crypto_100px"
                                          type="number"
                                          placeholder="Quantity"
                                          value={data.cryptoAssest.quantity}
                                          onChange={(e) =>
                                            handleChanges(e, "quantity")
                                          }
                                          required
                                        />
                                        {!errors.quantityZeroValidate && (
                                          <FormHelperText error>
                                            <Typography
                                              variant="caption"
                                              color="error"
                                              sx={{ fontSize: "9px" }}
                                            >
                                              Quantity can't be zero
                                            </Typography>
                                          </FormHelperText>
                                        )}
                                      </td>

                                      <td>
                                        <Input
                                          className="crypto_input crypto_100px"
                                          placeholder="Est Value"
                                          type="number"
                                          value={data.cryptoAssest.estValue}
                                          onChange={(e) =>
                                            handleChanges(e, "estValue")
                                          }
                                          startDecorator={<div>$</div>}
                                          required
                                          disabled
                                        />
                                      </td>

                                      <td>
                                        <div className="jewelry_choose_files crypto_115px">
                                          <div>
                                            <label
                                              className="otherAsset_choose_file_label"
                                              htmlFor={`files`}
                                            >
                                              Choose Files
                                            </label>
                                          </div>
                                          {selectedImage?.length > 0 && (
                                            <div>
                                              <label className="jewelry_choose_images_file">
                                                {selectedImage?.length === 1
                                                  ? `${
                                                      selectedImage[0].name
                                                        .length > 15
                                                        ? selectedImage[0].name.substring(
                                                            0,
                                                            4
                                                          ) +
                                                          "..." +
                                                          selectedImage[0].name.slice(
                                                            -6
                                                          )
                                                        : selectedImage[0].name
                                                    }`
                                                  : `${selectedImage?.length} files`}
                                              </label>
                                            </div>
                                          )}
                                          <input
                                            style={{ display: "none" }}
                                            id={`files`}
                                            type="file"
                                            name="myfile"
                                            onChange={(e) =>
                                              handleImageChange(e, 5)
                                            }
                                            accept=".pdf"
                                            multiple
                                          />
                                        </div>
                                      </td>

                                      <td>
                                        <div
                                          className="bene-select beneficiary_btn"
                                          onClick={() =>
                                            handleShowBeneficiary()
                                          }
                                        >
                                          Beneficiary
                                        </div>
                                      </td>

                                      <td>
                                        <Textarea
                                          style={{ fontSize: "14px" }}
                                          placeholder="Notes"
                                          id="notes"
                                          value={data.cryptoAssest.notes}
                                          onChange={(e) =>
                                            handleChanges(e, "notes")
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                <div className="crypto_recommendations">
                                  <div className="crypto_recommendation_base">
                                    <input
                                      type="checkbox"
                                      className="crypto_check"
                                      required
                                    />
                                    <p>
                                      <span style={{ fontWeight: "bold" }}>
                                        Secure your Bitcoin
                                      </span>{" "}
                                      with a hardware wallet or paper wallet.
                                    </p>
                                  </div>
                                  <div className="crypto_recommendation_base">
                                    <input
                                      type="checkbox"
                                      className="crypto_check"
                                      required
                                    />
                                    <p>
                                      <span style={{ fontWeight: "bold" }}>
                                        Create a detailed plan
                                      </span>{" "}
                                      including a will or trust t hat outlines
                                      how your Bitcoin should be transferred.
                                    </p>
                                  </div>
                                  <div className="crypto_recommendation_base">
                                    <input
                                      type="checkbox"
                                      className="crypto_check"
                                      required
                                    />
                                    <p>
                                      <span style={{ fontWeight: "bold" }}>
                                        Provide clear instructions
                                      </span>{" "}
                                      on accessing Bitcoin and re your heir
                                      understands them.
                                    </p>
                                  </div>
                                  <div className="crypto_recommendation_base">
                                    <input
                                      type="checkbox"
                                      className="crypto_check"
                                      required
                                    />
                                    <p>
                                      <span style={{ fontWeight: "bold" }}>
                                        Consult professionals
                                      </span>{" "}
                                      for legal and tax advice
                                    </p>
                                  </div>
                                  <div className="crypto_recommendation_base">
                                    <p>
                                      <span style={{ fontWeight: "bold" }}>
                                        Third Party applications like
                                        UnChained.com are suitable for safe
                                        transfer to heirs
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                            <CardFooter>
                              <div className="text-center">
                                <Button
                                  className="my-estate-clear-btn"
                                  onClick={resetForm}
                                  type="reset"
                                  outline
                                >
                                  Clear
                                </Button>
                                <Button
                                  outline
                                  type=""
                                  className="my-estate-add-btn"
                                >
                                  Add
                                </Button>
                              </div>
                            </CardFooter>
                          </Card>
                        </div>
                      </container>
                    </Form>
                  </div>
                </div>
              </SideBar>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        <SharedBeneficiary
          handleTrustSave={handleTrustSave}
          assetData={data?.cryptoAssest}
          trusts={trusts}
          handleChanges={handleChanges}
          sharedType={sharedType}
          handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
          estimatedTotalAmount={estimatedTotalAmount}
          typeBeneficiary={"normal"}
          sharedDetails={data?.sharedDetails}
        />
      )}
    </>
  );
}

export function InternationalAssetJewelry() {
  const navigate = useNavigate();
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    jewelry: {
      jewelryListName: "",
      owner: "",
      jewelryCaption: "",
      jewelryName: "",
      otherJewelryName: "",
      quantity: "",
      perQuantityValue: "",
      estimatedValue: "",
      caratValue: "",
      weight: "",
      trust: "",
      beneficiaryName: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "jewelry",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "caratValue") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          caratValue: newValue,
        },
      }));
    }

    if (stringValue === "jewelryName") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          jewelryName: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();

  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage1, setSelectedImage1] = useState([]);

  useEffect(() => {
    if (data.jewelry.jewelryName !== "Other") {
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          otherJewelryName: "",
        },
      }));
    }
  }, [data.jewelry.jewelryName]);

  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          trust: "",
        },
      }));
      setData((prevData) => ({
        ...prevData,
        jewelry: {
          ...prevData.jewelry,
          beneficiaryName: "",
        },
      }));
    } else {
      if (field === "quantity" || field === "perQuantityValue") {
        if (field === "quantity") {
          setData((prevData) => ({
            ...prevData,
            jewelry: {
              ...prevData.jewelry,
              [field]: newValue,
              estimatedValue: newValue * data.jewelry.perQuantityValue,
            },
          }));
        } else {
          setData((prevData) => ({
            ...prevData,
            jewelry: {
              ...prevData.jewelry,
              [field]: newValue,
              estimatedValue: newValue * data.jewelry.quantity,
            },
          }));
        }
      } else {
        setData((prevData) => ({
          ...prevData,
          jewelry: {
            ...prevData.jewelry,
            [field]: newValue,
          },
        }));
      }
      setEstimatedTotalAmount(data.jewelry.estimatedValue);
    }
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const handleImageChange1 = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["jpg", "jpeg", "png", "gif"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`imageFiles${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage1(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid image formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      jewelry: {
        jewelryCaption: "",
        jewelryName: "",
        otherJewelryName: "",
        quantity: "",
        perQuantityValue: "",
        estimatedValue: "",
        caratValue: "",
        weight: "",
        trust: "",
        beneficiaryName: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
    setData1({ assetCaption: "", assetType: "jewelry", countryName: "" });
    setOwnerName([]);
    setSharedType(null);
  };

  // Set the form
  const jewelryForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    //create form data to send a file and remaining class data
    const formData = new FormData();

    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
    }

    for (let i = 0; i < selectedImage1.length; i++) {
      formData.append(`imageFiles`, selectedImage1[i]);
    }

    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    // return null;
    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  // for add field pop
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.jewelry.estimatedValue);
  };
  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };
  const handleReset = () => {
    setbeneVisible(false);
  };
  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div className="overlay1" style={{ transition: "500ms" }}>
                    <div>
                      <Form onSubmit={jewelryForm} className="addform_body">
                        <container>
                          <div className="homepage jewelry_container">
                            <Card>
                              <CardHeader>
                                <div
                                  style={{ width: "100%" }}
                                  className="addform_heading"
                                >
                                  <h3 className="addform_heading_h1">
                                    {"Add Jewelry"}
                                  </h3>
                                  <div
                                    className="addform_close"
                                    onClick={toggle}
                                  >
                                    <FontAwesomeIcon icon={faXmark} />
                                  </div>
                                </div>
                              </CardHeader>
                              <CardBody className="jewelry_card_body">
                                <div>
                                  <table className="jewelry_table">
                                    <thead>
                                      <tr>
                                        <th className="jewelry_130px">
                                          Caption
                                        </th>
                                        <th className="jewelry_110px">
                                          Country*
                                        </th>
                                        <th className="jewelry_130px">
                                          Select Owner*
                                        </th>
                                        <th className="jewelry_110px">
                                          Jewelry Type*
                                        </th>
                                        <th className="jewelry_130px">
                                          Jewelry Name*
                                        </th>
                                        <th className="jewelry_100px">
                                          Weight(gm)*
                                        </th>
                                        <th className="jewelry_90px">
                                          Quantity*
                                        </th>
                                        <th className="jewelry_90px">
                                          Qty/Value*
                                        </th>
                                        <th className="jewelry_130px">
                                          Appraised Val*
                                        </th>
                                        <th>Images</th>
                                        <th>Documents</th>
                                        <th>Beneficiary*</th>
                                        <th>Notes</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <Input
                                            className="crypto_select crypto_115px"
                                            placeholder="Enter caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </td>
                                        <td>
                                          <Autocomplete
                                            className="crypto_select crypto_115px"
                                            placeholder="Country"
                                            options={countries}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={
                                              countries.find(
                                                (country) =>
                                                  country.label ===
                                                  data1.countryName
                                              ) || null
                                            }
                                            required
                                          />
                                        </td>
                                        <td>
                                          <Select
                                            className="jewelry_select jewelry_130px"
                                            placeholder="Select Owner"
                                            value={ownerName}
                                            multiple
                                            onChange={(event, newValue) =>
                                              handleChange(
                                                event,
                                                newValue,
                                                "owner"
                                              )
                                            }
                                            sx={{ minWidth: "13rem" }}
                                            slotProps={{
                                              listbox: {
                                                sx: { width: "100%" },
                                              },
                                            }}
                                            required
                                          >
                                            {ownerNames.map((name) => (
                                              <Option key={name} value={name}>
                                                {name}
                                              </Option>
                                            ))}
                                          </Select>
                                        </td>

                                        <td>
                                          <div className="jewelry_select_and_input">
                                            <div>
                                              <Select
                                                className="jewelry_select jewelry_110px"
                                                value={data.jewelry.jewelryName}
                                                onChange={(event, newValue) =>
                                                  handleChange(
                                                    event,
                                                    newValue,
                                                    "jewelryName"
                                                  )
                                                }
                                                required
                                              >
                                                <Option value="">
                                                  Jewelry Type
                                                </Option>
                                                <Option value="Gold">
                                                  Gold
                                                </Option>
                                                <Option value="Silver">
                                                  Silver
                                                </Option>
                                                <Option value="Platinum">
                                                  Platinum
                                                </Option>
                                                <Option value="Palladium">
                                                  Palladium
                                                </Option>
                                                <Option value="Stone">
                                                  Stone
                                                </Option>
                                                <Option value="Other">
                                                  Other
                                                </Option>
                                              </Select>
                                            </div>
                                          </div>
                                        </td>

                                        <td>
                                          <Input
                                            className="jewelry_input jewelry_130px"
                                            placeholder="Jewelry Name"
                                            onChange={(e) =>
                                              handleChanges(e, "jewelryCaption")
                                            }
                                            value={data.jewelry.jewelryCaption}
                                          />
                                        </td>

                                        <td>
                                          <Input
                                            className="jewelry_input jewelry_100px"
                                            type="number"
                                            placeholder="Weight"
                                            onChange={(e) =>
                                              handleChanges(e, "weight")
                                            }
                                            value={data.jewelry.weight}
                                            required
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="number"
                                            className="jewelry_input jewelry_90px"
                                            placeholder="Quantity"
                                            onChange={(e) =>
                                              handleChanges(e, "quantity")
                                            }
                                            value={data.jewelry.quantity}
                                            required
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            type="number"
                                            className="jewelry_input jewelry_90px"
                                            placeholder="Value"
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "perQuantityValue"
                                              )
                                            }
                                            value={
                                              data.jewelry.perQuantityValue
                                            }
                                            startDecorator={<div>$</div>}
                                            required
                                          />
                                        </td>
                                        <td>
                                          <Input
                                            className="jewelry_input jewelry_130px"
                                            id={`estimatedValue`}
                                            placeholder="Appraised Value"
                                            type="number"
                                            onChange={(e) => {
                                              handleChanges(
                                                e,
                                                "estimatedValue"
                                              );
                                            }}
                                            value={data.jewelry.estimatedValue}
                                            startDecorator={<div>$</div>}
                                            required
                                            disabled
                                          />
                                        </td>
                                        <td>
                                          <div className="jewelry_choose_images">
                                            <div>
                                              <label
                                                className="jewelry_choose_images_label"
                                                htmlFor={`images`}
                                              >
                                                Images
                                              </label>
                                            </div>
                                            {selectedImage1?.length > 0 && (
                                              <div>
                                                <label className="jewelry_choose_images_file">
                                                  {selectedImage1?.length === 1
                                                    ? `${
                                                        selectedImage1[0].name
                                                          .length > 15
                                                          ? selectedImage1[0].name.substring(
                                                              0,
                                                              4
                                                            ) +
                                                            "..." +
                                                            selectedImage1[0].name.slice(
                                                              -6
                                                            )
                                                          : selectedImage1[0]
                                                              .name
                                                      }`
                                                    : `${selectedImage1?.length} images`}
                                                </label>
                                              </div>
                                            )}

                                            <input
                                              style={{
                                                display: "none",
                                              }}
                                              id={`images`}
                                              type="file"
                                              name="images"
                                              onChange={(e) =>
                                                handleImageChange1(e)
                                              }
                                              multiple
                                              accept="image/*"
                                            />
                                          </div>
                                        </td>

                                        <td>
                                          <div className="jewelry_choose_files">
                                            <div>
                                              <label
                                                className="jewelry_choose_file_label"
                                                htmlFor={`files`}
                                              >
                                                Documents
                                              </label>
                                            </div>
                                            {selectedImage?.length > 0 && (
                                              <div>
                                                <label className="jewelry_choose_images_file">
                                                  {selectedImage?.length === 1
                                                    ? `${
                                                        selectedImage[0].name
                                                          .length > 15
                                                          ? selectedImage[0].name.substring(
                                                              0,
                                                              4
                                                            ) +
                                                            "..." +
                                                            selectedImage[0].name.slice(
                                                              -6
                                                            )
                                                          : selectedImage[0]
                                                              .name
                                                      }`
                                                    : `${selectedImage?.length} files`}
                                                </label>
                                              </div>
                                            )}

                                            <input
                                              style={{
                                                display: "none",
                                              }}
                                              id={`files`}
                                              type="file"
                                              name="myfile"
                                              onChange={(e) =>
                                                handleImageChange(e, 5)
                                              }
                                              accept=".pdf"
                                              multiple
                                            />
                                          </div>
                                        </td>

                                        <td>
                                          <div
                                            className="bene-select"
                                            onClick={handleShowBeneficiary}
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            Beneficiary
                                          </div>
                                        </td>
                                        <td>
                                          <Textarea
                                            placeholder="Notes"
                                            id="notes"
                                            onChange={(e) =>
                                              handleChanges(e, "notes")
                                            }
                                            value={data.jewelry.notes}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </CardBody>
                              <CardFooter>
                                <div className="text-center">
                                  <Button
                                    className="my-estate-clear-btn"
                                    onClick={resetForm}
                                    type="reset"
                                    outline
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    outline
                                    type=""
                                    className="my-estate-add-btn"
                                  >
                                    Add
                                  </Button>
                                </div>
                              </CardFooter>
                            </Card>
                          </div>
                        </container>
                      </Form>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        <SharedBeneficiary
          assetName={"jewelry"}
          handleTrustSave={handleTrustSave}
          assetData={data?.jewelry}
          trusts={trusts}
          handleChanges={handleChanges}
          sharedType={sharedType}
          handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
          estimatedTotalAmount={estimatedTotalAmount}
          typeBeneficiary={"normal"}
          sharedDetails={data?.sharedDetails}
        />
      )}
    </>
  );
}

export function InternationalAssetInsurance() {
  const navigate = useNavigate();
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  // set Add data
  const [data, setData] = useState({
    insurance: {
      owner: "",
      insuranceCaption: "",
      insuranceAmount: "",
      insuranceProvider: "",
      details: "",
      trust: "",
      notes: "",
    },
    deathBenefitsModel: {
      deathBenefits: {
        insuranceAmount: "",
        trust: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "insurance",
    countryName: "",
  });

  const [errors, setErrors] = useState({
    insuranceAmountValidate: true,
    deathInsuranceAmountValidate: true,
    insuranceAmountZeroValidate: true,
    deathInsuranceAmountZeroValidate: true,
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        insurance: {
          ...prevData.insurance,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  const resetSharedDetails = [
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ];
  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        insurance: {
          ...prevData.insurance,
          trust: "",
        },
      }));
    } else if (field === "deathBenefitsSharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        return {
          ...prevData,
          deathBenefitsModel: {
            ...prevData.deathBenefitsModel,
            sharedDetails: resetSharedDetails,
          },
        };
      });

      setData((prevData) => ({
        ...prevData,
        deathBenefitsModel: {
          ...prevData.deathBenefitsModel,
          deathBenefits: {
            ...prevData.deathBenefitsModel?.deathBenefits,
            trust: "", // Update death benefits insurance amount
          },
        },
      }));
    } else {
      setData((prevData) => {
        if (field === "insuranceAmount") {
          errorStates.insuranceAmountValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.insuranceAmountZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
          return {
            ...prevData,
            insurance: {
              ...prevData.insurance,
              insuranceAmount: newValue, // Update the field dynamically
            },
          };
        } else if (field === "deathBenefitsInsuranceAmount") {
          errorStates.deathInsuranceAmountValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.deathInsuranceAmountZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
          return {
            ...prevData,
            deathBenefitsModel: {
              ...prevData.deathBenefitsModel,
              deathBenefits: {
                ...prevData.deathBenefitsModel?.deathBenefits,
                insuranceAmount: newValue, // Update death benefits insurance amount
              },
            },
          };
        } else if (field === "deathBenefitsTrust") {
          return {
            ...prevData,
            deathBenefitsModel: {
              ...prevData.deathBenefitsModel,
              deathBenefits: {
                ...prevData.deathBenefitsModel?.deathBenefits,
                trust: newValue, // Update death benefits insurance amount
              },
            },
          };
        } else {
          return {
            ...prevData,
            insurance: {
              ...prevData.insurance,
              [field]: newValue, // Update the field dynamically
            },
          };
        }
      });
    }
    setErrors(errorStates);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setData({
      insurance: {
        insuranceCaption: "",
        insuranceAmount: "",
        insuranceProvider: "",
        details: "",
        notes: "",
      },
      deathBenefitsModel: {
        deathBenefits: {
          insuranceAmount: "",
          trust: "",
        },
        sharedDetails: [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ],
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });

    setData1({ assetCaption: "", assetType: "insurance", countryName: "" });
    setOwnerName([]);
    setIsChecked(false);
    setIsDisableNormal(false);
    setIsDisableDeathBenefits(false);
    setErrors({
      insuranceAmountValidate: true,
      deathInsuranceAmountValidate: true,
      insuranceAmountZeroValidate: true,
      deathInsuranceAmountZeroValidate: true,
    });
  };

  // Set the form
  const lifeForm = (event) => {
    event.preventDefault();

    const hasErrors = Object.values(errors).includes(false);
    if (!hasErrors) {
      let token = "Bearer " + getToken();

      //create form data to send a file and remaining class data
      const formData = new FormData();
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
      formData.append("asset", JSON.stringify(data));
      formData.append("data", JSON.stringify(data1));

      addInternationalAsset(formData, token)
        .then((resp) => {
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          toggle();
        })
        .catch((error) => {});
    }
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [typeBeneficiary, setTypeBeneficiary] = useState(null);

  const handleShowBeneficiary = (event, beneficiaryType) => {
    if (beneficiaryType === "death-benefits") {
      setTypeBeneficiary(beneficiaryType);
      setEstimatedTotalAmount(
        data.deathBenefitsModel?.deathBenefits?.insuranceAmount
      );
    } else {
      setTypeBeneficiary(beneficiaryType);
      setEstimatedTotalAmount(data.insurance?.insuranceAmount);
    }
    setbeneVisible(true);
  };

  const [isDisabledNormal, setIsDisableNormal] = useState(false);
  const [isDisabledDeathBenefits, setIsDisableDeathBenefits] = useState(false);

  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;

    if (typeBeneficiary === "normal") {
      if (name !== undefined) {
        setData((prevData) => ({
          ...prevData,
          sharedDetails: name,
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          sharedDetails: resetSharedDetails,
        }));
      }

      if (name !== undefined && name[0]?.distributedType !== "") {
        setIsDisableNormal(true);
      }
    } else {
      if (name !== undefined) {
        setData((prevData) => ({
          ...prevData,
          deathBenefitsModel: {
            ...prevData.deathBenefitsModel,
            sharedDetails: name,
          },
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          deathBenefitsModel: {
            ...prevData.deathBenefitsModel,
            sharedDetails: resetSharedDetails,
          },
        }));
      }

      if (name !== undefined && name[0]?.distributedType !== "") {
        setIsDisableDeathBenefits(true);
      }
    }
    setbeneVisible(false);
  };

  const handleTrustSave = () => {
    setbeneVisible(false);

    if (typeBeneficiary === "normal" && data?.insurance?.trust !== "") {
      setIsDisableNormal(true);
    }
    if (
      typeBeneficiary !== "normal" &&
      data?.deathBenefitsModel?.deathBenefits?.trust !== ""
    ) {
      setIsDisableDeathBenefits(true);
    }
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div className="overlay1" style={{ transition: "500ms" }}>
                    <div className="property_form">
                      <Container>
                        <Card color="" outline>
                          <CardHeader>
                            <h3 className="form1-heading">Life Insurance</h3>
                            <div className="Close" onClick={toggle}>
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <Form onSubmit={lifeForm}>
                              <div className="mt-2 ">
                                <div>
                                  <Tooltip title="Enter Caption for your assets">
                                    <div>
                                      <FormLabel>Assets Caption</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter asset caption"
                                        onChange={(e) =>
                                          handleChangesData(e, "assetCaption")
                                        }
                                        value={data1.assetCaption}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="mt-2">
                                <div>
                                  <Tooltip title="Enter the Country of your assets">
                                    <div>
                                      <FormLabel required>
                                        Country Name
                                      </FormLabel>

                                      <Autocomplete
                                        placeholder="Choose a country"
                                        options={countries}
                                        autoHighlight
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        onChange={(e, newValue) =>
                                          handleChangesCountry(e, newValue)
                                        }
                                        value={
                                          countries.find(
                                            (country) =>
                                              country.label ===
                                              data1.countryName
                                          ) || null
                                        }
                                        required
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div>
                                <Tooltip title="Select Owner">
                                  <div>
                                    <FormLabel required>Select Owner</FormLabel>

                                    <Select
                                      placeholder="Select Owner"
                                      value={ownerName}
                                      multiple
                                      onChange={(event, newValue) =>
                                        handleChange(event, newValue, "owner")
                                      }
                                      sx={{
                                        minWidth: "13rem",
                                      }}
                                      slotProps={{
                                        listbox: {
                                          sx: {
                                            width: "100%",
                                          },
                                        },
                                      }}
                                      required
                                    >
                                      {ownerNames.map((name) => (
                                        <Option key={name} value={name}>
                                          {name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter Heading for Insurance">
                                  <div className="mt-2">
                                    <FormLabel>Insurance Heading</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter insurance heading"
                                      onChange={(e) =>
                                        handleChanges(e, "insuranceCaption")
                                      }
                                      value={data.insurance.insuranceCaption}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter Heading for Insurance">
                                  <div className="mt-2">
                                    <FormLabel required>
                                      Insurance Provider
                                    </FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter insurance provider"
                                      onChange={(e) =>
                                        handleChanges(e, "insuranceProvider")
                                      }
                                      value={data.insurance.insuranceProvider}
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter The Name Of Your Insurance ">
                                  <div className="mt-2">
                                    <FormLabel required>Details</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter details"
                                      onChange={(e) =>
                                        handleChanges(e, "details")
                                      }
                                      value={data.insurance.details}
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <h4 className="mt-2">Death Benefits</h4>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="address_input">
                                  <div className="addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your Insurance Amount">
                                        <div style={{ marginTop: "3px" }}>
                                          <FormLabel required>
                                            Insurance Amount
                                          </FormLabel>
                                          <Input
                                            type="number"
                                            className="input_mui_joy"
                                            placeholder="Enter insurance amount"
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "deathBenefitsInsuranceAmount"
                                              )
                                            }
                                            value={
                                              data.deathBenefitsModel
                                                ?.deathBenefits?.insuranceAmount
                                            }
                                            startDecorator={<div>$</div>}
                                            required
                                            disabled={isDisabledDeathBenefits}
                                          />
                                          {(!errors.deathInsuranceAmountValidate ||
                                            !errors.deathInsuranceAmountZeroValidate) && (
                                            <FormHelperText error>
                                              <Typography
                                                variant="caption"
                                                color="error"
                                              >
                                                {!errors.deathInsuranceAmountValidate
                                                  ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                                  : !errors.deathInsuranceAmountZeroValidate
                                                  ? "Value can't be zero"
                                                  : ""}
                                              </Typography>
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                  <div className="mt-2 addform_input_half">
                                    <div
                                      className={`bene-select mt-3 `}
                                      onClick={
                                        data.deathBenefitsModel?.deathBenefits
                                          ?.insuranceAmount === "" ||
                                        Number(
                                          data.deathBenefitsModel?.deathBenefits
                                            ?.insuranceAmount
                                        ) === 0 ||
                                        isDisabledDeathBenefits
                                          ? null
                                          : (event) =>
                                              handleShowBeneficiary(
                                                event,
                                                "death-benefits"
                                              )
                                      }
                                      style={{
                                        cursor:
                                          data.deathBenefitsModel?.deathBenefits
                                            ?.insuranceAmount === "" ||
                                          Number(
                                            data.deathBenefitsModel
                                              ?.deathBenefits?.insuranceAmount
                                          ) === 0 ||
                                          isDisabledDeathBenefits
                                            ? "not-allowed"
                                            : "pointer",
                                        opacity:
                                          data.deathBenefitsModel?.deathBenefits
                                            ?.insuranceAmount === "" ||
                                          Number(
                                            data.deathBenefitsModel
                                              ?.deathBenefits?.insuranceAmount
                                          ) === 0 ||
                                          isDisabledDeathBenefits
                                            ? 0.6
                                            : 1,
                                      }}
                                    >
                                      Select Your Beneficiary
                                    </div>
                                  </div>
                                </div>
                                <FormHelperText error>
                                  <Typography variant="caption" color="error">
                                    Note: Please recheck "Insurance Amount"
                                    before selecting your beneficiary.
                                  </Typography>
                                </FormHelperText>
                              </div>

                              <div className="mt-2 switch_changes_main">
                                <div className="switch_changes_base">
                                  <div>
                                    Do you have current cash value of the
                                    Insurance?
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={isChecked}
                                          onChange={handleSwitchChange}
                                          color="primary"
                                          size="normal"
                                        />
                                      }
                                      label={isChecked ? "Yes" : "No"}
                                      labelPlacement="end"
                                    />
                                  </div>
                                </div>
                              </div>
                              {isChecked && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <div className="address_input">
                                    <div className="addform_input_half">
                                      <div>
                                        <Tooltip title="Enter your Insurance Amount">
                                          <div style={{ marginTop: "3px" }}>
                                            <FormLabel>
                                              Insurance Cash Amount
                                            </FormLabel>
                                            <Input
                                              type="number"
                                              className="input_mui_joy"
                                              placeholder="Enter insurance cash amount"
                                              onChange={(e) =>
                                                handleChanges(
                                                  e,
                                                  "insuranceAmount"
                                                )
                                              }
                                              value={
                                                data.insurance.insuranceAmount
                                              }
                                              startDecorator={<div>$</div>}
                                              required
                                              disabled={isDisabledNormal}
                                            />
                                            {(!errors.insuranceAmountValidate ||
                                              !errors.insuranceAmountZeroValidate) && (
                                              <FormHelperText error>
                                                <Typography
                                                  variant="caption"
                                                  color="error"
                                                >
                                                  {!errors.insuranceAmountValidate
                                                    ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                                    : !errors.insuranceAmountZeroValidate
                                                    ? "Value can't be zero"
                                                    : ""}
                                                </Typography>
                                              </FormHelperText>
                                            )}
                                          </div>
                                        </Tooltip>
                                      </div>
                                    </div>
                                    <div className="mt-2 addform_input_half">
                                      <div
                                        className={`bene-select mt-3`}
                                        onClick={
                                          data.insurance.insuranceAmount ===
                                            "" ||
                                          Number(
                                            data.insurance.insuranceAmount
                                          ) === 0 ||
                                          isDisabledNormal
                                            ? null
                                            : (event) =>
                                                handleShowBeneficiary(
                                                  event,
                                                  "normal"
                                                )
                                        }
                                        style={{
                                          cursor:
                                            data.insurance.insuranceAmount ===
                                              "" ||
                                            Number(
                                              data.insurance.insuranceAmount
                                            ) === 0 ||
                                            isDisabledNormal
                                              ? "not-allowed"
                                              : "pointer",
                                          opacity:
                                            data.insurance.insuranceAmount ===
                                              "" ||
                                            Number(
                                              data.insurance.insuranceAmount
                                            ) === 0 ||
                                            isDisabledNormal
                                              ? 0.6
                                              : 1,
                                        }}
                                      >
                                        Select Your Beneficiary
                                      </div>
                                    </div>
                                  </div>
                                  <FormHelperText error>
                                    <Typography variant="caption" color="error">
                                      Note: Please recheck "Insurance Cash
                                      Amount" before selecting your beneficiary.
                                    </Typography>
                                  </FormHelperText>
                                </div>
                              )}

                              <div className="mt-2">
                                <Tooltip title="Add your insurance related file">
                                  <div>
                                    <label
                                      style={{
                                        display: "block",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Supporting Document<span></span>
                                    </label>
                                    <input
                                      style={{
                                        border: "solid 1px lightgray",
                                        borderLeft: "none",
                                        width: "100%",
                                        borderRadius: "5px",
                                      }}
                                      type="file"
                                      name="myfile"
                                      id="exampleFile"
                                      multiple
                                      accept=".pdf"
                                      onChange={(e) => handleImageChange(e)}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div className="address_input">
                                <div style={{ width: "100%" }} className="mt-2">
                                  <div>
                                    <FormLabel>
                                      Notes/Point of contact
                                    </FormLabel>
                                    <Textarea
                                      sx={{
                                        height: "182px",
                                        minHeight: "52px",
                                      }}
                                      placeholder="Notes"
                                      id="notes"
                                      onChange={(e) =>
                                        handleChanges(e, "notes")
                                      }
                                      value={data.insurance.notes}
                                    />
                                  </div>
                                </div>
                              </div>

                              <Container className="text-center">
                                <Button
                                  onClick={resetForm}
                                  className="my-estate-clear-btn"
                                  type="reset"
                                  outline
                                >
                                  Clear
                                </Button>
                                <Button outline className="my-estate-add-btn">
                                  Add
                                </Button>
                              </Container>
                            </Form>
                          </CardBody>
                        </Card>
                      </Container>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        <SharedBeneficiary
          handleTrustSave={handleTrustSave}
          assetData={
            typeBeneficiary === "normal"
              ? data?.insurance
              : data?.deathBenefitsModel
          }
          trusts={trusts}
          handleChanges={handleChanges}
          sharedType={sharedType}
          handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
          estimatedTotalAmount={estimatedTotalAmount}
          typeBeneficiary={typeBeneficiary}
          sharedDetails={
            typeBeneficiary === "normal"
              ? data.sharedDetails
              : data?.deathBenefitsModel?.sharedDetails
          }
        />
      )}
    </>
  );
}

export function InternationalAssetVehicles() {
  const navigate = useNavigate();
  //  form show button
  let [form1, setForm1] = useState(true);

  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };

  const [data, setData] = useState({
    vehicle: {
      owner: "",
      vehicleCaption: "",
      vehicleType: "",
      otherVehicleType: "",
      year: "",
      vinNumber: "",
      make: "",
      otherMake: "",
      estValue: "",
      model: "",
      otherModel: "",
      miles: "",
      trust: "",
      notes: "",
    },
    mortgage: {
      mortgage: "",
      mortgageInstitution: "",
      accountNumber: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "vehicle",
    countryName: "",
  });

  const [errors, setErrors] = useState({
    estValueValidate: true,
    mortgageAmountValidate: true,
    estValueZeroValidate: true,
    mortgageAmountZeroValidate: true,
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "vehicleType") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          vehicleType: newValue,
        },
      }));
    }

    if (stringValue === "type") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          type: newValue,
        },
      }));
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          make: "",
          model: "",
        },
      }));
    }

    if (stringValue === "make") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          make: newValue,
        },
      }));
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          model: "",
        },
      }));
    }

    if (stringValue === "model") {
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          model: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string
      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);
  const [sharedType, setSharedType] = useState(null);
  const currentYear = new Date().getFullYear();
  const handleChanges = (e, field, section) => {
    const newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        vehicle: {
          ...prevData.vehicle,
          trust: "",
        },
      }));
    } else {
      if (section === "vehicle" || field === "trust") {
        if (field === "year" && newValue > currentYear) {
          if (newValue > currentYear) {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: "",
              },
            }));
            toast.error("You cannot select a future year.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else if (newValue.length === 4 && newValue < 1900) {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: "",
              },
            }));
            toast.error("You cannot select year before 1900.", {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          } else {
            setData((prevData) => ({
              ...prevData,
              vehicle: {
                ...prevData.vehicle,
                [field]: newValue,
              },
            }));
          }
        } else {
          setData((prevData) => ({
            ...prevData,
            vehicle: {
              ...prevData.vehicle,
              [field]: newValue,
            },
          }));

          if (field === "estValue") {
            errorStates.estValueValidate =
              newValue !== "" ? validateEightDigitNumber(newValue) : true;
            errorStates.estValueZeroValidate =
              newValue !== "" ? Number(newValue) > 0 : true;
            setEstimatedTotalAmount(data.vehicle.estValue);
          }
        }
      } else {
        if (field === "mortgage") {
          errorStates.mortgageAmountValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.mortgageAmountZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
        }

        setData((prevData) => ({
          ...prevData,
          mortgage: {
            ...prevData.mortgage,
            [field]: newValue,
          },
        }));
      }
    }

    setErrors(errorStates);
  };
  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  const resetForm = () => {
    setData({
      vehicle: {
        owner: "",
        vehicleCaption: "",
        vehicleType: "",
        otherVehicleType: "",
        year: "",
        vinNumber: "",
        make: "",
        otherMake: "",
        estValue: "",
        model: "",
        otherModel: "",
        miles: "",
        trust: "",
        notes: "",
      },
      mortgage: {
        mortgage: "",
        mortgageInstitution: "",
        accountNumber: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
    setOwnerName([]);
    handleReset();
    setIsChecked(false);
    setErrors({
      estValueValidate: true,
      mortgageAmountValidate: true,
      estValueZeroValidate: true,
      mortgageAmountZeroValidate: true,
    });
  };

  // Set the form
  const vehiclesForm = (event) => {
    event.preventDefault();
    const hasErrors = Object.values(errors).includes(false);
    if (!hasErrors) {
      let token = "Bearer " + getToken();

      //create form data to send a file and remaining class data
      const formData = new FormData();

      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
      formData.append("asset", JSON.stringify(data));
      formData.append("data", JSON.stringify(data1));

      addInternationalAsset(formData, token)
        .then((resp) => {
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          toggle();
        })
        .catch((error) => {});
    }
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.vehicle.estValue);
  };

  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const handleReset = () => {
    setbeneVisible(false);
  };

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div
                    className="overlay1"
                    style={{ transition: "500ms", height: "" }}
                  >
                    <div className="addform_ichest">
                      <div className="addform_main">
                        <div className="addform_heading">
                          <h3 className="addform_heading_h1">Add Vehicle</h3>
                          <div className="addform_close" onClick={toggle}>
                            <FontAwesomeIcon icon={faXmark} />
                          </div>
                        </div>
                        <div className="addform_body">
                          <Form
                            onSubmit={vehiclesForm}
                            className="addform_body"
                          >
                            <div className="addform_select_body">
                              <div className="addform_body_left">
                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter Caption for your assets">
                                        <div>
                                          <FormLabel>Assets Caption</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            placeholder="Enter asset caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the Country of your assets">
                                        <div>
                                          <FormLabel required>
                                            Country Name
                                          </FormLabel>

                                          <Autocomplete
                                            placeholder="Choose a country"
                                            options={countries}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={
                                              countries.find(
                                                (country) =>
                                                  country.label ===
                                                  data1.countryName
                                              ) || null
                                            }
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  <Tooltip
                                    title="Select Owner"
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel required>
                                        Select Owner
                                      </FormLabel>
                                      <Autocomplete
                                        multiple
                                        id="tags-default"
                                        placeholder="Select Owner"
                                        options={ownerNames}
                                        value={ownerName}
                                        onChange={(event, newValue) =>
                                          handleChange(event, newValue, "owner")
                                        }
                                        required={
                                          ownerName.length > 0 ? false : true
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div>
                                  <Tooltip title="Enter your vehicle heading">
                                    <div className="mt-2">
                                      <FormLabel>Vehicle Heading</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter vehicle heading"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "vehicleCaption",
                                            "vehicle"
                                          )
                                        }
                                        value={data.vehicle.vehicleCaption}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>

                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the type of your vehicle">
                                        <div className="mt-2">
                                          <FormLabel required>
                                            Vehicle Type
                                          </FormLabel>
                                          <Select
                                            value={data.vehicle.vehicleType}
                                            onChange={(event, newValue) =>
                                              handleChange(
                                                event,
                                                newValue,
                                                "vehicleType"
                                              )
                                            }
                                            required
                                          >
                                            <Option value="">
                                              Select type of vehicle
                                            </Option>
                                            <Option value="Auto">Auto</Option>
                                            <Option value="Two Wheeler">
                                              Two Wheeler
                                            </Option>
                                            <Option value="Boat">Boat</Option>
                                            <Option value="Yacht">Yacht</Option>
                                            <Option value="Other">Other</Option>
                                          </Select>
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the year of Manufacture of your vehicle">
                                        <div className="mt-2">
                                          <FormLabel required>
                                            Manufactured Year
                                          </FormLabel>
                                          <Input
                                            type="number"
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "year",
                                                "vehicle"
                                              )
                                            }
                                            value={data.vehicle.year}
                                            inputProps={{
                                              max: currentYear,
                                            }}
                                            placeholder="Manufactured Year"
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                {data.vehicle.vehicleType === "Other" && (
                                  <div>
                                    <Tooltip title="Enter your other vehicle type">
                                      <div className="mt-2">
                                        <FormLabel>
                                          Other Vehicle Type
                                        </FormLabel>
                                        <Input
                                          className="input_mui_joy"
                                          placeholder="Enter Other Vehicle"
                                          onChange={(e) =>
                                            handleChanges(
                                              e,
                                              "otherVehicleType",
                                              "vehicle"
                                            )
                                          }
                                          value={data.vehicle.otherVehicleType}
                                        />
                                      </div>
                                    </Tooltip>
                                  </div>
                                )}

                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the vin number">
                                        <div>
                                          <FormLabel>Vin Number</FormLabel>
                                          <Input
                                            placeholder="Enter vin number"
                                            value={data.vehicle.vinNumber}
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "vinNumber",
                                                "vehicle"
                                              )
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the company of your vehicle">
                                        <div>
                                          <FormLabel required>Make</FormLabel>

                                          <Input
                                            placeholder="Enter make"
                                            value={data.vehicle.make}
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "make",
                                                "vehicle"
                                              )
                                            }
                                            required
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div className="address_input">
                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter the model of your vehicle">
                                        <div>
                                          <FormLabel>Model</FormLabel>
                                          <Input
                                            placeholder="Enter model"
                                            value={data.vehicle.model}
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "model",
                                                "vehicle"
                                              )
                                            }
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>

                                  <div className="mt-2 addform_input_half">
                                    <div>
                                      <Tooltip title="Enter your vehicle mileage">
                                        <div>
                                          <FormLabel>Miles</FormLabel>
                                          <Input
                                            placeholder="Enter miles"
                                            type="number"
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "miles",
                                                "vehicle"
                                              )
                                            }
                                            value={data.vehicle.miles}
                                          />
                                        </div>
                                      </Tooltip>
                                    </div>
                                  </div>
                                </div>

                                <div>
                                  <Tooltip title="Enter estimated value">
                                    <div className="mt-2">
                                      <FormLabel required>
                                        Estimated Value
                                      </FormLabel>
                                      <Input
                                        type="number"
                                        className="input_mui_joy"
                                        placeholder="Enter Estimated Value"
                                        onChange={(e) =>
                                          handleChanges(
                                            e,
                                            "estValue",
                                            "vehicle"
                                          )
                                        }
                                        value={data.vehicle.estValue}
                                        startDecorator={<div>$</div>}
                                        required
                                      />
                                      {(!errors.estValueValidate ||
                                        !errors.estValueZeroValidate) && (
                                        <FormHelperText error>
                                          <Typography
                                            variant="caption"
                                            color="error"
                                          >
                                            {!errors.estValueValidate
                                              ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                              : !errors.estValueZeroValidate
                                              ? "Value can't be zero"
                                              : ""}
                                          </Typography>
                                        </FormHelperText>
                                      )}
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="addform_body_right_crypto">
                                <div className="mt-2 switch_changes_main">
                                  <div className="switch_changes_base">
                                    <div>Do you have loan against vehicle?</div>
                                    <div>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            checked={isChecked}
                                            onChange={handleSwitchChange}
                                            color="primary"
                                            size="normal"
                                          />
                                        }
                                        label={isChecked ? "Yes" : "No"}
                                        labelPlacement="end"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {isChecked && (
                                  <div>
                                    <div>
                                      <div>
                                        <div>
                                          <FormLabel required>
                                            {`Institution/Bank`}
                                          </FormLabel>

                                          <Input
                                            className="input_mui_joy"
                                            placeholder={`Institution/Bank`}
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "mortgageInstitution",
                                                "mortgage"
                                              )
                                            }
                                            value={
                                              data?.mortgage
                                                ?.mortgageInstitution
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="address_input">
                                      <div className="mt-2 addform_input_half">
                                        <div style={{ width: "100%" }}>
                                          <FormLabel required>
                                            {`Account Number`}
                                          </FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            type="number"
                                            placeholder={`Account Number`}
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "accountNumber",
                                                "mortgage"
                                              )
                                            }
                                            value={
                                              data?.mortgage?.accountNumber
                                            }
                                            required
                                          />
                                        </div>
                                      </div>
                                      <div className="mt-2 addform_input_half">
                                        <div>
                                          <FormLabel
                                            required
                                          >{`Amount`}</FormLabel>
                                          <Input
                                            className="input_mui_joy"
                                            type="number"
                                            placeholder="Amount"
                                            onChange={(e) =>
                                              handleChanges(
                                                e,
                                                "mortgage",
                                                "mortgage"
                                              )
                                            }
                                            value={data?.mortgage?.mortgage}
                                            startDecorator={<div>$</div>}
                                            required
                                          />
                                          {(!errors.mortgageAmountValidate ||
                                            !errors.mortgageAmountZeroValidate) && (
                                            <FormHelperText error>
                                              <Typography
                                                variant="caption"
                                                color="error"
                                              >
                                                {!errors.mortgageAmountValidate
                                                  ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                                  : !errors.mortgageAmountZeroValidate
                                                  ? "Value can't be zero"
                                                  : ""}
                                              </Typography>
                                            </FormHelperText>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div>
                                  <Tooltip title="Add your banks related file">
                                    <div className="mt-2">
                                      <FormLabel>Supporting Document</FormLabel>
                                      <input
                                        style={{
                                          border: "solid 1px lightgray",
                                          borderLeft: "none",
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        type="file"
                                        name="myfile"
                                        id="exampleFile"
                                        onChange={(e) =>
                                          handleImageChange(e, 5)
                                        }
                                        accept=".pdf"
                                        multiple
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div
                                  className="bene-select mt-3"
                                  onClick={handleShowBeneficiary}
                                  style={{ cursor: "pointer" }}
                                >
                                  Select Your Beneficiary
                                </div>

                                <div className="address_input">
                                  <div
                                    style={{ width: "100%" }}
                                    className="mt-2"
                                  >
                                    <div>
                                      <FormLabel>Notes</FormLabel>
                                      <Textarea
                                        sx={{
                                          height: "182px",
                                          minHeight: "52px",
                                        }}
                                        placeholder="Notes"
                                        id="notes"
                                        onChange={(e) =>
                                          handleChanges(e, "notes", "vehicle")
                                        }
                                        value={data.vehicle.notes}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="text-center">
                              <Button
                                className="my-estate-clear-btn"
                                onClick={resetForm}
                                type="reset"
                                outline
                              >
                                Clear
                              </Button>
                              <Button
                                outline
                                type=""
                                className="my-estate-add-btn"
                              >
                                Add
                              </Button>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        <SharedBeneficiary
          handleTrustSave={handleTrustSave}
          assetData={data?.vehicle}
          trusts={trusts}
          handleChanges={handleChanges}
          sharedType={sharedType}
          handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
          estimatedTotalAmount={estimatedTotalAmount}
          typeBeneficiary={"normal"}
          sharedDetails={data?.sharedDetails}
        />
      )}
    </>
  );
}

export function InternationalAssetIncome() {
  const navigate = useNavigate();
  // new update
  let [form1, setForm1] = useState(true);
  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };
  // set Add data
  const [data, setData] = useState({
    income: {
      owner: "",

      incomeCaption: "",
      incomeAmount: "",
      businessSource: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "income",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        income: {
          ...prevData.income,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const ownerNames = [];

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  let secondaryUserName = "";

  // Check if secondary user exists
  if (secondaryUserDetails !== undefined) {
    secondaryUserName =
      secondaryUserDetails.firstName + " " + secondaryUserDetails.lastName;

    // Push both user names into the array
    ownerNames.push(primaryUserName, secondaryUserName, "Other");
  } else {
    // If secondary user doesn't exist, only push primary user name
    ownerNames.push(primaryUserName, "Other");
  }

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      income: {
        ...prevData.income,
        [field]: newValue,
      },
    }));
    setEstimatedTotalAmount(data.income.incomeAmount);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  // auto matic clear the data
  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      income: {
        owner: "",
        incomeCaption: "",
        incomeAmount: "",
        businessSource: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const activeincomeForm = (event) => {
    event.preventDefault();
    if (
      data.payCheck === "" ||
      data.businessSource === "" ||
      data.benificiary === ""
    ) {
      // console.log("Error log");
      toast.error("Please fill all required feilds.");
      return;
    }

    let token = "Bearer " + getToken();

    // console.log("Token : " + token);

    //create form data to send a file and remaining class data
    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
      console.log("this is file indexs", selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  // for add field pop
  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  // let [show1, setShow1] = useState(false);
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.income.incomeAmount);
    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    console.log("getBenificiaryName id : ", id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      console.log("IF condition");
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    console.log("foundBenificiary details : ", foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    // beneficiaryDetails.map(value)
    console.log("beneficiaryDetails data: ", data);
    // const length = data.sharedDetails.length;
    // data.sharedDetails[length] = beneficiaryDetails;
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div className="overlay1" style={{ transition: "500ms" }}>
                    <div className="property_form">
                      <Container
                        style={{ height: "auto", boxSizing: "border-box" }}
                      >
                        <Card color="outline">
                          <CardHeader>
                            <h2 className="form1-heading">
                              Business-Passive Income
                            </h2>
                            <div className="Close" onClick={toggle}>
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </CardHeader>
                          <CardBody>
                            <Form onSubmit={activeincomeForm}>
                              <div className="mt-2 ">
                                <div>
                                  <Tooltip title="Enter Caption for your assets">
                                    <div>
                                      <FormLabel>Assets Caption</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        placeholder="Enter asset caption"
                                        onChange={(e) =>
                                          handleChangesData(e, "assetCaption")
                                        }
                                        value={data1.assetCaption}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>

                              <div className="mt-2">
                                <div>
                                  <Tooltip title="Enter the Country of your assets">
                                    <div>
                                      <FormLabel required>
                                        Country Name
                                      </FormLabel>

                                      <Autocomplete
                                        placeholder="Choose a country"
                                        options={countries}
                                        autoHighlight
                                        getOptionLabel={(option) =>
                                          option.label
                                        }
                                        onChange={(e, newValue) =>
                                          handleChangesCountry(e, newValue)
                                        }
                                        value={
                                          countries.find(
                                            (country) =>
                                              country.label ===
                                              data1.countryName
                                          ) || null
                                        }
                                        required
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <div>
                                <Tooltip title="Select Owner">
                                  <div>
                                    <FormLabel required>Select Owner</FormLabel>

                                    <Select
                                      placeholder="Select Owner"
                                      value={ownerName}
                                      multiple
                                      onChange={(event, newValue) =>
                                        handleChange(event, newValue, "owner")
                                      }
                                      sx={{
                                        minWidth: "13rem",
                                      }}
                                      slotProps={{
                                        listbox: {
                                          sx: {
                                            width: "100%",
                                          },
                                        },
                                      }}
                                      required
                                    >
                                      {ownerNames.map((name) => (
                                        <Option key={name} value={name}>
                                          {name}
                                        </Option>
                                      ))}
                                    </Select>
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter Heading for Income">
                                  <div className="mt-2">
                                    <FormLabel>Income Heading</FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter income heading"
                                      value={data.income.incomeCaption}
                                      onChange={(e) =>
                                        handleChanges(e, "incomeCaption")
                                      }
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter your Income">
                                  <div className="mt-2">
                                    <FormLabel required>Income</FormLabel>
                                    <Input
                                      type="number"
                                      className="input_mui_joy"
                                      placeholder="Enter income"
                                      value={data.income.incomeAmount}
                                      onChange={(e) =>
                                        handleChanges(e, "incomeAmount")
                                      }
                                      onClick={() =>
                                        setIsTextFieldClicked(true)
                                      }
                                      startDecorator={
                                        isTextFieldClicked ? <div>$</div> : null
                                      }
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div>
                                <Tooltip title="Enter Source of your income">
                                  <div className="mt-2">
                                    <FormLabel required>
                                      Income Source
                                    </FormLabel>
                                    <Input
                                      className="input_mui_joy"
                                      placeholder="Enter income heading"
                                      value={data.income.businessSource}
                                      onChange={(e) =>
                                        handleChanges(e, "businessSource")
                                      }
                                      required
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div className="mt-3">
                                <Tooltip title="Add your income related file">
                                  <div>
                                    <label
                                      style={{
                                        display: "block",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Supporting Document<span></span>
                                    </label>
                                    <input
                                      style={{
                                        border: "solid 1px lightgray",
                                        borderLeft: "none",
                                        width: "100%",
                                        borderRadius: "5px",
                                      }}
                                      type="file"
                                      name="myfile"
                                      id="exampleFile"
                                      multiple
                                      accept=".pdf"
                                      onChange={(e) => handleImageChange(e)}
                                    />
                                  </div>
                                </Tooltip>
                              </div>

                              <div
                                className="bene-select mt-3"
                                onClick={handleShowBeneficiary}
                                style={{ cursor: "pointer" }}
                              >
                                Select Your Beneficiary
                              </div>

                              <div className="address_input">
                                <div style={{ width: "100%" }} className="mt-2">
                                  <div>
                                    <FormLabel>Notes</FormLabel>
                                    <Textarea
                                      sx={{
                                        height: "182px",
                                        minHeight: "52px",
                                      }}
                                      placeholder="Notes"
                                      id="notes"
                                      onChange={(e) =>
                                        handleChanges(e, "notes")
                                      }
                                      value={data.income.notes}
                                    />
                                  </div>
                                </div>
                              </div>
                              <Container className="text-center">
                                <Button
                                  // onClick={resetForm}
                                  color="warning"
                                  className="my-estate-add-btn"
                                  outline
                                >
                                  Clear
                                </Button>
                                <Button
                                  color="success"
                                  outline
                                  className="my-estate-add-btn"
                                >
                                  Add
                                </Button>
                              </Container>
                            </Form>
                          </CardBody>
                        </Card>
                      </Container>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}
      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export function InternationalAssetOtherAssets() {
  const navigate = useNavigate();
  let [form1, setForm1] = useState(true);
  const toggle = () => {
    setForm1(!form1);
    navigate("/user/my-estate/International_assets");
  };
  // set Add data
  const [data, setData] = useState({
    otherAsset: {
      owner: "",
      otherAssetListName: "",
      assetCaption: "",
      assetValue: "",
      assetName: "",
      notes: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [data1, setData1] = useState({
    assetCaption: "",
    assetType: "otherAsset",
    countryName: "",
  });

  const handleChangesData = (e, field) => {
    const newValue = e.target.value;
    setData1((prevData) => ({
      ...prevData,
      [field]: newValue,
    }));
  };

  const handleChangesCountry = (event, newValue) => {
    setData1((prevData) => ({
      ...prevData,
      countryName: newValue ? newValue.label : "",
    }));
  };

  // for owner field
  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        otherAsset: {
          ...prevData.otherAsset,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    setData((prevData) => ({
      ...prevData,
      otherAsset: {
        ...prevData.otherAsset,
        [field]: newValue,
      },
    }));
    // setEstimatedTotalAmount(data.realEstate.estPropertyVal);
    setEstimatedTotalAmount(data.otherAsset.assetValue);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const resetForm = () => {
    setIsTextFieldClicked(false);
    setData({
      otherAsset: {
        owner: "",
        otherAssetListName: "",
        assetCaption: "",
        assetValue: "",
        assetName: "",
        notes: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
  };

  // Set the form
  const OtherAssestForm = (event) => {
    event.preventDefault();
    let token = "Bearer " + getToken();

    //create form data to send a file and remaining class data
    const formData = new FormData();
    for (let i = 0; i < selectedImage.length; i++) {
      formData.append(`files`, selectedImage[i]);
      console.log("this is file indexs", selectedImage[i]);
    }
    formData.append("asset", JSON.stringify(data));
    formData.append("data", JSON.stringify(data1));

    addInternationalAsset(formData, token)
      .then((resp) => {
        toast.success("Data Added !!", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
        toggle();
      })
      .catch((error) => {});
  };

  const [otherAssets, setOtherAssets] = useState([{ name: "", notes: "" }]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const otherAssetss = [0, 1, 2, 3, 4];
  const handleAddColumn = () => {
    if (visibleColumnIndex < 4) {
      setOtherAssets([
        ...otherAssets,
        { name: "", notes: "", label: visibleColumnIndex + 1 },
      ]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveColumn = (indexToRemove) => {
    if (otherAssets.length > 0) {
      // Create a copy of the otherAssets array
      const updatedAssets = [...otherAssets];
      // Remove the last element
      updatedAssets.pop();
      // Update the state with the modified array
      setOtherAssets(updatedAssets);

      // Update visibleColumnIndex if necessary
      if (visibleColumnIndex > 0) {
        setVisibleColumnIndex(visibleColumnIndex - 1);
      }
    }
    setData((prevData) => {
      const updatedOtherAsset = { ...prevData.otherAsset };

      // Reset the value of the field corresponding to the removed column to an empty string
      for (let i = 1; i <= visibleColumnIndex + 1; i++) {
        const fieldKey = `otherAssets${i + 1}`;
        if (indexToRemove === i) {
          updatedOtherAsset[fieldKey] = ""; // Reset the field value to an empty string
        }
      }

      // Return the updated state with the modified otherAsset object
      return {
        ...prevData,
        otherAsset: updatedOtherAsset,
      };
    });
  };

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);

  const getBenificiarydata = () => {
    let userId = getUser().id;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [isTextFieldClicked, setIsTextFieldClicked] = useState(false);

  //
  // let [show1, setShow1] = useState(false);
  const [benevisible, setbeneVisible] = useState(false);
  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.otherAsset.assetValue);
    data.sharedDetails = [];
  };

  const handleReset = () => {
    setbeneVisible(false);
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };
    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);
  };

  const getBenificiaryName = (id) => {
    console.log("getBenificiaryName id : ", id);
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      console.log("IF condition");
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }
    console.log("foundBenificiary details : ", foundBenificiary);
    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    toast.success("Saved successfully!");
    setbeneVisible(false);
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    // beneficiaryDetails.map(value)
    console.log("beneficiaryDetails data: ", data);
    // const length = data.sharedDetails.length;
    // data.sharedDetails[length] = beneficiaryDetails;
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails]; // Create a copy of the sharedDetails array

    // Check if the index i is within the bounds of the array
    // if (i >= 0 && i < updatedSharedDetails.length) {
    // Create a new object to update the specific element at index i
    const updatedElement = {
      ...updatedSharedDetails[i], // Copy existing properties
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement; // Update the element at index i
    console.log("updatedSharedDetails[i] : ", updatedSharedDetails[i]);
    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails, // Update the sharedDetails in the state
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  useEffect(() => {
    getBenificiarydata();
  }, []);

  return (
    <>
      {form1 && (
        <>
          <div className="fade-in-element">
            <UserBase>
              <div className="mt-5">
                <SideBar>
                  <div className="overlay1" style={{ transition: "500ms" }}>
                    <div>
                      <Form onSubmit={OtherAssestForm} className="addform_body">
                        <container>
                          <div className="homepage jewelry_container">
                            <Card color="outline">
                              <CardHeader>
                                <h2 className="form1-heading">
                                  Miscellaneous Assets
                                </h2>
                                <div className="Close" onClick={toggle}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </div>
                              </CardHeader>
                              <CardBody className="jewelry_card_body">
                                <div>
                                  <table className="jewelry_table">
                                    <thead>
                                      <tr>
                                        <th
                                          style={{ width: "115px" }}
                                          className="otherAsset_th"
                                        >
                                          Caption
                                        </th>
                                        <th
                                          style={{ width: "115px" }}
                                          className="otherAsset_th"
                                        >
                                          Country*
                                        </th>
                                        <th
                                          style={{ width: "190px" }}
                                          className="otherAsset_th"
                                        >
                                          Select Owner*
                                        </th>
                                        <th
                                          className="otherAsset_th"
                                          style={{ width: "120px" }}
                                        >
                                          Asset Caption
                                        </th>
                                        <th
                                          className="otherAsset_th"
                                          style={{ width: "120px" }}
                                        >
                                          Asset Name*
                                        </th>
                                        <th
                                          className="otherAsset_th"
                                          style={{ width: "120px" }}
                                        >
                                          Asset Value*
                                        </th>
                                        <th className="otherAsset_150px">
                                          Document
                                        </th>
                                        <th style={{ width: "115px" }}>
                                          Beneficiary*
                                        </th>
                                        <th className="otherAsset_th">Notes</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>
                                          <Input
                                            className="crypto_select crypto_115px"
                                            placeholder="Enter caption"
                                            onChange={(e) =>
                                              handleChangesData(
                                                e,
                                                "assetCaption"
                                              )
                                            }
                                            value={data1.assetCaption}
                                          />
                                        </td>
                                        <td>
                                          <Autocomplete
                                            className="crypto_select crypto_115px"
                                            placeholder="Country"
                                            options={countries}
                                            autoHighlight
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            onChange={(e, newValue) =>
                                              handleChangesCountry(e, newValue)
                                            }
                                            value={
                                              countries.find(
                                                (country) =>
                                                  country.label ===
                                                  data1.countryName
                                              ) || null
                                            }
                                            required
                                          />
                                        </td>
                                        <td>
                                          <Select
                                            style={{ width: "190px" }}
                                            className="otherAsset_select"
                                            placeholder="Select Owner"
                                            value={ownerName}
                                            multiple
                                            onChange={(event, newValue) =>
                                              handleChange(
                                                event,
                                                newValue,
                                                "owner"
                                              )
                                            }
                                            sx={{ minWidth: "13rem" }}
                                            slotProps={{
                                              listbox: {
                                                sx: { width: "100%" },
                                              },
                                            }}
                                            required
                                          >
                                            {ownerNames.map((name) => (
                                              <Option key={name} value={name}>
                                                {name}
                                              </Option>
                                            ))}
                                          </Select>
                                        </td>

                                        <td>
                                          <Input
                                            className="otherAsset_input otherAsset_150px"
                                            placeholder="Asset Caption"
                                            onChange={(e) =>
                                              handleChanges(e, "assetCaption")
                                            }
                                            value={data.otherAsset.assetCaption}
                                          />
                                        </td>

                                        <td>
                                          <Input
                                            className="otherAsset_input otherAsset_150px"
                                            placeholder="Asset Name"
                                            onChange={(e) =>
                                              handleChanges(e, "assetName")
                                            }
                                            value={data.otherAsset.assetName}
                                            required
                                          />
                                        </td>

                                        <td>
                                          <Input
                                            className="otherAsset_input otherAsset_150px"
                                            placeholder="Asset Value"
                                            onChange={(e) =>
                                              handleChanges(e, "assetValue")
                                            }
                                            value={data.otherAsset.assetValue}
                                            startDecorator={<div>$</div>}
                                            required
                                          />
                                        </td>

                                        <td>
                                          <div className="jewelry_choose_files otherAsset_150px">
                                            <div>
                                              <label
                                                className="otherAsset_choose_file_label"
                                                htmlFor={`files`}
                                              >
                                                Choose Files
                                              </label>
                                            </div>
                                            {selectedImage?.length > 0 && (
                                              <div>
                                                <label className="jewelry_choose_images_file">
                                                  {selectedImage?.length === 1
                                                    ? `${
                                                        selectedImage[0].name
                                                          .length > 15
                                                          ? selectedImage[0].name.substring(
                                                              0,
                                                              4
                                                            ) +
                                                            "..." +
                                                            selectedImage[0].name.slice(
                                                              -6
                                                            )
                                                          : selectedImage[0]
                                                              .name
                                                      }`
                                                    : `${selectedImage?.length} files`}
                                                </label>
                                              </div>
                                            )}

                                            <input
                                              style={{
                                                display: "none",
                                              }}
                                              id={`files`}
                                              type="file"
                                              name="myfile"
                                              onChange={(e) =>
                                                handleImageChange(e, 5)
                                              }
                                              accept=".pdf"
                                              multiple
                                            />
                                          </div>
                                        </td>

                                        <td style={{ width: "115px" }}>
                                          <div
                                            style={{ width: "115px" }}
                                            className="bene-select beneficiary_btn"
                                            onClick={handleShowBeneficiary}
                                          >
                                            Beneficiary
                                          </div>
                                        </td>
                                        <td>
                                          <Textarea
                                            placeholder="Notes"
                                            id="notes"
                                            onChange={(e) =>
                                              handleChanges(e, "notes")
                                            }
                                            value={data.otherAsset.notes}
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </CardBody>
                              <CardFooter>
                                <div className="text-center">
                                  <Button
                                    className="my-estate-clear-btn"
                                    onClick={resetForm}
                                    type="reset"
                                    outline
                                  >
                                    Clear
                                  </Button>
                                  <Button
                                    outline
                                    type=""
                                    className="my-estate-add-btn"
                                  >
                                    Add
                                  </Button>
                                </div>
                              </CardFooter>
                            </Card>
                          </div>
                        </container>
                      </Form>
                    </div>
                  </div>
                </SideBar>
              </div>
            </UserBase>
          </div>
        </>
      )}

      {benevisible && (
        // beneShow &&
        <div className="popup">
          <div
            className="popup-content popup-content-download"
            style={{
              minWidth: "350px",
              width: "100%",
              maxWidth: "700px",
            }}
          >
            <div className="note_popup">
              <div className="note_popup_heading">
                <div className="share_property_heading">
                  <h2>Share Property </h2>
                </div>
                <div>
                  <button
                    className="note_popup_heading_close_btn"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </div>
              </div>
              <div className="share_property_est_value">
                <p>
                  Estimated Value:{" "}
                  <code style={{ color: "green", fontWeight: "bold" }}>
                    ${estimatedTotalAmount}
                  </code>
                </p>
              </div>
              <div className="BeneficiarySelect">
                <div className="BeneficiarySelectContainer">
                  <div className="BeneficiarySelectRow">
                    <div class="share_property_Type">
                      <p class="share_property_Type_paragraph">
                        Choose Distribution Type:{" "}
                      </p>
                      <select
                        value={distributionType}
                        onChange={handleDistributionTypeChange}
                        class="share_property_Type_select"
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="dollar">Dollar</option>
                      </select>
                    </div>
                    <div className="SelectContainer">
                      <select
                        className="Property-inputfiled"
                        onChange={handleBeneficiarySelection}
                        value=""
                        disabled={!distributionType}
                      >
                        <option value="" disabled hidden>
                          {distributionType
                            ? "Select Your Beneficiary Username"
                            : "Select Type First"}
                        </option>
                        {beneficiary.map((benif) => (
                          <option
                            key={benif.id}
                            value={benif.id}
                            disabled={selectedBeneficiaries.includes(benif.id)}
                          >
                            {/* {benif.username} */}
                            {`${benif.firstName} ${benif.lastName}`}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="share_beneficiary_main_card">
                    {selectedBeneficiaries.map(
                      (beneficiary) => (
                        console.log("this is  beneficiary ", beneficiary),
                        (
                          <div key={beneficiary} class="share_beneficiary_card">
                            <div>
                              <p className="share_beneficiary_card_para">
                                Beneficiary:{" "}
                                {getBenificiaryName({ beneficiary })}
                              </p>
                              {distributionType === "percentage" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Percentage"
                                  value={
                                    beneficiaryDetails[beneficiary]
                                      ?.percentage || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                              )}
                              {distributionType === "dollar" && (
                                <input
                                  type="text"
                                  className="share_ben_percentage"
                                  placeholder="Dollar Value"
                                  value={
                                    beneficiaryDetails[beneficiary]?.value || ""
                                  }
                                  onChange={(e) =>
                                    handleFieldChange(
                                      beneficiary,
                                      "value",
                                      e.target.value
                                    )
                                  }
                                />
                              )}

                              {distributionType && (
                                <p className="share_beneficiary_card_para">
                                  Distributed Value: $
                                  {distributionType === "percentage"
                                    ? calculateDistributedAmount(
                                        "percentage",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )
                                    : calculateDistributedAmount(
                                        "dollar",
                                        estimatedTotalAmount,
                                        beneficiaryDetails[beneficiary]
                                      )}
                                </p>
                              )}
                            </div>
                            <div
                              className="share_beneficiary_card_close"
                              onClick={() =>
                                handleBeneficiaryClose(beneficiary)
                              }
                            >
                              <FontAwesomeIcon icon={faXmark} />
                            </div>
                          </div>
                        )
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            {selectedBeneficiaries.length > 0 && (
              <button onClick={handleSave}>Save</button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
