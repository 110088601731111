import AOS from "aos";
import "aos/dist/aos.css";
import React, { lazy, Suspense, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Notification } from "../../components/alerting-visitors/notification";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import {
  getAttorney,
  getBeneficiary,
  getCombinedData,
  getLiabilitiesValue,
  getNetworth,
  getNetworthForGraph,
  getOwnerByUserId,
  getToken,
  getUser,
} from "../../services/user-service";

import {
  differenceInMonths,
  differenceInYears,
  format,
  parseISO,
} from "date-fns";
import { useEffect } from "react";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  Area,
  AreaChart,
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import AlertPopup from "../../components/alerting-visitors/alertpopup";
import { formatAmount } from "../../components/Common";
import AttorneyDetail from "../AttorneyDetail";
import CreateOwner from "../CreateOwner";
import "./UserDashboard.css";
import Loading from "../../components/Loading";
import CreateTrust from "../trust-page/CreateTrust";

const GenerateWill = lazy(() => import("../my-estate/GenerateWill"));
const JointAccount = lazy(() => import("../JointAccount"));

const BeneficiarySignup = lazy(() => import("../BeneficiarySignup"));

export default function Userdashboard() {
  const navigate = useNavigate();
  let userId = getUser().commonId;
  let userAccountType = getUser().accountType;

  const [show] = useState(true);

  // get combined data in single list
  const [combinedData, setCombinedData] = useState(null);
  const getCombinedDataFromServer = () => {
    getCombinedData(userId)
      .then((res) => {
        setCombinedData(res);
        openPopup();
      })
      .catch((err) => {});
  };

  // check user has attorney or not
  const [attorneyStringType, setAttorneyStringType] = useState(false);
  const getAttorneyData = () => {
    let userId = getUser().id;
    let token = "Bearer " + getToken();
    getAttorney(token, userId)
      .then((res) => {
        if (typeof res === "string") {
          setAttorneyStringType(true);
        }
      })
      .catch((err) => {});
  };

  // check user has benificiary or not
  const [beneficiary, setBeneficiary] = useState(false);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        if (res.length > 0) {
          setBeneficiary(true);
        }
      })
      .catch((err) => {});
  };

  // check user has benificiary or not
  const [isOwner, setIsOwner] = useState(false);
  const getOwnerdata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getOwnerByUserId(token, userId)
      .then((res) => {
        if (res.length > 0) {
          setIsOwner(true);
        }
      })
      .catch((err) => {});
  };

  const handleClick = (url) => {
    // Handle the click event here
    navigate("/user/my-estate/" + url);
  };

  const [addBeneficiary, setAddBeneficiary] = useState(false);

  const toggleBeneficiary = () => {
    setAddBeneficiary(!addBeneficiary);
  };

  const [addOwner, setAddOwner] = useState(false);

  const toggleAddOwner = () => {
    setAddOwner(!addOwner);
  };

  const [addTrust, setAddTrust] = useState(false);

  const toggleAddTrust = () => {
    setAddTrust(!addTrust);
  };

  // for joint account popup
  const [addJointPopup, setAddJointPopup] = useState(false);
  const toggleJointAccount = () => {
    setAddJointPopup(!addJointPopup);
  };

  const handleBack = () => {
    setAddBeneficiary(false);
    setAddJointPopup(false);
    setAttorneyPopup(false);
    setAddTrust(false);
    setAddOwner(false);
  };
  AOS.init();

  // popup component
  const [showPopup, setShowPopup] = useState(false);

  const openPopup = () => {
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const [attorneyPopup, setAttorneyPopup] = useState(false);

  const toggleAttorney = () => {
    setAttorneyPopup(!attorneyPopup);
  };

  useEffect(() => {
    getAttorneyData();
    getBenificiarydata();
    getOwnerdata();
  }, []);

  const [selectPieChart, setSelectPieChart] = useState(false);
  const [selectAreaChart, setSelectAresChart] = useState(true);
  const toggleSelectChart = (value) => {
    if (value === "pie") {
      setSelectPieChart(true);
      setSelectAresChart(false);
    } else {
      setSelectPieChart(false);
      setSelectAresChart(true);
    }
  };

  const [currDateTime, setCurrDateTime] = useState({
    date: new Date()
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-"),
    time: new Date().toLocaleTimeString(),
  });

  const [greeting, setGreeting] = useState("Good Morning");
  const user = getUser();
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const hours = now.getHours();

      // Determine the appropriate greeting based on the current hour
      if (hours < 12) {
        setGreeting("Good Morning");
      } else if (hours < 18) {
        setGreeting("Good Afternoon");
      } else {
        setGreeting("Good Evening");
      }

      // Update date and time
      setCurrDateTime({
        date: now
          .toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "short",
            year: "numeric",
          })
          .replace(/ /g, "-"),
        time: now.toLocaleTimeString(),
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const [networthData, setNetworthData] = useState(null);
  const [liabilitiesValue, setLiabilitiesValue] = useState(null);

  const data = [
    { title: "Real Estate", value: formatAmount(networthData?.RealEstate) },
    { title: "Banks", value: formatAmount(networthData?.Bank) },
    { title: "Investment", value: formatAmount(networthData?.Investment) },
    { title: "Insurances", value: formatAmount(networthData?.Insurance) },
    { title: "Vehicles (Beta)", value: formatAmount(networthData?.Vehicles) },
    {
      title: "Business-Passive Income (Beta)",
      value: formatAmount(networthData?.Income),
    },
    { title: "Bitcoin BTC", value: formatAmount(networthData?.Crypto) },
    { title: "Jewelry", value: formatAmount(networthData?.Jewelry) },
    {
      title: " Miscellaneous Assets",
      value: formatAmount(networthData?.OtherAsset),
    },
    {
      title: "International Assets (Beta)",
      value: formatAmount(networthData?.InternationalAsset),
    },
  ];

  const assets = [
    {
      url: "real-estate",
      image: `${process.env.PUBLIC_URL}/img/realestate.webp`,
    },
    {
      url: "banks",
      image: `${process.env.PUBLIC_URL}/img/bank.webp`,
    },
    {
      url: "investments",
      image: `${process.env.PUBLIC_URL}/img/investment.webp`,
    },
    {
      url: "insurances",
      image: `${process.env.PUBLIC_URL}/img/insurance.webp`,
    },
    {
      url: "vehicles",
      image: `${process.env.PUBLIC_URL}/img/vehicle1.webp`,
    },
    {
      url: "income",
      image: `${process.env.PUBLIC_URL}/img/income.webp`,
    },
    {
      url: "crypto",
      image: `${process.env.PUBLIC_URL}/img/crypto.webp`,
    },
    {
      url: "jewelry",
      image: `${process.env.PUBLIC_URL}/img/jewelry.webp`,
    },
    {
      url: "other-assests",
      image: `${process.env.PUBLIC_URL}/img/other_assets.webp`,
    },
    {
      url: "International_assets",
      image: `${process.env.PUBLIC_URL}/img/international_assests.jpg`,
    },
  ];

  // pie chart
  const assetData = [
    { name: "Real Estate", value: networthData?.RealEstate },
    { name: "Banks", value: networthData?.Bank },
    { name: "Investment", value: networthData?.Investment },
    { name: "Bitcoin BTC", value: networthData?.Crypto },
    { name: "Jewelry", value: networthData?.Jewelry },
    { name: "Insurance", value: networthData?.Insurance },
    { name: "Vehicle", value: networthData?.Vehicles },
    { name: "Business-Passive Income", value: networthData?.Income },
    { name: "Miscellaneous Asset", value: networthData?.OtherAsset },
    { name: "International Asset", value: networthData?.InternationalAsset },
  ];

  const allAssetsValuesZero = assetData.every((item) => item.value === 0);

  const COLORS = [
    "#6A89CC", // Soft Blue
    "#78E08F", // Soft Green
    "#F8C291", // Light Peach
    "#E77F67", // Muted Coral
    "#82CCDD", // Light Teal
    "#60A3BC", // Desaturated Cyan
    "#E55039", // Warm Red
    "#F6B93B", // Warm Yellow
    "#B8E994", // Soft Lime
    "#546DE5", // Medium Indigo
  ];

  const liabilitiesData = [
    { name: "Credit Card", value: liabilitiesValue?.CreditCard },
    { name: "Mortgage/Loan", value: liabilitiesValue?.Mortgage },
    { name: "Personal Loan", value: liabilitiesValue?.PersonalLoan },
  ];

  const allLiabilitiesValuesZero = liabilitiesData.every(
    (item) => item.value === 0
  );

  const LIGHT_COLORS = ["#90ee90", "#90c6ee", "#ee9090"];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  // get networth graph data from backend
  const [networthGraph, setNetworthGraph] = useState(null);
  const getNetworthForAreaChartGraph = () => {
    const userId = getUser().commonId;
    getNetworthForGraph(userId)
      .then((res) => {
        setNetworthGraph(res);
      })
      .catch((error) => {});
  };

  // Custom tooltip formatter
  const formatTooltip = (value) => formatAmount(value);
  const [timeFilter, setTimeFilter] = useState("1month");

  let maxValue = 0;
  let roundedMaxValue = 0;
  const getRoundedEvenValue = (value, roundTo) => {
    const roundedValue = Math.ceil(value / roundTo);
    return roundedValue % 2 === 0
      ? roundedValue * roundTo
      : roundedValue * roundTo + roundTo;
  };
  const filterDataByTimeRange = (data, range) => {
    maxValue = Math.max(...data.map((item) => item.netWorthValue));
    roundedMaxValue = getRoundedEvenValue(maxValue, 1000);
    const now = new Date();
    let startDate;

    switch (range) {
      case "1month":
        startDate = new Date();
        startDate.setMonth(now.getMonth() - 1);
        break;
      case "6months":
        startDate = new Date();
        startDate.setMonth(now.getMonth() - 6);
        break;
      case "1year":
        startDate = new Date();
        startDate.setFullYear(now.getFullYear() - 1);
        break;
      case "5years":
        startDate = new Date();
        startDate.setFullYear(now.getFullYear() - 5);
        break;
      case "all-time":
        return data;
      default:
        return data;
    }

    // return data.filter((item) => new Date(item.date) >= startDate);

    const filteredData = data.filter(
      (item) => new Date(item.date) >= startDate
    );

    const dataMap = filteredData.reduce((acc, item) => {
      const formattedDate = new Date(item.date).toISOString().split("T")[0];
      acc[formattedDate] = item;
      return acc;
    }, {});

    const dateRangeData = [];
    let latestNetWorth = 0;
    startDate.setDate(startDate.getDate() + 1);
    for (
      let date = new Date(startDate + 1);
      date <= now;
      date.setDate(date.getDate() + 1)
    ) {
      const formattedDate = date.toISOString().split("T")[0];
      if (dataMap[formattedDate]) {
        latestNetWorth = dataMap[formattedDate].netWorthValue;
        dateRangeData.push(dataMap[formattedDate]);
      } else {
        // Use the latest net worth value instead of defaulting to 0
        dateRangeData.push({
          date: formattedDate,
          netWorthValue: latestNetWorth,
        });
      }
    }

    return dateRangeData;
  };

  const handleTimeFilterChange = (range) => {
    setTimeFilter(range);
  };

  const filteredData = networthGraph
    ? filterDataByTimeRange(networthGraph, timeFilter)
    : [];

  // Function to format tick
  const tickFormatter = (dateString, filter) => {
    const date = parseISO(dateString);
    const now = new Date();
    const diffMonths = differenceInMonths(now, date);
    const diffYears = differenceInYears(now, date);

    switch (filter) {
      case "1month":
        // Show date every 7 days
        if (date.getDate() % 7 === 0) {
          return format(date, "MMM d");
        }
        return "";
      case "6months":
        // console.log(diffMonths);
        // Show only month name
        if (diffMonths <= 6) {
          return format(date, "MMM yyyy");
        }
        return "";
      case "1year":
        // Show month name for the past year
        if (diffYears <= 1) {
          return format(date, "MMM yyyy");
        }
        return "";
      case "5years":
      case "all-time":
        // Show year for the past 5 years or all time
        if (diffYears <= 5) {
          return format(date, "yyyy");
        }
        return "";
      default:
        return "";
    }
  };

  // Determine interval based on the filter
  const getInterval = () => {
    switch (timeFilter) {
      case "1month":
        return 0;
      case "6months":
      case "1year":
        return 30;
      case "5years":
        return 420;
      case "all-time":
        return 60;
      default:
        return 0;
    }
  };

  useEffect(() => {
    getNetworthForAreaChartGraph();
  }, []);

  // networth api start
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getNetworth(userId);
        setNetworthData(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    const fetchLiabilitiesValue = async () => {
      try {
        const data = await getLiabilitiesValue(userId);
        setLiabilitiesValue(data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    fetchLiabilitiesValue();
  }, [userId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // networth api end
  return (
    <>
      {showPopup && (
        <Suspense fallback={<Loading show={loading} />}>
          <GenerateWill combinedData={combinedData} closePopup={closePopup} />
        </Suspense>
      )}

      {!showPopup && (
        <div className={`your-component ${show ? "fade-in-element" : ""}`}>
          <UserBase>
            <div style={{ marginTop: "4rem" }} className="otherElement">
              <SideBar>
                <div
                  style={{
                    backgroundColor: "yellow",
                    padding: "3px 0 6px 0",
                  }}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    <Notification />
                  </Suspense>
                </div>

                {addBeneficiary && (
                  <div
                    className="beneficiary-popup"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  >
                    <div className="beneficiary-content">
                      {addBeneficiary && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <BeneficiarySignup handleBack={handleBack} />
                        </Suspense>
                      )}
                    </div>
                  </div>
                )}

                {addJointPopup && (
                  <div
                    className="beneficiary-popup"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  >
                    <div className="beneficiary-content">
                      {addJointPopup && (
                        <Suspense fallback={<div>Loading...</div>}>
                          <JointAccount userId={userId} onBack={handleBack} />
                        </Suspense>
                      )}
                    </div>
                  </div>
                )}

                {addOwner && (
                  <div
                    className="beneficiary-popup"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  >
                    <div className="beneficiary-content">
                      {addOwner && <CreateOwner onBack={handleBack} />}
                    </div>
                  </div>
                )}

                {addTrust && (
                  <div
                    className="beneficiary-popup"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  >
                    <div className="beneficiary-content">
                      {addTrust && <CreateTrust onBack={handleBack} />}
                    </div>
                  </div>
                )}

                {attorneyPopup && (
                  <div
                    className="beneficiary-popup"
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  >
                    <div className="beneficiary-content">
                      {attorneyPopup && (
                        <AttorneyDetail handleBack={handleBack} />
                      )}
                    </div>
                  </div>
                )}

                <div style={{ textAlign: "center" }} className="main">
                  <div className="Dashboard_main">
                    <div className="dashboard_left">
                      <div className="user_details_dash">
                        <div className="UserDetails">
                          <p className="Username_details">
                            {greeting},{" " + user.firstName}
                            <br />
                            Welcome to I
                            <span style={{ color: "#059212" }}>-</span>Chest.com
                          </p>
                          <p className="date_time_details">
                            Today {currDateTime.date} {currDateTime.time}
                          </p>
                        </div>
                        <div className="User_action_btns ">
                          <div className="user_dashbord_btn">
                            {beneficiary ? (
                              <div
                                className="dashboard_action_btn"
                                onClick={toggleBeneficiary}
                              >
                                Add Beneficiaries
                              </div>
                            ) : (
                              <div
                                className="dashboard_action_btn"
                                onClick={toggleBeneficiary}
                              >
                                Add Beneficiary
                              </div>
                            )}

                            <div
                              className="dashboard_action_btn"
                              onClick={toggleJointAccount}
                            >
                              Invite Secondary Account
                            </div>
                          </div>
                          <div className="user_dashbord_btn">
                            <div
                              className="dashboard_action_btn"
                              onClick={getCombinedDataFromServer}
                            >
                              Inventory Report
                            </div>
                            {/* {attorneyStringType && ( */}
                            <div
                              className="dashboard_action_btn"
                              onClick={toggleAttorney}
                            >
                              Invite Attorneys/Agents
                            </div>
                            {/* )} */}
                          </div>
                          <div className="user_dashbord_btn">
                            {isOwner ? (
                              <div
                                className="dashboard_action_btn"
                                onClick={toggleAddOwner}
                              >
                                Add Owners
                              </div>
                            ) : (
                              <div
                                className="dashboard_action_btn"
                                onClick={toggleAddOwner}
                              >
                                Add Owner
                              </div>
                            )}
                            {isOwner ? (
                              <div
                                className="dashboard_action_btn"
                                onClick={toggleAddTrust}
                              >
                                Add Trusts
                              </div>
                            ) : (
                              <div
                                className="dashboard_action_btn"
                                onClick={toggleAddTrust}
                              >
                                Add Trust
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="user_chart_dash_main">
                        <div className="chart_button">
                          <button
                            className={`chart_all_button ${
                              selectAreaChart ? "selected" : ""
                            }`}
                            onClick={() => toggleSelectChart("area")}
                          >
                            Total Net Worth
                          </button>
                          <button
                            className={`chart_all_button ${
                              selectPieChart ? "selected" : ""
                            }`}
                            onClick={() => toggleSelectChart("pie")}
                          >
                            Assets/Liabilities Value
                          </button>
                        </div>
                        <div className="user_charts">
                          {selectAreaChart ? (
                            <div className="user_chart_dash">
                              <div className="chat_filter_buttons">
                                <div className="Totalnetworth">
                                  <p>
                                    Approx Net Worth:{" "}
                                    <span className="networth_amount">
                                      {formatAmount(
                                        networthData?.TotalNetWorth
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <div>
                                  <button
                                    className="filter_button_style"
                                    onClick={() =>
                                      handleTimeFilterChange("1month")
                                    }
                                    style={{
                                      color:
                                        timeFilter === "1month"
                                          ? "blue"
                                          : "black",
                                    }}
                                  >
                                    1 Month
                                  </button>
                                  <button
                                    className="filter_button_style"
                                    onClick={() =>
                                      handleTimeFilterChange("6months")
                                    }
                                    style={{
                                      color:
                                        timeFilter === "6months"
                                          ? "blue"
                                          : "black",
                                    }}
                                  >
                                    6 Months
                                  </button>
                                  <button
                                    className="filter_button_style"
                                    onClick={() =>
                                      handleTimeFilterChange("1year")
                                    }
                                    style={{
                                      color:
                                        timeFilter === "1year"
                                          ? "blue"
                                          : "black",
                                    }}
                                  >
                                    1 Year
                                  </button>
                                  <button
                                    className="filter_button_style"
                                    onClick={() =>
                                      handleTimeFilterChange("5years")
                                    }
                                    style={{
                                      color:
                                        timeFilter === "5years"
                                          ? "blue"
                                          : "black",
                                    }}
                                  >
                                    5 Years
                                  </button>
                                  <button
                                    className="filter_button_style"
                                    onClick={() =>
                                      handleTimeFilterChange("all-time")
                                    }
                                    style={{
                                      color:
                                        timeFilter === "all-time"
                                          ? "blue"
                                          : "black",
                                    }}
                                  >
                                    All Time
                                  </button>
                                </div>
                              </div>
                              <div className="user_areachart">
                                <ResponsiveContainer width="100%" height="100%">
                                  <AreaChart
                                    data={filteredData}
                                    margin={{
                                      top: 10,
                                      right: 30,
                                      left: 0,
                                      bottom: 0,
                                    }}
                                  >
                                    <defs>
                                      <linearGradient
                                        id="colorNetworth"
                                        x1="0"
                                        y1="0"
                                        x2="0"
                                        y2="1"
                                      >
                                        <stop
                                          offset="5%"
                                          stopColor="#7286D3"
                                          stopOpacity={0.8}
                                        />
                                        <stop
                                          offset="95%"
                                          stopColor="#7286D3"
                                          stopOpacity={0}
                                        />
                                      </linearGradient>
                                    </defs>
                                    <XAxis
                                      dataKey="date"
                                      tickLine={false}
                                      tickFormatter={(tick) =>
                                        tickFormatter(tick, timeFilter)
                                      }
                                      interval={getInterval()}
                                    />
                                    <YAxis
                                      domain={[0, roundedMaxValue]}
                                      tickCount={5}
                                      ticks={[
                                        0,
                                        roundedMaxValue / 4,
                                        roundedMaxValue / 2,
                                        (roundedMaxValue * 3) / 4,
                                        roundedMaxValue,
                                      ]}
                                    />

                                    <Tooltip
                                      labelFormatter={(label) =>
                                        format(
                                          parseISO(label),
                                          "eeee, d MMM, yyyy"
                                        )
                                      }
                                      formatter={(value) => [
                                        `Networth: ${formatAmount(value)}`,
                                      ]}
                                    />
                                    <Area
                                      type="monotone"
                                      dataKey="netWorthValue"
                                      stroke="#2C74B3"
                                      fillOpacity={1}
                                      fill="url(#colorNetworth)"
                                    />
                                  </AreaChart>
                                </ResponsiveContainer>
                              </div>
                            </div>
                          ) : (
                            <div className="user_pie_chart_main">
                              <div className="user_pie_chart_with_assets">
                                <div className="user_pie_chart">
                                  {allAssetsValuesZero ? (
                                    <p style={{ fontWeight: "500" }}>
                                      No data to show
                                    </p>
                                  ) : (
                                    <>
                                      <ResponsiveContainer
                                        width="100%"
                                        height="100%"
                                      >
                                        <PieChart>
                                          <Pie
                                            data={assetData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            // label={renderCustomizedLabel}
                                            outerRadius={120}
                                            fill="#8884d8"
                                            dataKey="value"
                                          >
                                            {assetData.map((entry, index) => (
                                              <Cell
                                                key={`cell-${index}`}
                                                fill={
                                                  COLORS[index % COLORS.length]
                                                }
                                              />
                                            ))}
                                          </Pie>
                                          <Tooltip formatter={formatTooltip} />
                                        </PieChart>
                                      </ResponsiveContainer>

                                      <p style={{ fontWeight: "500" }}>
                                        All Assets Value
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div className="user_pie_chart_details">
                                  {assetData.map(
                                    (data, index) =>
                                      data.value > 0 && (
                                        <div
                                          className="pie_chart_details"
                                          key={index}
                                        >
                                          <div
                                            className="pie_chart_detail_box"
                                            style={{
                                              backgroundColor: COLORS[index],
                                            }}
                                          ></div>
                                          <p>{data.name}</p>
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>
                              <div className="user_pie_chart_with_liabilities">
                                <div className="user_pie_chart">
                                  {allLiabilitiesValuesZero ? (
                                    <p style={{ fontWeight: "500" }}>
                                      No data to show
                                    </p>
                                  ) : (
                                    <>
                                      <ResponsiveContainer
                                        width="100%"
                                        height="100%"
                                      >
                                        <PieChart>
                                          <Pie
                                            data={liabilitiesData}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            // label={renderCustomizedLabel}
                                            outerRadius={120}
                                            fill="#8884d8"
                                            dataKey="value"
                                          >
                                            {liabilitiesData.map(
                                              (entry, index) => (
                                                <Cell
                                                  key={`cell-${index}`}
                                                  fill={
                                                    LIGHT_COLORS[
                                                      index %
                                                        LIGHT_COLORS.length
                                                    ]
                                                  }
                                                />
                                              )
                                            )}
                                          </Pie>
                                          <Tooltip formatter={formatTooltip} />
                                        </PieChart>
                                      </ResponsiveContainer>
                                      <p style={{ fontWeight: "500" }}>
                                        All Liabilities Value
                                      </p>
                                    </>
                                  )}
                                </div>
                                <div
                                  className="user_pie_chart_details"
                                  style={{ gap: "20px" }}
                                >
                                  {liabilitiesData.map(
                                    (data, index) =>
                                      data.value > 0 && (
                                        <div className="pie_chart_details">
                                          <div
                                            className="pie_chart_detail_box"
                                            style={{
                                              backgroundColor:
                                                LIGHT_COLORS[index],
                                            }}
                                          ></div>
                                          <p>{data.name}</p>
                                        </div>
                                      )
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="dashboard_right">
                      <div className="Assets_count">
                        <p className="Assets_report_heading">
                          Add and View Assets
                        </p>
                        <div className="all_assets_report">
                          {data.map((item, index) => (
                            <div
                              key={index}
                              className="assets_card"
                              onClick={() => handleClick(assets[index].url)}
                            >
                              <img
                                className="assets_image"
                                src={assets[index].image}
                                alt={item.title}
                              />
                              <div className="assets_data">
                                <p>{item.title}</p>
                                <p>{item.value}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!beneficiary && attorneyStringType && (
                  <div>
                    <AlertPopup
                      userAccountType={userAccountType}
                      attorneyStringType={attorneyStringType}
                      beneficiary={beneficiary}
                      toggleBeneficiary={toggleBeneficiary}
                      toggleAttorney={toggleAttorney}
                    />
                  </div>
                )}
              </SideBar>
            </div>
          </UserBase>
        </div>
      )}
    </>
  );
}

const formatAreaChartTooltip = (value, name, props) => {
  const { payload } = props; // Get the payload containing date (label)
  const date = payload.name; // The `name` field in your data holds the date string
  const formattedDate = format(parseISO(date), "eeee, d MMM, yyyy");

  // Return formatted value and label
  return [formattedDate];
};
