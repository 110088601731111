import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import {
  currentUser,
  fetchNetWorth,
  generateToken,
  loginUser,
  setUser,
} from "../services/user-service";

import { faEye, faEyeSlash, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import "./LoginPage.css";
import Otppage from "./Otppage";
import Signup from "./Signup";

const Login = ({ onBack }) => {
  const navigate = useNavigate();
  const { login } = useAuth();

  const [loginDetail, setLoginDetail] = useState({
    username: "",
    password: "",
  });

  const handleChanges = (event, property) => {
    setLoginDetail({ ...loginDetail, [property]: event.target.value });
  };

  const [visible, setVisible] = useState(false);

  const [showOtp] = React.useState(false);
  const [usermail] = React.useState(null);

  const loginFormSubmit = (event) => {
    event.preventDefault();
    setVisible(false);
    if (
      loginDetail.username.trim() === "" ||
      loginDetail.password.trim() === ""
    ) {
      setVisible(true);
      // handleReset();
      return;
    }

    // call server api for sending data
    generateToken(loginDetail)
      .then((jwtTokenData) => {
        loginUser(jwtTokenData.token);
        const token = "Bearer " + jwtTokenData.token;

        currentUser(token).then((user) => {
          setUser(user);
          login();
          if (user.role !== "ATTORNEY") {
            fetchNetWorth(user.commonId);
          }

          if (user.role === "USER") {
            navigate("/user/dashboard");
          } else if (user.role === "ATTORNEY") {
            navigate("/attorney/dashboard");
          } else if (
            user.role === "BENEFICIARY" &&
            user.firstlevelapproval === "true"
          ) {
            navigate("/beneficiary/dashboard");
          }
        });
      })
      .catch((error) => {
        setVisible(true);
      });
  };

  const [showSignin, setShowSignin] = React.useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleBack = () => {
    setShowSignin(false);
  };
  return (
    <div className="login-popup">
      {showOtp && (
        <div className="login-popup">
          <div className="login-content">
            {showOtp && <Otppage usermail={usermail} />}
          </div>
        </div>
      )}
      {!showOtp && (
        <div>
          <div className="loginpage_login">
            <form class="form" onSubmit={loginFormSubmit}>
              <Alert className="text-center" color="danger" isOpen={visible}>
                Invalid username or password !!
              </Alert>

              <div className="login_heading">
                <p>Login</p>
                <div className="login_cancel_icon">
                  <FontAwesomeIcon
                    className="cancel_icon_login"
                    icon={faTimes}
                    onClick={onBack}
                  />
                </div>
              </div>
              <div class="flex-column">
                <label>Username </label>
              </div>
              <div class="inputForm">
                <input
                  type="text"
                  class="input"
                  placeholder="Enter your username"
                  id="username"
                  onChange={(e) => handleChanges(e, "username")}
                />
              </div>
              <div class="flex-column">
                <label>Password </label>
              </div>
              <div class="inputForm">
                <input
                  class="input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  id="password"
                  onChange={(e) => handleChanges(e, "password")}
                />
              </div>
              <div class="flex-row login_page_btns ">
                <div className="show_password_btn">
                  <div>
                    <input
                      type="checkbox"
                      style={{ marginRight: "10px" }}
                      onClick={handleTogglePassword}
                    />
                    <label>Show Password </label>
                  </div>
                </div>
                <div>
                  <span class="span">
                    <Link class="span" to="/forgot">
                      Forgot password?
                    </Link>
                  </span>
                </div>
              </div>
              <button class="button_submit_login">Sign In</button>
              {/* <p class="p line">Or With</p> */}
              {/* <div class="flex-row">
                <button class="btn google">
                  Google
                </button><button class="btn apple">
                  Apple
                </button>
              </div> */}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default Login;
