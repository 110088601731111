import { faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
} from "@mui/joy";
import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { validateEightDigitNumber } from "../../../components/Common";
import SharedBeneficiaryEdit from "../../../components/SharedBeneficiaryEdit";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import "../../../css/formPOPup.css";
import "../../../css/myestate_edit.css";
import {
  getSingleRealEstate,
  updateRealEstates,
} from "../../../services/RealEstate-service";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../../services/user-service";

function EditRealestate() {
  const { id } = useParams();

  // set Add data
  const [data, setData] = useState({
    realEstate: {
      owner: "",
      propertyCaption: "",
      propertyType: "",
      otherPropertyType: "",
      aptNumber: "",
      streetAddress: "",
      estPropertyVal: "",
      isPartOfNetWorth: "true",
      zipCode: "",
      country: "",
      propertyTax: "",
      city: "",
      state: "",
      trust: "",
      notes: "",
    },
    mortgages: [
      {
        mortgage: "",
        mortgageInstitution: "",
        accountNumber: "",
      },
    ],
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [errors, setErrors] = useState({
    estPropertyValValidate: true,
    propertyTaxValidate: true,
    estPropertyValZeroValidate: true,
    propertyTaxZeroValidate: true,
    mortgages: [],
  });

  const navigate = useNavigate();
  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState(null);
  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "propertyType") {
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          propertyType: newValue,
          otherPropertyType: newValue !== "Other" ? "" : "",
        },
      }));
    }
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  const [sharedType, setSharedType] = useState(null);
  const handleChangesRealEstate = (e, field) => {
    let newValue = e.target.value;
    const errorStates = { ...errors };
    if (field === "sharedType") {
      setSharedType(newValue);
      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          trust: "",
        },
      }));
    } else {
      if (field === "estPropertyVal" || field === "propertyTax") {
        if (field === "estPropertyVal") {
          errorStates.estPropertyValValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.estPropertyValZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
        } else {
          errorStates.propertyTaxValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.propertyTaxZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
        }
      } else if (field === "isPartOfNetWorth") {
        newValue =
          data.realEstate.isPartOfNetWorth === "false" ? "true" : "false";
      } else {
      }
      setData((prevData) => ({
        ...prevData,
        realEstate: {
          ...prevData.realEstate,
          [field]: newValue,
        },
      }));
    }
    setErrors(errorStates);
  };

  // add multiple mordgages
  const [mordgage, setMordgage] = useState([]);
  const [visibleColumnIndex, setVisibleColumnIndex] = useState(0);
  const mordgages = [0, 1, 2, 3, 4];
  const handleAddColumn = () => {
    if (visibleColumnIndex < 4) {
      setMordgage([...mordgage, { label: visibleColumnIndex + 1 }]);
      setVisibleColumnIndex(visibleColumnIndex + 1);
    }
  };

  const handleRemoveColumn = (indexToRemove) => {
    setMordgage(mordgage.filter((_, index) => index !== indexToRemove));
    setVisibleColumnIndex(visibleColumnIndex - 1);

    setData((prevData) => {
      const updatedMortgages = prevData.mortgages.map((mortgage, index) => {
        if (index === indexToRemove) {
          return { mortgage: "" }; // Reset the mortgage value to an empty string
        }
        return mortgage; // Return the original mortgage object for other indices
      });

      // Filter out mortgages with empty strings
      const filteredMortgages = updatedMortgages.filter(
        (mortgage) => mortgage.mortgage !== ""
      );

      // Return the new state with empty mortgages removed
      return {
        ...prevData,
        mortgages: filteredMortgages,
      };
    });
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  // post the form
  const AddForm = (event) => {
    event.preventDefault();

    // Check if there are any errors
    const hasErrors =
      Object.values(errors).includes(false) ||
      errors.mortgages.some((mortgage) => !mortgage.mortgageAmountValidate);

    if (!hasErrors) {
      let token = "Bearer " + getToken();
      const formData = new FormData();
      if (null != selectedImage) {
        for (let i = 0; i < selectedImage.length; i++) {
          formData.append(`files`, selectedImage[i]);
          console.log("this is file indexs", selectedImage[i]);
        }
      }
      formData.append("data", JSON.stringify(data));

      updateRealEstates(formData, token)
        .then((resp) => {
          toast.success("Updated Successfully!!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
          navigate("/user/my-estate/real-estate");
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };

  let findMortgagesLength = null;
  let [findMortLength, setFindMortLength] = useState(0);
  const [responseData, setResponseData] = useState(null);
  const [responseAssetData, setResponseAssetData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleRealEstate(token, id);

        setData({
          ...data,
          realEstate: res.realEstate,
          mortgages: res.mortgages,
          documents: res.documents,
          sharedDetails: res.sharedDetails,
        });
        setResponseData(res);
        setResponseAssetData(res?.realEstate);
        const ownerNameArray = res.realEstate.owner.split(", ");
        setOwnerName(ownerNameArray);

        if (res.realEstate.trust !== "" && res.realEstate.trust != null) {
          setSharedType("Trust");
        } else if (res.sharedDetails.length > 0) {
          setSharedType("Beneficiary");
        } else {
          setSharedType(null);
        }

        findMortgagesLength = [...res.mortgages];
        setIsChecked(findMortgagesLength.length > 0 ? true : false);
        setFindMortLength(findMortgagesLength.length);
        if (findMortgagesLength.length === 0) {
          setVisibleColumnIndex(findMortgagesLength.length);
        } else {
          setVisibleColumnIndex(findMortgagesLength.length - 1);
        }

        setEstimatedTotalAmount(res.realEstate.estPropertyVal);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
    getTrustsData();
  }, []);

  const validateZipCode = (value) => {
    return /^\d{5}$/.test(value); // Validates a 5-digit numeric input
  };

  useEffect(() => {
    const fetchDataFromAPI = () => {
      if (data.realEstate.zipCode.length === 5) {
        const apiUrl = `https://api.zippopotam.us/us/${data.realEstate.zipCode}`;
        axios
          .get(apiUrl)
          .then((res) => {
            // setLoading(false);
            if (res.data && res.data.places && res.data.places.length > 0) {
              const placeName = res.data.places[0]["place name"];

              setData((prevData) => ({
                ...prevData,
                realEstate: {
                  ...prevData.realEstate,
                  city: placeName,
                  country: res.data.country,
                  state: res.data.places[0]["state"],
                },
              }));
              console.log("Place Name: ", placeName);
            } else {
              console.log("No places found in the response.");
            }
          })
          .catch((error) => {
            // setLoading(false);
            console.log("Error fetching data from API:", error);
            // toast.error("Failed to fetch address data from API.");
          });
      } else if (data.realEstate.zipCode.length > 5) {
        setData((prevData) => ({
          ...prevData,
          realEstate: {
            ...prevData.realEstate,
            zipCode: "",
            city: "",
            country: "",
            state: "",
          },
        }));
      } else if (data.realEstate.zipCode.length <= 4) {
        setData((prevData) => ({
          ...prevData,
          realEstate: {
            ...prevData.realEstate,
            city: "",
            country: "",
            state: "",
          },
        }));
      }
    };

    fetchDataFromAPI();
  }, [data.realEstate.zipCode]);

  let to_name = getUser().firstName + " " + getUser().lastName;
  console.log("type of nam  is ::::::", typeof to_name);
  console.log(to_name);
  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  const handleChanges1 = (e, field, { index }) => {
    const { value } = e.target;
    const errorStates = { ...errors };

    if (!errorStates.mortgages[index]) {
      errorStates.mortgages[index] = {
        mortgageAmountValidate: true,
      };
    }

    setData((prevData) => {
      const updatedMortgages = [...prevData.mortgages];
      if (!updatedMortgages[index]) {
        updatedMortgages[index] = {};
      }
      updatedMortgages[index][field] = value;

      return {
        ...prevData,
        mortgages: updatedMortgages,
      };
    });

    if (field === "mortgage") {
      errorStates.mortgages[index].mortgageAmountValidate =
        value !== "" ? validateEightDigitNumber(value) : true;
    }

    setErrors(errorStates);
  };

  //$ on click

  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.realEstate.estPropertyVal);
  };
  const handleEditCloseBeneficiary = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div className="addform_ichest">
            <div className="addform_main">
              <div className="addform_heading">
                <h3 className="addform_heading_h1">
                  Edit Real Estate Properties
                </h3>
                <div className="addform_close">
                  <FontAwesomeIcon
                    icon={faXmark}
                    onClick={() => {
                      navigate("/user/my-estate/real-estate");
                    }}
                  />
                </div>
              </div>
              <div className="addform_body">
                <Form onSubmit={AddForm} className="addform_body">
                  <div className="addform_select_body">
                    <div className="addform_body_left">
                      <div>
                        <Tooltip title="Select Owner" className="mt-2">
                          <div>
                            <FormLabel required>Select Owner</FormLabel>

                            <Select
                              placeholder="Select Owner"
                              value={ownerName}
                              multiple
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "owner")
                              }
                              sx={{
                                minWidth: "13rem",
                              }}
                              slotProps={{
                                listbox: {
                                  sx: {
                                    width: "100%",
                                  },
                                },
                              }}
                              required
                            >
                              {ownerNames.map((name) => (
                                <Option key={name} value={name}>
                                  {name}
                                </Option>
                              ))}
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      <div>
                        <Tooltip title="Enter Heading For Property ">
                          <div className="mt-2">
                            <FormLabel>Property Heading</FormLabel>
                            <Input
                              className="input_mui_joy"
                              placeholder="Enter property heading"
                              value={data.realEstate.propertyCaption}
                              onChange={(e) =>
                                handleChangesRealEstate(e, "propertyCaption")
                              }
                            />
                          </div>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title="Select The Type Of Property">
                          <div className="mt-2">
                            <FormLabel required>Type Of Property</FormLabel>

                            <Select
                              value={data.realEstate.propertyType}
                              onChange={(event, newValue) =>
                                handleChange(event, newValue, "propertyType")
                              }
                              required
                            >
                              <Option value="">
                                Select Your Type Of Property
                              </Option>
                              <Option value="Residential">Residential</Option>
                              <Option value="Commercial">Commercial</Option>
                              <Option value="Industrial">Industrial</Option>
                              <Option value="Land">Land</Option>
                              <Option value="Other">Other</Option>
                            </Select>
                          </div>
                        </Tooltip>
                      </div>

                      {data.realEstate.propertyType === "Other" && (
                        <div className="mt-2">
                          <Tooltip title="Enter Your Other Property Type ">
                            <div>
                              <FormLabel>Other Property</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter Other Property"
                                id="otherprop"
                                onChange={(e) =>
                                  handleChangesRealEstate(
                                    e,
                                    "otherPropertyType"
                                  )
                                }
                                value={data.realEstate.otherPropertyType}
                              />
                            </div>
                          </Tooltip>
                        </div>
                      )}
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter a valid Zip-code ">
                              <div>
                                <FormLabel required>Zip Code</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  type="number"
                                  placeholder="Enter your Zip Code"
                                  id="zipCode"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "zipCode")
                                  }
                                  value={data.realEstate.zipCode}
                                  inputprops={{
                                    minLength: 5,
                                    maxLength: 5,
                                  }}
                                  error={
                                    !validateZipCode(data.realEstate.zipCode)
                                  }
                                  required
                                />
                                {!validateZipCode(data.realEstate.zipCode) && (
                                  <Typography variant="caption" color="error">
                                    Please enter a valid 5-digit Zip Code
                                  </Typography>
                                )}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Automatically populate based on the zip code">
                              <div style={{ width: "100%" }}>
                                <FormLabel>City/Town *</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="City/Town *"
                                  value={data.realEstate.city}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Automatically populate based on the zip code">
                              <div>
                                <FormLabel>State *</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="State *"
                                  value={data.realEstate.state}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Automatically populate based on the zip code">
                              <div>
                                <FormLabel>Country *</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Country *"
                                  value={data.realEstate.country}
                                  readOnly
                                  disabled
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Your Street Address ">
                              <div style={{ width: "100%" }}>
                                <FormLabel>Street Address</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Street Address *"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "streetAddress")
                                  }
                                  value={data.realEstate.streetAddress}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter Your Apartment Number ">
                              <div style={{ width: "100%" }}>
                                <FormLabel>Apartment</FormLabel>
                                <Input
                                  className="input_mui_joy"
                                  placeholder="Apartment"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "aptNumber")
                                  }
                                  value={data.realEstate.aptNumber}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your estimated Property Value">
                              <div style={{ width: "100%" }}>
                                <FormLabel>Property Value</FormLabel>
                                <Input
                                  type="number"
                                  className="input_mui_joy"
                                  placeholder="Estimated Property Value"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "estPropertyVal")
                                  }
                                  value={data.realEstate.estPropertyVal}
                                  startDecorator={<span>$</span>}
                                />
                                {(!errors.estPropertyValValidate ||
                                  !errors.estPropertyValZeroValidate) && (
                                  <FormHelperText error>
                                    <Typography variant="caption" color="error">
                                      {!errors.estPropertyValValidate
                                        ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                        : !errors.estPropertyValZeroValidate
                                        ? "Value can't be zero"
                                        : ""}
                                    </Typography>
                                  </FormHelperText>
                                )}
                              </div>
                            </Tooltip>
                          </div>
                        </div>

                        <div className="mt-2 addform_input_half">
                          <div>
                            <Tooltip title="Enter your Estimated Annual Property Tax">
                              <div style={{ width: "100%" }}>
                                <FormLabel>Annual Property Tax</FormLabel>
                                <Input
                                  type="number"
                                  className="input_mui_joy"
                                  placeholder="Estimated Annual Property Tax"
                                  onChange={(e) =>
                                    handleChangesRealEstate(e, "propertyTax")
                                  }
                                  value={data.realEstate.propertyTax}
                                  startDecorator={<span>$</span>}
                                />
                                {(!errors.propertyTaxValidate ||
                                  !errors.propertyTaxZeroValidate) && (
                                  <FormHelperText error>
                                    <Typography variant="caption" color="error">
                                      {!errors.propertyTaxValidate
                                        ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                        : !errors.propertyTaxZeroValidate
                                        ? "Value can't be zero"
                                        : ""}
                                    </Typography>
                                  </FormHelperText>
                                )}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 switch_changes_main">
                        <div className="switch_changes_base">
                          <div>
                            Do you want to include the asset value as part of
                            your net worth?
                          </div>
                          <div>
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    data.realEstate.isPartOfNetWorth ===
                                      "true" ||
                                    data.realEstate.isPartOfNetWorth === null
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    handleChangesRealEstate(
                                      e,
                                      "isPartOfNetWorth"
                                    )
                                  }
                                  color="primary"
                                  size="normal"
                                />
                              }
                              label={
                                data.realEstate.isPartOfNetWorth === "true" ||
                                data.realEstate.isPartOfNetWorth === null
                                  ? "Yes"
                                  : "No"
                              }
                              labelPlacement="end"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="addform_body_right">
                      <div
                        className="mt-2 form1-double"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          gap: "5px",
                        }}
                      >
                        <div
                          style={{
                            width: "49.5%",
                            display: "flex",
                            justifyContent: "flex-start",
                          }}
                        >
                          <div
                            className="txt"
                            style={{
                              alignSelf: "center",
                              marginRight: "10px",
                              flex: "1 0 100%",
                            }}
                          >
                            Do you have mortgages?
                          </div>
                          <div
                            className="switch"
                            style={{
                              flex: "1 1 100%",
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={isChecked}
                                  onChange={handleSwitchChange}
                                  color="primary"
                                  size="normal"
                                />
                              }
                              label={isChecked ? "Yes" : "No"}
                              labelPlacement="end"
                            />
                          </div>
                        </div>
                      </div>

                      {isChecked && (
                        <div>
                          {mordgages.map((index) => (
                            <div
                              key={index}
                              style={{
                                marginBottom: "10px",
                                display:
                                  index <= visibleColumnIndex
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <div>
                                <div>
                                  <Tooltip
                                    title={
                                      index === 0
                                        ? `Add your primary mortgage`
                                        : "Add your secondary mortgage"
                                    }
                                  >
                                    <div>
                                      {index === 0 ? (
                                        <FormLabel required>
                                          {`Primary Mortgage/Bank`}
                                        </FormLabel>
                                      ) : (
                                        <FormLabel required>
                                          {`Secondary Number/Bank`}
                                        </FormLabel>
                                      )}

                                      <Input
                                        className="input_mui_joy"
                                        placeholder={
                                          index === 0
                                            ? `Primary Mortgage`
                                            : "Secondary Number"
                                        }
                                        id={`mortgageInstitution${index + 1}`}
                                        required={visibleColumnIndex >= index}
                                        onChange={(e) =>
                                          handleChanges1(
                                            e,
                                            "mortgageInstitution",
                                            {
                                              index,
                                            }
                                          )
                                        }
                                        value={
                                          data.mortgages[index]
                                            ? data.mortgages[index]
                                                .mortgageInstitution
                                            : ""
                                        }
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="address_input">
                                <div className="mt-2 addform_input_half">
                                  <Tooltip
                                    title={`Add your account number ${
                                      index + 1
                                    }`}
                                  >
                                    <div style={{ width: "100%" }}>
                                      <FormLabel required>
                                        {`Account Number ${index + 1}`}
                                      </FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        type="number"
                                        id={`accountNumber${index + 1}`}
                                        placeholder={`Account Number ${
                                          index + 1
                                        }`}
                                        onChange={(e) =>
                                          handleChanges1(e, "accountNumber", {
                                            index,
                                          })
                                        }
                                        value={
                                          data.mortgages[index]
                                            ? data.mortgages[index]
                                                .accountNumber
                                            : ""
                                        }
                                        required={visibleColumnIndex >= index}
                                      />
                                    </div>
                                  </Tooltip>
                                </div>
                                <div className="mt-2 addform_input_half">
                                  <Tooltip title={`Add your amount`}>
                                    <div>
                                      <FormLabel required>{`Amount`}</FormLabel>
                                      <Input
                                        className="input_mui_joy"
                                        type="number"
                                        placeholder="Amount"
                                        id={`mortgage${index + 1}`}
                                        required={visibleColumnIndex >= index}
                                        onChange={(e) =>
                                          handleChanges1(e, "mortgage", {
                                            index,
                                          })
                                        }
                                        value={
                                          data.mortgages[index]
                                            ? data.mortgages[index].mortgage
                                            : ""
                                        }
                                        startDecorator={<div>$</div>}
                                      />
                                      {errors.mortgages.length > index &&
                                        !errors?.mortgages[index]
                                          ?.mortgageAmountValidate && (
                                          <FormHelperText error>
                                            <Typography
                                              variant="caption"
                                              color="error"
                                            >
                                              Please enter a value up to 8
                                              digits (maximum: $99,999,999).
                                            </Typography>
                                          </FormHelperText>
                                        )}
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              {index !== 0 && (
                                <div style={{ marginTop: "4px" }}>
                                  <Button
                                    style={{
                                      height: "30px",
                                      width: "30px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      borderRadius: "50%",
                                      backgroundColor: "rgb(255, 74, 74)",
                                      border: "none",
                                    }}
                                    onClick={() => handleRemoveColumn(index)}
                                  >
                                    <FontAwesomeIcon icon={faMinus} />
                                  </Button>
                                </div>
                              )}
                            </div>
                          ))}
                          {visibleColumnIndex < 4 && (
                            <div style={{ marginTop: "2px" }}>
                              <Button
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  backgroundColor: "#4aafff",
                                  border: "none",
                                }}
                                onClick={handleAddColumn}
                              >
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </div>
                          )}
                        </div>
                      )}

                      <div className="address_input">
                        <div className="mt-2 addform_input_half">
                          <div
                            className="bene-select mt-3"
                            onClick={handleShowBeneficiary}
                            style={{ cursor: "pointer" }}
                          >
                            Select Beneficiary
                          </div>
                        </div>
                        <div className="mt-2 addform_input_half">
                          <div style={{ width: "100%" }}>
                            <Tooltip title="Upload your property related file it should be under 400 kb">
                              <div>
                                <FormLabel>Supporting Document</FormLabel>
                                <input
                                  style={{
                                    border: "solid 1px lightgray",
                                    borderLeft: "none",
                                    width: "100%",
                                    borderRadius: "2px",
                                  }}
                                  type="file"
                                  name="myfile"
                                  id="exampleFile"
                                  onChange={handleImageChange}
                                  accept=".pdf"
                                  multiple
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mt-2 form1-double"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          justifyContent: "space-between",
                          marginTop: "-12px",
                        }}
                      ></div>

                      <div className="address_input">
                        <div style={{ width: "100%" }} className="mt-2">
                          <FormLabel>Notes</FormLabel>
                          <Textarea
                            sx={{ height: "182px", minHeight: "52px" }}
                            placeholder="Notes"
                            id="notes"
                            onChange={(e) =>
                              handleChangesRealEstate(e, "notes")
                            }
                            value={data.realEstate.notes}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button outline type="" className="my-estate-add-btn">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        {benevisible && (
          <SharedBeneficiaryEdit
            responseData={responseData}
            responseAssetData={responseAssetData}
            handleTrustSave={handleTrustSave}
            assetData={data?.realEstate}
            trusts={trusts}
            handleChanges={handleChangesRealEstate}
            sharedType={sharedType}
            handleCloseBeneficiaryPopup={handleEditCloseBeneficiary}
            estimatedTotalAmount={estimatedTotalAmount}
            typeBeneficiary={"normal"}
            sharedDetails={data?.sharedDetails}
          />
        )}
      </SideBar>
    </UserBase>
  );
}

export default EditRealestate;
