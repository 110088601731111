import {
  faDownload,
  faShieldHalved,
  faTrash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
} from "@mui/joy";
import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
} from "reactstrap";
import {
  formatAmount,
  validateEightDigitNumber,
} from "../../components/Common";
import SharedBeneficiary from "../../components/SharedBeneficiary";
import ShowSharedBeneficiary from "../../components/ShowSharedBeneficiary";
import SideBar from "../../components/sidebar/Sidebar";
import UserBase from "../../components/user/UserBase";
import "../../css/formPOPup.css";
import "../../css/myestare.css";
import {
  deleteInsurance,
  getInsurance,
  insurance,
} from "../../services/InsuranceService";
import {
  deleteSingleProperty,
  downloadDocument1,
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../services/user-service";
import Deletebutton from "./Deletebutton";
import UpdateButton from "./UpdateButton";

function LifeInsurance() {
  // set Add data
  const [data, setData] = useState({
    insurance: {
      owner: "",
      insuranceCaption: "",
      insuranceAmount: "",
      insuranceProvider: "",
      details: "",
      trust: "",
      isPartOfNetWorth: "true",
      notes: "",
    },
    deathBenefitsModel: {
      deathBenefits: {
        insuranceAmount: "",
        trust: "",
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [errors, setErrors] = useState({
    insuranceAmountValidate: true,
    deathInsuranceAmountValidate: true,
    insuranceAmountZeroValidate: true,
    deathInsuranceAmountZeroValidate: true,
  });

  const [ownerName, setOwnerName] = useState([]);
  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        insurance: {
          ...prevData.insurance,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked);
  };

  const resetSharedDetails = [
    {
      distributedType: "",
      distributedValue: "",
      distributedAmount: "",
      beneficiaryId: "",
    },
  ];
  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    let newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        insurance: {
          ...prevData.insurance,
          trust: "",
        },
      }));
    } else if (field === "deathBenefitsSharedType") {
      setSharedType(newValue);

      setData((prevData) => {
        return {
          ...prevData,
          deathBenefitsModel: {
            ...prevData.deathBenefitsModel,
            sharedDetails: resetSharedDetails,
          },
        };
      });

      setData((prevData) => ({
        ...prevData,
        deathBenefitsModel: {
          ...prevData.deathBenefitsModel,
          deathBenefits: {
            ...prevData.deathBenefitsModel?.deathBenefits,
            trust: "", // Update death benefits insurance amount
          },
        },
      }));
    } else {
      setData((prevData) => {
        if (field === "insuranceAmount") {
          errorStates.insuranceAmountValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.insuranceAmountZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
          return {
            ...prevData,
            insurance: {
              ...prevData.insurance,
              insuranceAmount: newValue, // Update the field dynamically
            },
          };
        } else if (field === "deathBenefitsInsuranceAmount") {
          errorStates.deathInsuranceAmountValidate =
            newValue !== "" ? validateEightDigitNumber(newValue) : true;
          errorStates.deathInsuranceAmountZeroValidate =
            newValue !== "" ? Number(newValue) > 0 : true;
          return {
            ...prevData,
            deathBenefitsModel: {
              ...prevData.deathBenefitsModel,
              deathBenefits: {
                ...prevData.deathBenefitsModel?.deathBenefits,
                insuranceAmount: newValue, // Update death benefits insurance amount
              },
            },
          };
        } else if (field === "deathBenefitsTrust") {
          return {
            ...prevData,
            deathBenefitsModel: {
              ...prevData.deathBenefitsModel,
              deathBenefits: {
                ...prevData.deathBenefitsModel?.deathBenefits,
                trust: newValue, // Update death benefits insurance amount
              },
            },
          };
        } else {
          if (field === "isPartOfNetWorth") {
            newValue =
              data.insurance.isPartOfNetWorth === "false" ? "true" : "false";
          }
          return {
            ...prevData,
            insurance: {
              ...prevData.insurance,
              [field]: newValue, // Update the field dynamically
            },
          };
        }
      });
    }
    setErrors(errorStates);
  };

  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });
        setSelectedImage(selectedFilesArray);
      } else {
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = "";
      }
    }
  };

  const resetForm = () => {
    setData({
      insurance: {
        insuranceCaption: "",
        insuranceAmount: "",
        insuranceProvider: "",
        details: "",
        trust: "",
        isPartOfNetWorth: "true",
        notes: "",
      },
      deathBenefitsModel: {
        deathBenefits: {
          insuranceAmount: "",
          trust: "",
        },
        sharedDetails: [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ],
      },
      sharedDetails: [
        {
          distributedType: "",
          distributedValue: "",
          distributedAmount: "",
          beneficiaryId: "",
        },
      ],
    });
    setOwnerName([]);
    setIsChecked(false);
    setIsDisableNormal(false);
    setIsDisableDeathBenefits(false);
    setErrors({
      insuranceAmountValidate: true,
      deathInsuranceAmountValidate: true,
      insuranceAmountZeroValidate: true,
      deathInsuranceAmountZeroValidate: true,
    });
  };

  const lifeForm = (event) => {
    event.preventDefault();
    const hasErrors = Object.values(errors).includes(false);
    if (!hasErrors) {
      let token = "Bearer " + getToken();
      const formData = new FormData();
      for (let i = 0; i < selectedImage.length; i++) {
        formData.append(`files`, selectedImage[i]);
      }
      formData.append("data", JSON.stringify(data));

      insurance(formData, token)
        .then((resp) => {
          AddCard();
          toast.success("Data Added !!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
          toggle();
        })
        .catch((error) => {});
    }
  };

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const [currentIdType, setCurrentIdType] = useState("");
  const removeInsurance = (id, idType) => {
    setShowConfirmation(true);
    setCurrentId(id);
    setCurrentIdType(idType);
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleRemove = (params) => {
    setShowConfirmation(false);
    const { Id, idType } = params;
    if (idType === "insuranceId") {
      deleteInsurance(Id)
        .then((res) => {
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          AddCard();
          setShow1(false);
        })
        .catch((error) => {});
    } else {
      deleteSingleProperty(Id)
        .then(() => {
          fetchNetWorth(primaryUserDetails.commonId);
          setBeneficiaryVisible(!beneficiaryVisible);
          setShow1(false);
          AddCard();
          toast.success("Deleted successfully...", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        })
        .catch(() => {});
    }
  };

  const handleDownload = (id, fileName) => {
    let myarry = fileName.split(".");
    downloadDocument1(id)
      .then((response) => {
        const downloadUrl = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `${myarry[0]}.${myarry[1]}`;
        link.click();
        URL.revokeObjectURL(downloadUrl);
      })
      .catch((error) => {});
  };

  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");

  const handleOpenPopup = (note) => {
    setSelectedNote(note);
    setPopupVisible(true);
  };

  const [popupVisibleDownlaod, setPopupVisibleDownlaod] = useState(false);
  const [selectedDownlaod, setSelectDownload] = useState("");

  const handleShowDownlaod = (showDetail) => {
    setPopupVisibleDownlaod(true);
    setSelectDownload(showDetail);
  };

  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(true);
  }, []);

  let [form1, setForm1] = useState(false);

  const toggle = () => {
    resetForm();
    setForm1(!form1);
  };

  let [card, setCard] = useState([]);
  let [showDetail, setShowDetail] = useState([]);
  let [show1, setShow1] = useState(false);

  const AddCard = () => {
    let userId = getUser().commonId;
    let token = "Bearer " + getToken();
    getInsurance(token, userId)
      .then((res) => {
        setCard(res);
      })
      .catch((error) => {
        setCard([]);
        console.error(error); // Changed to console.error for better visibility of errors
      });
  };

  // showing the details of cards like popup
  const Showdetails = (obj) => {
    // const arrayFromObject = Object.keys(obj).map(key => obj[key]);
    setShowDetail(obj);
    setShow1(true);
  };

  useEffect(() => {
    AddCard();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  //
  // let [show1, setShow1] = useState(false);
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);
  const [beneficiaryVisible, setBeneficiaryVisible] = useState(false);
  const [typeBeneficiary, setTypeBeneficiary] = useState(null);

  const handleShowBeneficiary = (event, beneficiaryType) => {
    if (beneficiaryType === "death-benefits") {
      setTypeBeneficiary(beneficiaryType);
      setEstimatedTotalAmount(
        data.deathBenefitsModel?.deathBenefits?.insuranceAmount
      );
    } else {
      setTypeBeneficiary(beneficiaryType);
      setEstimatedTotalAmount(data.insurance?.insuranceAmount);
    }
    setbeneVisible(true);
    setShow1(false);
  };

  const [isDisabledNormal, setIsDisableNormal] = useState(false);
  const [isDisabledDeathBenefits, setIsDisableDeathBenefits] = useState(false);

  const handleCloseBeneficiaryPopup = (params) => {
    const { name } = params;

    if (typeBeneficiary === "normal") {
      if (name !== undefined) {
        setData((prevData) => ({
          ...prevData,
          sharedDetails: name,
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          sharedDetails: resetSharedDetails,
        }));
      }

      if (name !== undefined && name[0]?.distributedType !== "") {
        setIsDisableNormal(true);
      }
    } else {
      if (name !== undefined) {
        setData((prevData) => ({
          ...prevData,
          deathBenefitsModel: {
            ...prevData.deathBenefitsModel,
            sharedDetails: name,
          },
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          deathBenefitsModel: {
            ...prevData.deathBenefitsModel,
            sharedDetails: resetSharedDetails,
          },
        }));
      }

      if (name !== undefined && name[0]?.distributedType !== "") {
        setIsDisableDeathBenefits(true);
      }
    }
    setbeneVisible(false);
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found"; // Or handle the case where beneficiary with the given ID isn't found
    }
  };

  const handleOpenBeneficiary = (beneficiaryType) => {
    setBeneficiaryVisible(true);
    beneficiaryType === "normal"
      ? setTypeBeneficiary(beneficiaryType)
      : setTypeBeneficiary("death-benefits");
  };

  const handleCloseBeneficiary = () => {
    setBeneficiaryVisible(false);
  };

  const handleTrustSave = () => {
    setbeneVisible(false);

    if (typeBeneficiary === "normal" && data?.insurance?.trust !== "") {
      setIsDisableNormal(true);
    }
    if (
      typeBeneficiary !== "normal" &&
      data?.deathBenefitsModel?.deathBenefits?.trust !== ""
    ) {
      setIsDisableDeathBenefits(true);
    }
  };

  return (
    <div className={`your-component ${show ? "fade-in-element" : ""}`}>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          {primaryUserDetails.accountType === "secondary" &&
          secondaryUserDetails.accountType === "primary" &&
          secondaryUserDetails.isSecondaryUserEditable === "false" ? (
            ""
          ) : (
            <div className="addme">
              <div className="addme_inner">
                <button onClick={() => toggle()} style={{ width: "auto" }}>
                  Add New Insurance
                </button>
              </div>
            </div>
          )}

          <div className="propCard">
            <div className="propCard-card">
              {card.map((entity) => (
                <div className="propCard-card-body" key={entity.insurance.id}>
                  <div className="propCard_card_status">
                    <p
                      className={`propCard_card_status_text ${
                        (entity.sharedDetails.length > 0 &&
                          entity.deathBenefitsModel.sharedDetails.length > 0) ||
                        (entity.insurance.trust &&
                          entity.insurance.trust !== "" &&
                          entity.insurance.trust !== null)
                          ? "completed"
                          : "incomplete"
                      }`}
                    >
                      {(entity.sharedDetails.length > 0 &&
                        entity.deathBenefitsModel.sharedDetails.length > 0) ||
                      (entity.insurance.trust &&
                        entity.insurance.trust !== "" &&
                        entity.insurance.trust !== null)
                        ? "completed"
                        : "incomplete"}
                    </p>
                  </div>
                  <h5 className="propCard-card-title">
                    {entity.insurance.insuranceProvider}
                  </h5>
                  <p className="propCard-card-text">
                    {" "}
                    {entity.insurance.details}
                  </p>
                  <div className="propCard-btn">
                    <p className=" viewDetails">
                      <button onClick={() => Showdetails(entity)}>
                        View Details
                      </button>
                    </p>
                  </div>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              ))}
            </div>
          </div>

          {form1 && (
            <div className="overlay1" style={{ transition: "500ms" }}>
              <div className="property_form">
                <Container>
                  <Card color="" outline>
                    <CardHeader>
                      <h3 className="form1-heading">Life Insurance</h3>
                      <div className="Close" onClick={toggle}>
                        <FontAwesomeIcon icon={faXmark} />
                      </div>
                    </CardHeader>
                    <CardBody>
                      <Form onSubmit={lifeForm}>
                        <div>
                          <Tooltip title="Select Owner">
                            <div>
                              <FormLabel required>Select Owner</FormLabel>

                              <Select
                                placeholder="Select Owner"
                                value={ownerName}
                                multiple
                                onChange={(event, newValue) =>
                                  handleChange(event, newValue, "owner")
                                }
                                sx={{
                                  minWidth: "13rem",
                                }}
                                slotProps={{
                                  listbox: {
                                    sx: {
                                      width: "100%",
                                    },
                                  },
                                }}
                                required
                              >
                                {ownerNames.map((name) => (
                                  <Option key={name} value={name}>
                                    {name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </Tooltip>
                        </div>

                        <div>
                          <Tooltip title="Enter Heading for Insurance">
                            <div className="mt-2">
                              <FormLabel>Insurance Heading</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter insurance heading"
                                onChange={(e) =>
                                  handleChanges(e, "insuranceCaption")
                                }
                                value={data.insurance.insuranceCaption}
                              />
                            </div>
                          </Tooltip>
                        </div>

                        <div>
                          <Tooltip title="Enter Heading for Insurance">
                            <div className="mt-2">
                              <FormLabel required>Insurance Provider</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter insurance provider"
                                onChange={(e) =>
                                  handleChanges(e, "insuranceProvider")
                                }
                                value={data.insurance.insuranceProvider}
                                required
                              />
                            </div>
                          </Tooltip>
                        </div>

                        <div>
                          <Tooltip title="Enter The Name Of Your Insurance ">
                            <div className="mt-2">
                              <FormLabel required>Details</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter details"
                                onChange={(e) => handleChanges(e, "details")}
                                value={data.insurance.details}
                                required
                              />
                            </div>
                          </Tooltip>
                        </div>

                        <h4 className="mt-2">Death Benefits</h4>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div className="address_input">
                            <div className="addform_input_half">
                              <div>
                                <Tooltip title="Enter your Insurance Amount">
                                  <div style={{ marginTop: "3px" }}>
                                    <FormLabel required>
                                      Insurance Amount
                                    </FormLabel>
                                    <Input
                                      type="number"
                                      className="input_mui_joy"
                                      placeholder="Enter insurance amount"
                                      onChange={(e) =>
                                        handleChanges(
                                          e,
                                          "deathBenefitsInsuranceAmount"
                                        )
                                      }
                                      value={
                                        data.deathBenefitsModel?.deathBenefits
                                          ?.insuranceAmount
                                      }
                                      startDecorator={<div>$</div>}
                                      required
                                      disabled={isDisabledDeathBenefits}
                                    />
                                    {(!errors.deathInsuranceAmountValidate ||
                                      !errors.deathInsuranceAmountZeroValidate) && (
                                      <FormHelperText error>
                                        <Typography
                                          variant="caption"
                                          color="error"
                                        >
                                          {!errors.deathInsuranceAmountValidate
                                            ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                            : !errors.deathInsuranceAmountZeroValidate
                                            ? "Value can't be zero"
                                            : ""}
                                        </Typography>
                                      </FormHelperText>
                                    )}
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                            <div className="mt-2 addform_input_half">
                              <div
                                className={`bene-select mt-3 `}
                                onClick={
                                  data.deathBenefitsModel?.deathBenefits
                                    ?.insuranceAmount === "" ||
                                  Number(
                                    data.deathBenefitsModel?.deathBenefits
                                      ?.insuranceAmount
                                  ) === 0 ||
                                  isDisabledDeathBenefits
                                    ? null
                                    : (event) =>
                                        handleShowBeneficiary(
                                          event,
                                          "death-benefits"
                                        )
                                }
                                style={{
                                  cursor:
                                    data.deathBenefitsModel?.deathBenefits
                                      ?.insuranceAmount === "" ||
                                    Number(
                                      data.deathBenefitsModel?.deathBenefits
                                        ?.insuranceAmount
                                    ) === 0 ||
                                    isDisabledDeathBenefits
                                      ? "not-allowed"
                                      : "pointer",
                                  opacity:
                                    data.deathBenefitsModel?.deathBenefits
                                      ?.insuranceAmount === "" ||
                                    Number(
                                      data.deathBenefitsModel?.deathBenefits
                                        ?.insuranceAmount
                                    ) === 0 ||
                                    isDisabledDeathBenefits
                                      ? 0.6
                                      : 1,
                                }}
                              >
                                Select Your Beneficiary
                              </div>
                            </div>
                          </div>
                          <FormHelperText error>
                            <Typography variant="caption" color="error">
                              Note: Please recheck "Insurance Amount" before
                              selecting your beneficiary.
                            </Typography>
                          </FormHelperText>
                        </div>

                        <div className="mt-2 switch_changes_main">
                          <div className="switch_changes_base">
                            <div>
                              Do you have current cash value of the Insurance?
                            </div>
                            <div>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={isChecked}
                                    onChange={handleSwitchChange}
                                    color="primary"
                                    size="normal"
                                  />
                                }
                                label={isChecked ? "Yes" : "No"}
                                labelPlacement="end"
                              />
                            </div>
                          </div>
                        </div>
                        {isChecked && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div className="address_input">
                              <div className="addform_input_half">
                                <div>
                                  <Tooltip title="Enter your Insurance Amount">
                                    <div style={{ marginTop: "3px" }}>
                                      <FormLabel>
                                        Insurance Cash Amount
                                      </FormLabel>
                                      <Input
                                        type="number"
                                        className="input_mui_joy"
                                        placeholder="Enter insurance cash amount"
                                        onChange={(e) =>
                                          handleChanges(e, "insuranceAmount")
                                        }
                                        value={data.insurance.insuranceAmount}
                                        startDecorator={<div>$</div>}
                                        required
                                        disabled={isDisabledNormal}
                                      />
                                      {(!errors.insuranceAmountValidate ||
                                        !errors.insuranceAmountZeroValidate) && (
                                        <FormHelperText error>
                                          <Typography
                                            variant="caption"
                                            color="error"
                                          >
                                            {!errors.insuranceAmountValidate
                                              ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                              : !errors.insuranceAmountZeroValidate
                                              ? "Value can't be zero"
                                              : ""}
                                          </Typography>
                                        </FormHelperText>
                                      )}
                                    </div>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="mt-2 addform_input_half">
                                <div
                                  className={`bene-select mt-3`}
                                  onClick={
                                    data.insurance.insuranceAmount === "" ||
                                    Number(data.insurance.insuranceAmount) ===
                                      0 ||
                                    isDisabledNormal
                                      ? null
                                      : (event) =>
                                          handleShowBeneficiary(event, "normal")
                                  }
                                  style={{
                                    cursor:
                                      data.insurance.insuranceAmount === "" ||
                                      Number(data.insurance.insuranceAmount) ===
                                        0 ||
                                      isDisabledNormal
                                        ? "not-allowed"
                                        : "pointer",
                                    opacity:
                                      data.insurance.insuranceAmount === "" ||
                                      Number(data.insurance.insuranceAmount) ===
                                        0 ||
                                      isDisabledNormal
                                        ? 0.6
                                        : 1,
                                  }}
                                >
                                  Select Your Beneficiary
                                </div>
                              </div>
                            </div>

                            <FormHelperText error>
                              <Typography variant="caption" color="error">
                                Note: Please recheck "Insurance Cash Amount"
                                before selecting your beneficiary.
                              </Typography>
                            </FormHelperText>
                            <div className="mt-2 switch_changes_main">
                              <div className="switch_changes_base">
                                <div>
                                  Do you want to include the insurance cash amount as part of
                                  your net worth?
                                </div>
                                <div>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        checked={
                                          data.insurance.isPartOfNetWorth ===
                                          "true"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          handleChanges(e, "isPartOfNetWorth")
                                        }
                                        color="primary"
                                        size="normal"
                                      />
                                    }
                                    label={
                                      data?.insurance?.isPartOfNetWorth ===
                                      "true"
                                        ? "Yes"
                                        : "No"
                                    }
                                    labelPlacement="end"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div className="mt-2">
                          <Tooltip title="Add your insurance related file">
                            <div>
                              <label
                                style={{
                                  display: "block",
                                  marginBottom: "5px",
                                }}
                              >
                                Supporting Document<span></span>
                              </label>
                              <input
                                style={{
                                  border: "solid 1px lightgray",
                                  borderLeft: "none",
                                  width: "100%",
                                  borderRadius: "5px",
                                }}
                                type="file"
                                name="myfile"
                                id="exampleFile"
                                multiple
                                accept=".pdf"
                                onChange={(e) => handleImageChange(e)}
                              />
                            </div>
                          </Tooltip>
                        </div>

                        <div className="address_input">
                          <div style={{ width: "100%" }} className="mt-2">
                            <div>
                              <FormLabel>Notes/Point of contact</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) => handleChanges(e, "notes")}
                                value={data.insurance.notes}
                              />
                            </div>
                          </div>
                        </div>

                        <Container className="text-center">
                          <Button
                            onClick={resetForm}
                            className="my-estate-clear-btn"
                            type="reset"
                            outline
                          >
                            Clear
                          </Button>
                          <Button outline className="my-estate-add-btn">
                            Add
                          </Button>
                        </Container>
                      </Form>
                    </CardBody>
                  </Card>
                </Container>
              </div>
            </div>
          )}

          {show1 && Object.keys(showDetail).length > 0 && (
            <>
              <div className="card__data">
                <div className="card__data-container">
                  <section className="section1">
                    <div>
                      <p className="row1-text">
                        <FontAwesomeIcon
                          icon={faShieldHalved}
                          style={{ color: "#025596", fontSize: "18px" }}
                        />
                        <span>{showDetail.insurance.details}</span>
                      </p>
                      <div className="row1-button">
                        <div>
                          {showDetail.documents &&
                            showDetail.documents.length > 0 && (
                              <Tooltip title="click to see multiple downlaod files">
                                <div
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    handleShowDownlaod(showDetail);
                                    setShow(false);
                                  }}
                                >
                                  <div className="myestate_download_button dwnbtn">
                                    <FontAwesomeIcon
                                      className="myestate_download_icon"
                                      icon={faDownload}
                                    />
                                    <span></span>
                                  </div>
                                </div>
                              </Tooltip>
                            )}
                        </div>

                        {primaryUserDetails.accountType === "secondary" &&
                        secondaryUserDetails.accountType === "primary" &&
                        secondaryUserDetails.isSecondaryUserEditable ===
                          "false" ? (
                          ""
                        ) : (
                          <div>
                            <Tooltip title="Click Here To Edit">
                              <div>
                                <UpdateButton
                                  URL={"../my-estate/insurances/"}
                                  id={showDetail.insurance.id}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        )}

                        {primaryUserDetails.accountType === "secondary" &&
                        secondaryUserDetails.accountType === "primary" &&
                        secondaryUserDetails.isSecondaryUserEditable ===
                          "false" ? (
                          ""
                        ) : (
                          <div>
                            <FontAwesomeIcon
                              onClick={() =>
                                removeInsurance(
                                  showDetail.insurance.id,
                                  "insuranceId"
                                )
                              }
                              className="delete-button-comp"
                              style={{ cursor: "pointer" }}
                              icon={faTrash}
                            />
                          </div>
                        )}

                        <div>
                          <span
                            className="card__data-close"
                            onClick={() => {
                              setShow1(!show1);
                            }}
                          >
                            <FontAwesomeIcon icon={faXmark} />
                          </span>
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="section2">
                    <div>
                      <div className="col1">
                        {showDetail.insurance.owner && (
                          <p>
                            Owner : <code>{showDetail.insurance.owner}</code>
                          </p>
                        )}

                        <p>
                          Insurance Caption:{" "}
                          <code>{showDetail.insurance.insuranceCaption}</code>
                        </p>

                        <p>
                          Insurance Provider:{" "}
                          <code>{showDetail.insurance.insuranceProvider}</code>
                        </p>

                        <p>
                          Details: <code>{showDetail.insurance.details}</code>
                        </p>

                        {showDetail.insurance.notes && (
                          <Tooltip title="Click To see Note">
                            <p
                              onClick={() => {
                                handleOpenPopup(showDetail.insurance.notes);
                                setShow1(!show1);
                              }}
                            >
                              Note/Point of contact:{" "}
                              <code>
                                {" "}
                                {showDetail && showDetail.insurance.notes
                                  ? showDetail.insurance.notes.slice(0, 5)
                                  : ""}
                                ...<span className="readmore">Read More</span>
                              </code>
                            </p>
                          </Tooltip>
                        )}
                      </div>
                      <div className="col2">
                        <p>
                          Insurance Amount:{" "}
                          <code style={{ color: "green", fontWeight: "bold" }}>
                            {formatAmount(
                              showDetail.deathBenefitsModel.deathBenefits
                                .insuranceAmount
                            )}
                          </code>
                        </p>

                        <p
                          onClick={() => {
                            handleOpenBeneficiary("Death-benefits");
                          }}
                        >
                          Death Benefits Beneficiary:{" "}
                          <code>
                            {showDetail.deathBenefitsModel.sharedDetails[0] ? (
                              <span className="readmore">Click Here</span>
                            ) : (
                              <span
                                style={{ color: "#e74c3c", fontWeight: "bold" }}
                              >
                                No Beneficiary
                              </span>
                            )}
                          </code>
                        </p>

                        {showDetail.insurance.insuranceAmount && (
                          <p>
                            Insurance Cash Amount:{" "}
                            <code
                              style={{ color: "green", fontWeight: "bold" }}
                            >
                              {formatAmount(
                                showDetail.insurance.insuranceAmount
                              )}
                            </code>
                          </p>
                        )}
                        {showDetail.sharedDetails[0] && (
                          <div className="col2">
                            {showDetail.sharedDetails[0] && (
                              <p
                                onClick={() => {
                                  handleOpenBeneficiary("normal");
                                }}
                              >
                                Beneficiary Details{" "}
                                <code>
                                  <span className="readmore">Click Here</span>
                                </code>
                              </p>
                            )}
                          </div>
                        )}
                        {showDetail.insurance.trust && (
                          <p>
                            Trust Name :{" "}
                            <code>{showDetail.insurance.trust}</code>
                          </p>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </>
          )}

          {showConfirmation && (
            <Deletebutton
              handleRemove={handleRemove}
              Id={currentId}
              idType={currentIdType}
              handleConfirmationClose={handleConfirmationClose}
            />
          )}

          {popupVisible && (
            // Popup div
            <div className="popup">
              <div className="popup-content">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div>
                      <h2>Notes</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisible(false);
                          setShow1(!show1);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>
                  <div>
                    <p>{selectedNote}</p>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/*  download popup */}
          {popupVisibleDownlaod && (
            <div className="popup">
              <div className="popup-content popup-content-download">
                <div className="note_popup">
                  <div className="note_popup_heading">
                    <div style={{ textAlign: "center", width: "100%" }}>
                      <h2>Download Files</h2>
                    </div>
                    <div>
                      <button
                        className="note_popup_heading_close_btn"
                        onClick={() => {
                          setPopupVisibleDownlaod(false);
                          setShow1(true);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </div>
                  </div>

                  <div>
                    {selectedDownlaod.documents &&
                      selectedDownlaod.documents.length > 0 &&
                      selectedDownlaod.documents.map((file, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <Tooltip title={file.fileName}>
                            <div
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                gap: "20px",
                              }}
                              onClick={() => {
                                handleDownload(file.id, file.fileName);
                              }}
                            >
                              Download - {index + 1}
                              <div className="myestate_download_button dwnbtn">
                                <FontAwesomeIcon
                                  className="myestate_download_icon"
                                  icon={faDownload}
                                />
                                <span>{file.fileName}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          )}

          {benevisible && (
            <SharedBeneficiary
              handleTrustSave={handleTrustSave}
              assetData={
                typeBeneficiary === "normal"
                  ? data?.insurance
                  : data?.deathBenefitsModel
              }
              trusts={trusts}
              handleChanges={handleChanges}
              sharedType={sharedType}
              handleCloseBeneficiaryPopup={handleCloseBeneficiaryPopup}
              estimatedTotalAmount={estimatedTotalAmount}
              typeBeneficiary={typeBeneficiary}
              sharedDetails={
                typeBeneficiary === "normal"
                  ? data.sharedDetails
                  : data?.deathBenefitsModel?.sharedDetails
              }
            />
          )}

          {beneficiaryVisible && (
            <ShowSharedBeneficiary
              primaryUserDetails={primaryUserDetails}
              secondaryUserDetails={secondaryUserDetails}
              removeInsurance={removeInsurance}
              showDetail={
                typeBeneficiary === "normal"
                  ? showDetail?.sharedDetails
                  : showDetail?.deathBenefitsModel?.sharedDetails
              }
              getBenificiaryName={getBenificiaryName}
              handleCloseBeneficiary={handleCloseBeneficiary}
            />
          )}
        </SideBar>
      </UserBase>
    </div>
  );
}

export default LifeInsurance;
