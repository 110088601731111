import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { getBeneficiary, getToken, getUser } from "../services/user-service";
import { Radio, RadioGroup } from "@mui/joy";

function SharedBeneficiary({
  assetName,
  handleTrustSave,
  assetIndexValue,
  assetData,
  trusts,
  sharedType,
  handleChanges,
  handleCloseBeneficiaryPopup,
  estimatedTotalAmount,
  typeBeneficiary,
  sharedDetails,
}) {
  const [data, setData] = useState({
    sharedDetails: sharedDetails || [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [distributionType, setDistributionType] = useState("");
  const [selectedBeneficiaries, setSelectedBeneficiaries] = useState([]);
  const [beneficiaryDetails, setBeneficiaryDetails] = useState({});

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getBenificiarydata();
  }, []);

  const handleReset = () => {
    setDistributionType("");
    setSelectedBeneficiaries([]);
    setBeneficiaryDetails({});
  };

  const handleDistributionTypeChange = (event) => {
    const newType = event.target.value;
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection = (event) => {
    const selectedBeneficiary = event.target.value;
    if (!selectedBeneficiaries.includes(selectedBeneficiary)) {
      setSelectedBeneficiaries([...selectedBeneficiaries, selectedBeneficiary]);
      setBeneficiaryDetails({
        ...beneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", value: "" },
      });
    }
  };

  const handleBeneficiaryClose = (beneficiary) => {
    const updatedBeneficiaries = selectedBeneficiaries.filter(
      (b) => b !== beneficiary
    );
    setSelectedBeneficiaries(updatedBeneficiaries);
    const updatedDetails = { ...beneficiaryDetails };

    delete updatedDetails[beneficiary];
    setBeneficiaryDetails(updatedDetails);

    const updatedSharedDetails = data?.sharedDetails.filter(
      (detail) => detail.beneficiaryId !== beneficiary
    );

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
  };

  const getBenificiaryName = (id) => {
    var foundBenificiary = null;
    if (id.beneficiary === undefined) {
      foundBenificiary = beneficiary.find((b) => b.id === parseInt(id));
    } else {
      foundBenificiary = beneficiary.find(
        (b) => b.id === parseInt(id.beneficiary)
      );
    }

    if (foundBenificiary) {
      return `${foundBenificiary.firstName} ${foundBenificiary.lastName}`;
    } else {
      return "Benificiary not found";
    }
  };

  const handleFieldChange = (beneficiary, field, value) => {
    if (!estimatedTotalAmount || parseFloat(estimatedTotalAmount) === 0) {
      toast.error(
        "Please provide a valid estimated value before adding percentages!"
      );
      return;
    }
    let updatedDetails = { ...beneficiaryDetails };
    if (/^\d*$/.test(value)) {
      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
    }
    let totalPercentage = 0;
    let totalValue = 0;
    Object.keys(updatedDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(updatedDetails[key]?.percentage);
      const beneficiaryValue = parseFloat(updatedDetails[key]?.value);
      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        totalPercentage += beneficiaryPercentage;
      }
      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        totalValue += beneficiaryValue;
      }
    });
    if (
      totalPercentage > 100 ||
      totalValue > parseFloat(estimatedTotalAmount)
    ) {
      updatedDetails[beneficiary][field] = "";
      toast.error(
        "Total percentage exceeds 100% or total value exceeds estimated value!"
      );
    } else {
      setBeneficiaryDetails(updatedDetails);
    }
  };

  const calculateDistributedAmount = (
    distributionType,
    balance,
    beneficiaryDetail
  ) => {
    if (distributionType === "percentage") {
      return (
        (parseFloat(balance) * parseFloat(beneficiaryDetail?.percentage || 0)) /
        100
      ).toFixed(2);
    } else if (distributionType === "dollar") {
      return parseFloat(beneficiaryDetail?.value || 0).toFixed(2);
    }
    return "0.00";
  };

  const handleSave = () => {
    var i = 0;
    Object.keys(beneficiaryDetails).forEach((key) => {
      const beneficiaryPercentage = parseFloat(
        beneficiaryDetails[key]?.percentage
      );
      const beneficiaryValue = parseFloat(beneficiaryDetails[key]?.value);

      if (!isNaN(beneficiaryPercentage) && beneficiaryPercentage >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "percentage",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "percentage",
          beneficiaryPercentage,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }

      if (!isNaN(beneficiaryValue) && beneficiaryValue >= 0) {
        const distributedAmount = calculateDistributedAmount(
          "dollar",
          estimatedTotalAmount,
          beneficiaryDetails[key]
        );
        handleChanges2(
          "dollar",
          beneficiaryValue,
          distributedAmount,
          parseInt(key),
          i
        );
        i += 1;
      }
    });
    handleCloseBeneficiaryPopup({
      name: data?.sharedDetails,
    });
  };

  const handleChanges2 = (t, v, a, b, i) => {
    const updatedSharedDetails = [...data.sharedDetails];

    const updatedElement = {
      ...updatedSharedDetails[i],
      distributedType: t,
      distributedValue: v,
      distributedAmount: a,
      beneficiaryId: b,
    };

    updatedSharedDetails[i] = updatedElement;

    setData((prevState) => ({
      ...prevState,
      sharedDetails: updatedSharedDetails,
    }));
    data.sharedDetails[i] = updatedSharedDetails[i];
  };

  const ben = (newType) => {
    const resetDetails = {};
    Object.keys(beneficiaryDetails).forEach((beneficiary) => {
      resetDetails[beneficiary] = { percentage: "", value: "" };
    });
    setDistributionType(newType);
    setBeneficiaryDetails(resetDetails);
  };

  const handleBeneficiarySelection1 = (selectedBeneficiary) => {
    if (selectedBeneficiary === null) {
      return null;
    }
    setSelectedBeneficiaries((prevSelectedBeneficiaries) => {
      // Check if the selectedBeneficiary is already included
      if (!prevSelectedBeneficiaries.includes(selectedBeneficiary)) {
        // If not included, update the selected beneficiaries
        const newSelectedBeneficiaries = [
          ...prevSelectedBeneficiaries,
          selectedBeneficiary,
        ];
        return newSelectedBeneficiaries;
      }

      // If already included, return the previous state without any changes
      return prevSelectedBeneficiaries;
    });

    setBeneficiaryDetails((prevBeneficiaryDetails) => {
      const newBeneficiaryDetails = {
        ...prevBeneficiaryDetails,
        [selectedBeneficiary]: { percentage: "", dollar: "" },
      };
      return newBeneficiaryDetails;
    });
  };

  const handleFieldChange1 = (beneficiary, field, value) => {
    setBeneficiaryDetails((prevDetails) => {
      let updatedDetails = { ...prevDetails };

      updatedDetails = {
        ...updatedDetails,
        [beneficiary]: {
          ...updatedDetails[beneficiary],
          [field]: value,
        },
      };
      return updatedDetails;
    });
  };

  useEffect(() => {
    const copiedSharedDetails =
      sharedDetails !== undefined &&
      sharedDetails[0]?.distributedType !== undefined
        ? [...sharedDetails]
        : [];

    if (
      copiedSharedDetails.length > 0 &&
      copiedSharedDetails[0]?.distributedType !== ""
    ) {
      ben(copiedSharedDetails[0]?.distributedType);
      for (var i = 0; i < copiedSharedDetails.length; i++) {
        handleBeneficiarySelection1(copiedSharedDetails[i].beneficiaryId);
        handleFieldChange1(
          copiedSharedDetails[i].beneficiaryId,
          copiedSharedDetails[i].distributedType === "dollar"
            ? "value"
            : copiedSharedDetails[i].distributedType,
          copiedSharedDetails[i].distributedValue
        );
      }
    }
  }, []);

  return (
    <>
      <div className="popup">
        <div
          className="popup-content popup-content-download"
          style={{
            minWidth: "350px",
            width: "100%",
            maxWidth: "700px",
          }}
        >
          <div className="note_popup">
            <div className="note_popup_heading">
              <div className="share_property_heading">
                <h2>Share Property </h2>
              </div>
              <div>
                <button
                  className="note_popup_heading_close_btn"
                  onClick={handleCloseBeneficiaryPopup}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <p>Do you want to share your asset to beneficiary or trust?</p>
              <RadioGroup
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "20px",
                  margin: "8px 0",
                }}
                defaultValue="medium"
                name="radio-buttons-group"
                value={sharedType}
                onChange={(e) => {
                  if (assetIndexValue !== null) {
                    handleChanges(e, "sharedType", assetIndexValue, assetName);
                  } else if (typeBeneficiary === "normal") {
                    handleChanges(e, "sharedType");
                  } else {
                    handleChanges(e, "deathBenefitsSharedType");
                  }

                  handleReset(e);
                }}
              >
                <Radio value="Beneficiary" label="Beneficiary" />
                <Radio sx={{ margin: 0 }} value="Trust" label="Trust" />
              </RadioGroup>
            </div>
            {sharedType ? (
              <div style={{ marginTop: "8px" }}>
                <div className="share_property_est_value">
                  <p>
                    Estimated Value:{" "}
                    <code style={{ color: "green", fontWeight: "bold" }}>
                      ${estimatedTotalAmount}
                    </code>
                  </p>
                </div>
                {sharedType === "Beneficiary" ? (
                  <>
                    {assetName === "jewelry" || assetName === "otherAsset" ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: "8px",
                            margin: "12px 0",
                          }}
                        >
                          <p>
                            Select your beneficiary where you want to share your
                            asset:
                          </p>
                          <select
                            value={
                              typeBeneficiary === "normal"
                                ? assetData?.beneficiaryName
                                : assetData?.deathBenefits?.trust
                            }
                            onChange={(e) => {
                              if (assetIndexValue !== null) {
                                handleChanges(
                                  e,
                                  "beneficiaryName",
                                  assetIndexValue,
                                  assetName
                                );
                              } else if (typeBeneficiary === "normal") {
                                handleChanges(e, "trust");
                              } else {
                                handleChanges(e, "deathBenefitsTrust");
                              }
                            }}
                            className="share_property_Type_select"
                          >
                            <option value={""}>
                              {"Select your beneficiary"}
                            </option>
                            {beneficiary.map((benData) => (
                              <option
                                key={benData.id}
                                value={`${benData?.firstName} ${benData?.lastName}`}
                              >
                                {`${benData?.firstName} ${benData?.lastName}`}
                              </option>
                            ))}
                          </select>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="BeneficiarySelect">
                          <div className="BeneficiarySelectContainer">
                            <div className="BeneficiarySelectRow">
                              <div className="share_property_Type">
                                <p className="share_property_Type_paragraph">
                                  Choose Distribution Type:{" "}
                                </p>
                                <select
                                  value={distributionType}
                                  onChange={handleDistributionTypeChange}
                                  className="share_property_Type_select"
                                >
                                  <option value="">Select Type</option>
                                  <option value="percentage">Percentage</option>
                                  <option value="dollar">Dollar</option>
                                </select>
                              </div>
                              <div className="SelectContainer">
                                <select
                                  className="Property-inputfiled"
                                  onChange={handleBeneficiarySelection}
                                  value=""
                                  disabled={!distributionType}
                                >
                                  <option value="" disabled hidden>
                                    {distributionType
                                      ? "Select Your Beneficiary Username"
                                      : "Select Type First"}
                                  </option>
                                  {beneficiary.map((benif) => (
                                    <option
                                      key={benif.id}
                                      value={benif.id}
                                      disabled={selectedBeneficiaries.includes(
                                        benif.id
                                      )}
                                    >
                                      {`${benif.firstName} ${benif.lastName}`}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="share_beneficiary_main_card">
                              {selectedBeneficiaries.map((beneficiary) => (
                                <div
                                  key={beneficiary}
                                  className="share_beneficiary_card"
                                >
                                  <div>
                                    <p className="share_beneficiary_card_para">
                                      Beneficiary:{" "}
                                      {getBenificiaryName({ beneficiary })}
                                    </p>
                                    {distributionType === "percentage" && (
                                      <input
                                        type="text"
                                        className="share_ben_percentage"
                                        placeholder="Percentage"
                                        value={
                                          beneficiaryDetails[beneficiary]
                                            ?.percentage || ""
                                        }
                                        onChange={(e) =>
                                          handleFieldChange(
                                            beneficiary,
                                            "percentage",
                                            e.target.value
                                          )
                                        }
                                      />
                                    )}
                                    {distributionType === "dollar" && (
                                      <input
                                        type="text"
                                        className="share_ben_percentage"
                                        placeholder="Dollar Value"
                                        value={
                                          beneficiaryDetails[beneficiary]
                                            .value || ""
                                        }
                                        onChange={(e) =>
                                          handleFieldChange(
                                            beneficiary,
                                            "value",
                                            e.target.value
                                          )
                                        }
                                      />
                                    )}

                                    {distributionType && (
                                      <p className="share_beneficiary_card_para">
                                        Distributed Value: $
                                        {distributionType === "percentage"
                                          ? calculateDistributedAmount(
                                              "percentage",
                                              estimatedTotalAmount,
                                              beneficiaryDetails[beneficiary]
                                            )
                                          : calculateDistributedAmount(
                                              "dollar",
                                              estimatedTotalAmount,
                                              beneficiaryDetails[beneficiary]
                                            )}
                                      </p>
                                    )}
                                  </div>
                                  <div
                                    className="share_beneficiary_card_close"
                                    onClick={() =>
                                      handleBeneficiaryClose(beneficiary)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faXmark} />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                      margin: "12px 0",
                    }}
                  >
                    <p>Select your trust where you want to share your asset:</p>
                    <select
                      value={
                        typeBeneficiary === "normal"
                          ? assetData?.trust
                          : assetData?.deathBenefits?.trust
                      }
                      onChange={(e) => {
                        if (assetIndexValue !== null) {
                          handleChanges(e, "trust", assetIndexValue, assetName);
                        } else if (typeBeneficiary === "normal") {
                          handleChanges(e, "trust");
                        } else {
                          handleChanges(e, "deathBenefitsTrust");
                        }
                      }}
                      className="share_property_Type_select"
                    >
                      <option value={""}>{"Select your trust"}</option>
                      {trusts.map((trust) => (
                        <option key={trust.id} value={trust?.trustName}>
                          {trust?.trustName}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
          {(selectedBeneficiaries.length > 0 ||
            (sharedDetails?.length > 0 &&
              sharedDetails[0]?.distributedType !== "" &&
              typeBeneficiary === "normal")) && (
            <button
              onClick={() => {
                handleSave();
              }}
            >
              Save
            </button>
          )}
          {((assetData?.trust !== "" && assetData?.trust !== undefined) ||
            (assetData?.beneficiaryName !== "" &&
              assetData?.beneficiaryName !== undefined)) && (
            <button onClick={handleTrustSave}>Save</button>
          )}
          {typeBeneficiary === "death-benefits" &&
            assetData?.deathBenefits?.trust !== "" &&
            assetData?.deathBenefits?.trust !== undefined && (
              <button onClick={handleTrustSave}>Save</button>
            )}
        </div>
      </div>
    </>
  );
}

export default SharedBeneficiary;
