import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormHelperText, Input, Option, Select, Textarea } from "@mui/joy";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Card, CardBody, CardFooter, CardHeader } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import { formatAmount } from "../../../components/Common";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import "../../../css/formPOPup.css";
import "../../../css/myestare.css";
import "../../../css/myestate_edit.css";
import {
  getSingleCryptoAssest,
  updateCryptoAssests,
} from "../../../services/CryptoService";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../../services/user-service";
import SharedBeneficiaryEdit from "../../../components/SharedBeneficiaryEdit";
import { Typography } from "@mui/material";

function EditCrypto() {
  const { id } = useParams();
  const [data, setData] = useState({
    cryptoAssest: {
      cryptoListName: "",
      owner: "",
      heading: "",
      coin: "Bitcoin",
      storageType: "Exchange",
      exchangeOrWallet: "",
      otherExchangeOrWallet: "",
      quantity: "",
      notes: "",
      estValue: "",
      trust: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [errors, setErrors] = useState({
    quantityZeroValidate: true,
  });

  const navigate = useNavigate();

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "coin") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          coin: newValue,
        },
      }));
    }

    if (stringValue === "storageType") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          storageType: newValue,
          exchangeOrWallet: "",
        },
      }));
    }

    if (stringValue === "exchangeOrWallet") {
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          exchangeOrWallet: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  // use state to set the selected images
  const [selectedImage, setSelectedImage] = useState([]);

  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    const newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);
      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        cryptoAssest: {
          ...prevData.cryptoAssest,
          trust: "",
        },
      }));
    } else {
      if (field === "quantity") {
        errorStates.quantityZeroValidate =
          newValue !== "" ? Number(newValue) > 0 : true;
        setData((prevData) => ({
          ...prevData,
          cryptoAssest: {
            ...prevData.cryptoAssest,
            [field]: newValue,
            estValue: newValue * bitcoinPrice,
          },
        }));
      } else {
        setData((prevData) => ({
          ...prevData,
          cryptoAssest: {
            ...prevData.cryptoAssest,
            [field]: newValue,
          },
        }));
      }
      setEstimatedTotalAmount(data.cryptoAssest.estValue);
    }
    setErrors(errorStates);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const cryptoForm = (event) => {
    event.preventDefault();

    const hasErrors = Object.values(errors).includes(false);
    if (!hasErrors) {
      let token = "Bearer " + getToken();

      const formData = new FormData();
      if (null != selectedImage) {
        for (let i = 0; i < selectedImage.length; i++) {
          formData.append(`files`, selectedImage[i]);
          console.log("this is file indexs", selectedImage[i]);
        }
      }
      formData.append("data", JSON.stringify(data));

      updateCryptoAssests(formData, token)
        .then((resp) => {
          toast.success("Updated Successfully!!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
          navigate("/user/my-estate/crypto");
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };

  const [responseData, setResponseData] = useState(null);
  const [responseAssetData, setResponseAssetData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleCryptoAssest(token, id);
        console.log("this is crypto responce ", res);
        setData({
          ...data,
          cryptoAssest: res.cryptoAssest,
          documents: res.documents,
          sharedDetails: res.sharedDetails,
        });
        setResponseData(res);
        setResponseAssetData(res?.cryptoAssest);
        const ownerNameArray = res.cryptoAssest.owner.split(", ");
        setOwnerName(ownerNameArray);
        if (res.cryptoAssest.trust !== "" && res.cryptoAssest.trust != null) {
          setSharedType("Trust");
        } else if (res.sharedDetails.length > 0) {
          setSharedType("Beneficiary");
        } else {
          setSharedType(null);
        }

        setEstimatedTotalAmount(res.cryptoAssest.estValue);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    getBenificiarydata();
    getTrustsData();
  }, []);

  const [bitcoinPrice, setBitcoinPrice] = useState(null);

  useEffect(() => {
    const getBitcoinPrice = () => {
      axios
        .get("https://api.coindesk.com/v1/bpi/currentprice/BTC.json")
        .then((res) => {
          const price = res.data.bpi.USD.rate_float;
          setBitcoinPrice(price);
        })
        .catch((error) => {
          console.error("Error fetching Bitcoin price:", error);
        });
    };
    getBitcoinPrice();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
    setEstimatedTotalAmount(data.cryptoAssest.estValue);
  };

  const handleEditCloseBeneficiary = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };
  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  return (
    <UserBase>
      <div className="mt-5"></div>
      <SideBar>
        <div className="overlay1" style={{ transition: "500ms", height: "" }}>
          <div>
            <Form onSubmit={cryptoForm} className="addform_body">
              <container>
                <div className="homepage jewelry_container">
                  <Card>
                    <CardHeader>
                      <div
                        style={{ width: "100%" }}
                        className="addform_heading"
                      >
                        <h3 className="addform_heading_h1">
                          {data.cryptoAssest.cryptoListName}
                        </h3>
                        <div className="addform_close">
                          <FontAwesomeIcon
                            icon={faXmark}
                            onClick={() => {
                              navigate("/user/my-estate/crypto");
                            }}
                          />
                        </div>
                      </div>
                    </CardHeader>
                    <CardBody className="jewelry_card_body">
                      <div>
                        <table className="jewelry_table">
                          <thead>
                            <tr>
                              <th className="crypto_132px">Select Owner*</th>
                              <th className="crypto_115px">Heading</th>
                              <th className="crypto_115px">Select Coin*</th>
                              <th className="crypto_115px">
                                Select Exchange/Wallet*
                              </th>
                              <th className="crypto_100px">Quantity*</th>
                              <th className="crypto_100px">Value*</th>

                              <th className="crypto_115px">Documents</th>
                              <th>Beneficiary*</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Select
                                  className="crypto_select crypto_132px"
                                  placeholder="Owner"
                                  value={ownerName}
                                  multiple
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "owner")
                                  }
                                  required
                                >
                                  {ownerNames.map((name) => (
                                    <Option key={name} value={name}>
                                      {name}
                                    </Option>
                                  ))}
                                </Select>
                              </td>

                              <td>
                                <Input
                                  className="crypto_input crypto_115px"
                                  placeholder="Heading"
                                  value={data.cryptoAssest.heading}
                                  onChange={(e) => handleChanges(e, "heading")}
                                />
                              </td>

                              <td>
                                <Select
                                  className="crypto_select crypto_115px"
                                  value={data.cryptoAssest.coin}
                                  onChange={(event, newValue) =>
                                    handleChange(event, newValue, "coin")
                                  }
                                  required
                                >
                                  <Option value="">Select coin</Option>
                                  <Option value="Bitcoin">Bitcoin</Option>
                                </Select>
                              </td>

                              <td>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    gap: "2px",
                                  }}
                                >
                                  <Select
                                    className="crypto_select crypto_115px"
                                    value={data.cryptoAssest.storageType}
                                    onChange={(event, newValue) =>
                                      handleChange(
                                        event,
                                        newValue,
                                        "storageType"
                                      )
                                    }
                                    required
                                  >
                                    <Option value="">Select one</Option>
                                    <Option value="Exchange">Exchange</Option>
                                    <Option value="Wallet">Wallet</Option>
                                  </Select>

                                  {data.cryptoAssest.storageType ===
                                  "Exchange" ? (
                                    <>
                                      <Select
                                        className="crypto_115px"
                                        value={
                                          data.cryptoAssest.exchangeOrWallet
                                        }
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "exchangeOrWallet"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">
                                          Select exchange
                                        </Option>
                                        <Option value="Coinbase">
                                          Coinbase
                                        </Option>
                                        <Option value="Binance.US">
                                          Binance.US
                                        </Option>
                                        <Option value="Kraken">Kraken</Option>
                                        <Option value="Gemini">Gemini</Option>
                                        <Option value="Bitfinex">
                                          Bitfinex
                                        </Option>
                                        <Option value="Bitstamp">
                                          Bitstamp
                                        </Option>
                                        <Option value="Huobi US">
                                          Huobi US
                                        </Option>
                                        <Option value="Crypto.com">
                                          Crypto.com
                                        </Option>
                                        <Option value="BitFlyer">
                                          BitFlyer
                                        </Option>
                                        <Option value="OKCoin">OKCoin</Option>
                                        <Option value="Other">Other</Option>
                                      </Select>
                                      {data.cryptoAssest.exchangeOrWallet ===
                                        "Other" && (
                                        <Input
                                          className="crypto_input crypto_132px"
                                          placeholder="Other Exchange"
                                          value={
                                            data.cryptoAssest
                                              .otherExchangeOrWallet
                                          }
                                          onChange={(e) =>
                                            handleChanges(
                                              e,
                                              "otherExchangeOrWallet"
                                            )
                                          }
                                          required
                                        />
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      <Select
                                        className="crypto_115px"
                                        value={
                                          data.cryptoAssest.exchangeOrWallet
                                        }
                                        onChange={(event, newValue) =>
                                          handleChange(
                                            event,
                                            newValue,
                                            "exchangeOrWallet"
                                          )
                                        }
                                        required
                                      >
                                        <Option value="">Select wallet</Option>
                                        <Option value="Ledger Nano S">
                                          Ledger Nano S
                                        </Option>
                                        <Option value="Trezor Model T">
                                          Trezor Model T
                                        </Option>
                                        <Option value="Exodus">Exodus</Option>
                                        <Option value="Atomic Wallet">
                                          Atomic Wallet
                                        </Option>
                                        <Option value="Trust Wallet">
                                          Trust Wallet
                                        </Option>
                                        <Option value="MyEtherWallet">
                                          MyEtherWallet
                                        </Option>
                                        <Option value="Coinomi">Coinomi</Option>
                                        <Option value="MetaMask">
                                          MetaMask
                                        </Option>
                                        <Option value="Freewallet">
                                          Freewallet
                                        </Option>
                                        <Option value="KeepKey">KeepKey</Option>
                                        <Option value="Other">Other</Option>
                                      </Select>
                                      {data.cryptoAssest.exchangeOrWallet ===
                                        "Other" && (
                                        <Input
                                          className="crypto_input crypto_115px"
                                          placeholder="Other Wallet"
                                          value={
                                            data.cryptoAssest
                                              .otherExchangeOrWallet
                                          }
                                          onChange={(e) =>
                                            handleChanges(
                                              e,
                                              "otherExchangeOrWallet"
                                            )
                                          }
                                          required
                                        />
                                      )}
                                    </>
                                  )}
                                </div>
                              </td>

                              <td>
                                <Input
                                  className="crypto_input crypto_100px"
                                  type="number"
                                  placeholder="Quantity"
                                  value={data.cryptoAssest.quantity}
                                  onChange={(e) => handleChanges(e, "quantity")}
                                  required
                                />
                                {!errors.quantityZeroValidate && (
                                  <FormHelperText error>
                                    <Typography
                                      variant="caption"
                                      color="error"
                                      sx={{ fontSize: "9px" }}
                                    >
                                      Quantity can't be zero
                                    </Typography>
                                  </FormHelperText>
                                )}
                              </td>

                              <td>
                                <Input
                                  className="crypto_input crypto_100px"
                                  placeholder="Est Value"
                                  type="number"
                                  value={data.cryptoAssest.estValue}
                                  onChange={(e) => handleChanges(e, "estValue")}
                                  startDecorator={<div>$</div>}
                                  required
                                  disabled
                                />
                              </td>

                              <td>
                                <div className="jewelry_choose_files crypto_132px">
                                  <div>
                                    <label
                                      className="otherAsset_choose_file_label"
                                      htmlFor={`files`}
                                    >
                                      Choose Files
                                    </label>
                                  </div>
                                  {selectedImage?.length > 0 && (
                                    <div>
                                      <label className="jewelry_choose_images_file">
                                        {selectedImage?.length === 1
                                          ? `${
                                              selectedImage[0].name.length > 15
                                                ? selectedImage[0].name.substring(
                                                    0,
                                                    4
                                                  ) +
                                                  "..." +
                                                  selectedImage[0].name.slice(
                                                    -6
                                                  )
                                                : selectedImage[0].name
                                            }`
                                          : `${selectedImage?.length} files`}
                                      </label>
                                    </div>
                                  )}
                                  <input
                                    style={{ display: "none" }}
                                    id={`files`}
                                    type="file"
                                    name="myfile"
                                    onChange={(e) => handleImageChange(e, 5)}
                                    accept=".pdf"
                                    multiple
                                  />
                                </div>
                              </td>

                              <td>
                                <div
                                  className="bene-select beneficiary_btn"
                                  onClick={() => handleShowBeneficiary()}
                                >
                                  Beneficiary
                                </div>
                              </td>

                              <td>
                                <Textarea
                                  style={{ fontSize: "14px" }}
                                  placeholder="Notes"
                                  id="notes"
                                  value={data.cryptoAssest.notes}
                                  onChange={(e) => handleChanges(e, "notes")}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                    <CardFooter>
                      <div className="text-center">
                        <Button outline className="my-estate-add-btn">
                          Update
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </container>
            </Form>
          </div>
        </div>

        {benevisible && (
          <SharedBeneficiaryEdit
            responseData={responseData}
            responseAssetData={responseAssetData}
            handleTrustSave={handleTrustSave}
            assetData={data?.cryptoAssest}
            trusts={trusts}
            handleChanges={handleChanges}
            sharedType={sharedType}
            handleCloseBeneficiaryPopup={handleEditCloseBeneficiary}
            estimatedTotalAmount={estimatedTotalAmount}
            typeBeneficiary={"normal"}
            sharedDetails={data?.sharedDetails}
          />
        )}
      </SideBar>
    </UserBase>
  );
}

export default EditCrypto;
