import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Textarea,
} from "@mui/joy";
import { FormControlLabel, Switch, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Form } from "reactstrap";
import SideBar from "../../../components/sidebar/Sidebar";
import UserBase from "../../../components/user/UserBase";
import "../../../css/formPOPup.css";
import "../../../css/myestate_edit.css";
import {
  getSingleInvestment,
  updateInvestments,
} from "../../../services/investment-service";
import {
  fetchNetWorth,
  getBeneficiary,
  getOwnerByUserId,
  getSecondaryUser,
  getToken,
  getTrustByUserId,
  getUser,
} from "../../../services/user-service";
import SharedBeneficiaryEdit from "../../../components/SharedBeneficiaryEdit";
import { validateEightDigitNumber } from "../../../components/Common";

function EditInvestment() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({
    investment: {
      owner: "",
      investment: "",
      nameOfTheInvestment: "",
      investmentCaption: "",
      notes: "",
      totalAmount: "",
      isPartOfNetWorth: "true",
      trust: "",
    },
    sharedDetails: [
      {
        distributedType: "",
        distributedValue: "",
        distributedAmount: "",
        beneficiaryId: "",
      },
    ],
  });

  const [errors, setErrors] = useState({
    totalAmountValidate: true,
    totalAmountZeroValidate: true,
  });

  // for owner field
  const [ownerName, setOwnerName] = useState([]);

  const handleChange = (event, newValue, stringValue) => {
    if (stringValue === "investment") {
      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          investment: newValue,
        },
      }));
    }

    if (stringValue === "owner") {
      // Convert ownerName array to a single string

      const comingValue =
        typeof newValue === "string" ? newValue.split(",") : newValue;
      const ownerString = comingValue.join(", ");

      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          owner: ownerString,
        },
      }));

      // Update the ownerName state afterwards
      setOwnerName(newValue);
    }
  };

  // Define an array to store user names
  const [ownerNames, setOwnerNames] = useState([]);

  // Get primaryUser data
  const primaryUserDetails = getUser();
  const primaryUserName =
    primaryUserDetails.firstName + " " + primaryUserDetails.lastName;

  // Get secondaryUser data
  const secondaryUserDetails = getSecondaryUser();
  useEffect(() => {
    const fetchOwnerData = async () => {
      const userId = getUser().commonId;
      const token = "Bearer " + getToken();

      try {
        const resp = await getOwnerByUserId(token, userId);
        // Process data after fetching
        const names = [primaryUserName];

        // Check if secondary user exists
        if (secondaryUserDetails !== undefined) {
          const secondaryUserName = `${secondaryUserDetails.firstName} ${secondaryUserDetails.lastName}`;
          names.push(secondaryUserName);
        }
        resp.forEach((user) => {
          const fullName = `${user.firstName} ${user.lastName}`;
          names.push(fullName);
        });

        // Update ownerNames state
        setOwnerNames(names);
      } catch (error) {
        console.error("Failed to fetch owner data", error);
      }
    };

    fetchOwnerData();
  }, []);

  const [selectedImage, setSelectedImage] = useState([]);
  const [sharedType, setSharedType] = useState(null);
  const handleChanges = (e, field) => {
    let newValue = e.target.value;
    const errorStates = { ...errors };

    if (field === "sharedType") {
      setSharedType(newValue);
      setData((prevData) => {
        const resetSharedDetails = [
          {
            distributedType: "",
            distributedValue: "",
            distributedAmount: "",
            beneficiaryId: "",
          },
        ];

        return {
          ...prevData,
          sharedDetails: resetSharedDetails,
        };
      });
      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          trust: "",
        },
      }));
    } else {
      if (field === "totalAmount") {
        errorStates.totalAmountValidate =
          newValue !== "" ? validateEightDigitNumber(newValue) : true;
        errorStates.totalAmountZeroValidate =
          newValue !== "" ? Number(newValue) > 0 : true;
      } else if (field === "isPartOfNetWorth") {
        newValue =
          data.investment.isPartOfNetWorth === "false" ? "true" : "false";
      }
      setData((prevData) => ({
        ...prevData,
        investment: {
          ...prevData.investment,
          [field]: newValue,
        },
      }));
    }
    setEstimatedTotalAmount(data.investment.totalAmount);
    setErrors(errorStates);
  };

  // Handle image
  const handleImageChange = (event) => {
    const selectedFiles = event.target.files;
    const allowedExtensions = ["pdf"];

    if (selectedFiles) {
      const selectedFilesArray = Array.from(selectedFiles);

      // Check each file's extension
      const invalidFiles = selectedFilesArray.filter((file) => {
        const fileNameParts = file.name.split(".");
        const fileExtension =
          fileNameParts[fileNameParts.length - 1].toLowerCase();
        return !allowedExtensions.includes(fileExtension);
      });

      if (invalidFiles.length === 0) {
        const formData = new FormData();

        selectedFilesArray.forEach((file, index) => {
          formData.append(`file${index + 1}`, file);
        });

        // Set the selected image state with the array of files
        setSelectedImage(selectedFilesArray);

        // Now, you can use the formData object to send files to the API
        // Example:
        // apiCall(formData);
      } else {
        // Handle invalid file extensions
        const invalidExtensions = invalidFiles
          .map((file) => file.name.split(".").pop())
          .join(", ");
        toast.error(
          `Invalid file extensions: ${invalidExtensions}. Please select valid document formats.`,
          {
            position: toast.POSITION.BOTTOM_CENTER,
          }
        );
        event.target.value = ""; // Clear the input field
      }
    }
  };

  const investmentForm = (event) => {
    event.preventDefault();

    const hasErrors = Object.values(errors).includes(false);
    if (!hasErrors) {
      let token = "Bearer " + getToken();
      const formData = new FormData();
      if (null != selectedImage) {
        for (let i = 0; i < selectedImage.length; i++) {
          formData.append(`files`, selectedImage[i]);
        }
      }
      formData.append("data", JSON.stringify(data));

      updateInvestments(formData, token)
        .then((resp) => {
          toast.success("Updated Successfully!!", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
          fetchNetWorth(primaryUserDetails.commonId);
          navigate("/user/my-estate/investments");
        })
        .catch((error) => {
          console.log(error);
          toast.error("An error occurred. Please try again.", {
            position: toast.POSITION.BOTTOM_CENTER,
          });
        });
    }
  };
  const [responseData, setResponseData] = useState(null);
  const [responseAssetData, setResponseAssetData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let token = "Bearer " + getToken();
        const res = await getSingleInvestment(token, id);

        setData({
          ...data,
          investment: res.investment,
          documents: res.documents,
          sharedDetails: res.sharedDetails,
        });
        setResponseData(res);
        setResponseAssetData(res?.investment);
        const ownerNameArray = res.investment.owner.split(", ");
        setOwnerName(ownerNameArray);
        setEstimatedTotalAmount(res.investment.totalAmount);

        if (res.investment.trust !== "" && res.investment.trust != null) {
          setSharedType("Trust");
        } else if (res.sharedDetails.length > 0) {
          setSharedType("Beneficiary");
        } else {
          setSharedType(null);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // beneficiary addition in form
  const [beneficiary, setBenificiary] = useState([]);
  const getBenificiarydata = () => {
    let userId = getUser().commonId;
    console.log("user Id=" + userId);
    let token = "Bearer " + getToken();
    getBeneficiary(token, userId)
      .then((res) => {
        setBenificiary(res);
      })
      .catch((err) => console.log(err));
  };

  const [trusts, setTrusts] = useState([]);
  const getTrustsData = () => {
    let userId = getUser().commonId;

    let token = "Bearer " + getToken();
    getTrustByUserId(token, userId)
      .then((res) => {
        setTrusts(res);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBenificiarydata();
    getTrustsData();
  }, []);

  //
  const [benevisible, setbeneVisible] = useState(false);
  const [estimatedTotalAmount, setEstimatedTotalAmount] = useState(0);

  const handleShowBeneficiary = () => {
    setbeneVisible(true);
  };

  const handleEditCloseBeneficiary = (params) => {
    const { name } = params;
    if (name !== undefined) {
      setData((prevData) => ({
        ...prevData,
        sharedDetails: name,
      }));
    }
    setbeneVisible(false);
  };

  const handleTrustSave = () => {
    setbeneVisible(false);
  };

  return (
    <>
      <UserBase>
        <div className="mt-5"></div>
        <SideBar>
          <div className="overlay1" style={{ transition: "500ms", height: "" }}>
            <div className="addform_ichest">
              <div className="addform_main">
                <div className="addform_heading">
                  <h3 className="addform_heading_h1">Edit Investment</h3>
                  <div className="addform_close">
                    <FontAwesomeIcon
                      icon={faXmark}
                      onClick={() => {
                        navigate("/user/my-estate/investments");
                      }}
                    />
                  </div>
                </div>
                <div className="addform_body">
                  <Form onSubmit={investmentForm} className="addform_body">
                    <div className="addform_select_body">
                      <div className="addform_body_left">
                        <div>
                          <Tooltip title="Select Owner" className="mt-2">
                            <div>
                              <FormLabel>Select Owner</FormLabel>
                              <Select
                                value={ownerName}
                                multiple
                                placeholder="Select Name"
                                onChange={(event, newValue) =>
                                  handleChange(event, newValue, "owner")
                                }
                                sx={{
                                  minWidth: "13rem",
                                }}
                              >
                                {ownerNames.map((name) => (
                                  <Option key={name} value={name}>
                                    {name}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                          </Tooltip>
                        </div>

                        <div>
                          <Tooltip title="Enter Heading for Investment">
                            <div className="mt-2">
                              <FormLabel>Investment Heading</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter investment heading"
                                value={data.investment.investmentCaption}
                                onChange={(e) =>
                                  handleChanges(e, "investmentCaption")
                                }
                              />
                            </div>
                          </Tooltip>
                        </div>

                        <div>
                          <Tooltip title="Select Investment Type">
                            <div className="mt-2">
                              <FormLabel required>Investment</FormLabel>
                              <Select
                                value={data.investment.investment}
                                onChange={(event, newValue) =>
                                  handleChange(event, newValue, "investment")
                                }
                                required
                              >
                                <Option value="">
                                  Select Your Type Of investment
                                </Option>
                                <Option value="Stocks">Stocks</Option>
                                <Option value="Mutual Funds">
                                  Mutual Funds
                                </Option>
                                <Option value="Bonds">Bonds</Option>
                                <Option value="401K">401K</Option>
                                <Option value="Exchange-Traded Funds (ETFs)">
                                  Exchange-Traded Funds (ETFs)
                                </Option>
                                <Option value="Certificates of Deposit (CDs)">
                                  Certificates of Deposit (CDs)
                                </Option>
                                <Option value="Money Market Funds">
                                  Money Market Funds
                                </Option>
                                <Option value="Alternative Investments">
                                  Alternative Investments
                                </Option>
                              </Select>
                            </div>
                          </Tooltip>
                        </div>

                        <div>
                          <Tooltip title="Enter the name of Exchange">
                            <div className="mt-2">
                              <FormLabel>Name Of Exchange</FormLabel>
                              <Input
                                className="input_mui_joy"
                                placeholder="Enter name of Exchange"
                                onChange={(e) =>
                                  handleChanges(e, "nameOfTheInvestment")
                                }
                                value={data.investment.nameOfTheInvestment}
                              />
                            </div>
                          </Tooltip>
                        </div>

                        <div>
                          <Tooltip title="Enter Your Estimated Amount ">
                            <div className="mt-2">
                              <FormLabel required>Estimated Amount</FormLabel>
                              <Input
                                placeholder="Estimated Investment Amount"
                                required
                                type="number"
                                onChange={(e) => {
                                  handleChanges(e, "totalAmount");
                                }}
                                value={data.investment.totalAmount}
                                startDecorator={<div>$</div>}
                              />
                              {(!errors.totalAmountValidate ||
                                !errors.totalAmountZeroValidate) && (
                                <FormHelperText error>
                                  <Typography variant="caption" color="error">
                                    {!errors.totalAmountValidate
                                      ? "Please enter a number up to 8 digits (maximum: $99,999,999)."
                                      : !errors.totalAmountZeroValidate
                                      ? "Value can't be zero"
                                      : ""}
                                  </Typography>
                                </FormHelperText>
                              )}
                            </div>
                          </Tooltip>
                        </div>

                        <div className="mt-2 switch_changes_main">
                          <div className="switch_changes_base">
                            <div>
                              Do you want to include the asset value as part of
                              your net worth?
                            </div>
                            <div>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={
                                      data.investment.isPartOfNetWorth ===
                                        "true" ||
                                      data.investment.isPartOfNetWorth === null
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleChanges(e, "isPartOfNetWorth")
                                    }
                                    color="primary"
                                    size="normal"
                                  />
                                }
                                label={
                                  data?.investment?.isPartOfNetWorth ===
                                    "true" ||
                                  data.investment.isPartOfNetWorth === null
                                    ? "Yes"
                                    : "No"
                                }
                                labelPlacement="end"
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="addform_body_right_investment">
                        <div>
                          <Tooltip title="Add your banks related file">
                            <div className="mt-2">
                              <FormLabel>Supporting Document</FormLabel>
                              <input
                                style={{
                                  border: "solid 1px lightgray",
                                  borderLeft: "none",
                                  width: "100%",
                                  borderRadius: "5px",
                                }}
                                type="file"
                                name="myfile"
                                id="exampleFile"
                                onChange={(e) => handleImageChange(e, 5)}
                                accept=".pdf"
                                multiple
                              />
                            </div>
                          </Tooltip>
                        </div>
                        <div
                          className="bene-select mt-3"
                          onClick={handleShowBeneficiary}
                          style={{ cursor: "pointer" }}
                        >
                          Select Your Beneficiary
                        </div>
                        <div className="address_input">
                          <div style={{ width: "100%" }} className="mt-2">
                            <div>
                              <FormLabel>Notes</FormLabel>
                              <Textarea
                                sx={{ height: "182px", minHeight: "52px" }}
                                placeholder="Notes"
                                id="notes"
                                onChange={(e) => handleChanges(e, "notes")}
                                value={data.investment.notes}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button outline className="my-estate-add-btn">
                        Update
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>

          {benevisible && (
            <SharedBeneficiaryEdit
              responseData={responseData}
              responseAssetData={responseAssetData}
              handleTrustSave={handleTrustSave}
              assetData={data?.investment}
              trusts={trusts}
              handleChanges={handleChanges}
              sharedType={sharedType}
              handleCloseBeneficiaryPopup={handleEditCloseBeneficiary}
              estimatedTotalAmount={estimatedTotalAmount}
              typeBeneficiary={"normal"}
              sharedDetails={data?.sharedDetails}
            />
          )}
        </SideBar>
      </UserBase>
    </>
  );
}

export default EditInvestment;
